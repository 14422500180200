import React from "react";
import globalStyles from '../../../Styles/Style.module.css';
import styles from "./ListUser.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign, faGift, faPlus, faTimes, faTruck} from "@fortawesome/free-solid-svg-icons";
import {api_url} from "../../../Services/ConnexionService";
import CardTemplateUser from "./CardTemplateUser";
import moment from "moment";
import formStyles from "../../../Styles/FormStyle.module.css";
import AddChildBasket from "./AddChildBasket";
import Modal from "react-modal";


class ListUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            quantityGift: 0,
        }
        this.isOpen = false;
    }

    openModal = () => {
        this.isOpen = true;
        this.forceUpdate();
    }

    closeModal = () => {
        this.isOpen = false;
        this.forceUpdate();
    }

    decreaseQty = () => {
        this.props.setQuantity(this.props.oaid, -1, this.state.quantityGift - 1, false);
        this.setState({quantityGift: this.state.quantityGift - 1});
    }

    increaseQty = () => {
        this.props.setQuantity(this.props.oaid, -1, this.state.quantityGift + 1, false);
        this.setState({quantityGift: this.state.quantityGift + 1});
    }

    displayUsers = (nbUsers, listUsers) => {
        let users = []

        let missing = nbUsers - (listUsers.length % nbUsers);
        let currentLine = 0;
        for (let c = 0; c < listUsers.length; c++) {
            if (c >= nbUsers*currentLine + nbUsers){
                users.push(<div className={styles.break}/>)
                currentLine++;
            }

            let user = listUsers[c];

            users.push(
                <div className={styles.childCard} style={{
                    marginLeft: c%nbUsers === 0 ? "0" : "10px",
                    marginRight: c%nbUsers === nbUsers - 1 ? "0" : "10px",
                }}>
                    <CardTemplateUser
                        firstName={user.firstName}
                        fullName={user.fullName}
                        birthDate={user.birthDate}
                        setQuantity={this.props.setQuantity.bind(this)}
                        oaid={this.props.oaid}
                        id={user.id}
                        quantity={c === 0 ? this.props.quantity : 0}
                    />
                </div>
            )

        }


        if(missing === nbUsers || nbUsers === 1){
            users.push(<div className={styles.break}/>)
            missing = nbUsers;
        }

        users.push(
            <div className={styles.childCard} style={{
                marginLeft: missing === nbUsers ? "0" : "10px",
                marginRight: missing === 1 ? "0" : "10px",
            }}>
                <div className={globalStyles.card} style={{padding: "20px", flex: 1, backgroundColor: "#f3f6f8", display: "flex", flexDirection: "column"}}>
                    <h3 style={{margin: "10px 0"}}>
                        <FontAwesomeIcon icon={faGift} style={{marginRight: "10px"}}/>
                        Offrir en cadeau
                    </h3>

                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div>
                            Quantité :
                        </div>
                        <button
                            className={
                                this.state.quantityGift <= 0  ?
                                    styles.disabledControleBtn :
                                    styles.controleBtn
                            }
                            onClick={() => this.decreaseQty()}
                            style={{paddingBottom: "2px"}}
                            disabled={this.state.quantityGift <= 0}
                        >
                            -
                        </button>
                        {this.state.quantityGift}
                        <button
                            className={styles.controleBtn}
                            onClick={() => this.increaseQty()}
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>
        )

        missing--;

        if(missing === nbUsers || missing === 0 || nbUsers === 1){
            users.push(<div className={styles.break}/>)
            missing = nbUsers;
        }

        users.push(
            <div className={styles.childCard} style={{
                marginLeft: missing === nbUsers ? "0" : "10px",
                marginRight: missing === 1 ? "0" : "10px",
            }}>
                <div className={globalStyles.card} style={{padding: "30px", flex: 1, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column"}}>
                    <h3 style={{margin: "10px 0"}}>
                        <FontAwesomeIcon icon={faPlus} style={{marginRight: "10px"}}/>
                        Nouveau profil
                    </h3>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1, margin: "10px 0 0"}}>
                        <button className={globalStyles.defaultBtn}  onClick={() => this.openModal()}>
                            Ajouter
                        </button>
                    </div>
                </div>
            </div>
        )

        missing--;

        for(let i = 0; i < missing; i++){
            users.push(
                <div style={{flex: 1, display: "flex",  flexDirection: "row", margin: "10px"}}>
                </div>
            )
        }
        return users
    }

    setDefault = (id) => {
        let request = new Request(api_url + '/client/user/address/default/' + id, {method:'POST', credentials:'include'});
        this.props.fetchJsonOrError(request, (data) => {
            if(data["edited"]){
                window.location.reload();
            }
        })
    }

    deleteAddress = (id) => {
        let request = new Request(api_url + '/client/user/address/delete/' + id, {method:'POST', credentials:'include'});
        this.props.fetchJsonOrError(request, (data) => {
            if(data["deleted"]){
                window.location.reload();
            }
        })
    }

    render(){
        return (
            <div style={{width: "100%"}}>
                <h2 className={styles.title} style={{marginTop: "10px"}}>
                    <FontAwesomeIcon icon={this.props.iconTitle} style={{marginRight: "15px"}}/>
                    {this.props.title}
                </h2>

                <div className={styles.cardContainer} style={{flexWrap: "wrap", margin: "auto"}}>
                    {window.innerWidth > 960 ?
                        this.displayUsers(this.props.desktopNumber, this.props.users) :
                        this.displayUsers(this.props.mobileNumber, this.props.users)
                    }
                </div>

                <Modal
                    isOpen={this.isOpen}
                    contentLabel="Ajouter un enfant"
                    style={{
                        content : {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 500,
                            width: "70%",
                            padding: "40px",
                            paddingTop: "20px",
                            borderRadius: "15px"
                        },
                        overlay : {
                            zIndex: 500,
                            backgroundColor: "rgba(0, 0, 0, 0.8)"
                        }
                    }}
                >
                    <AddChildBasket
                        fetchJsonOrError={this.props.fetchJsonOrError}
                        oaid={this.props.oaid}
                        title={"Ajouter un enfant"}
                    />
                    <button
                        style={{
                            position: "absolute",
                            top: "20px",
                            right: "20px",
                            background: "none",
                            border: "none",
                            fontSize: "20px",
                            cursor: "pointer"
                        }}
                        onClick={() => this.closeModal()}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </Modal>
            </div>
        );
    }

}

export default ListUser;