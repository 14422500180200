import React from "react";
import styles from './Login.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faEye, faEyeSlash, faLock} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import LoggedComponent, {api_url, mapStateToProps} from "../../Services/ConnexionService"
import globalStyles from  '../../Styles/Style.module.css';
import formStyles from  '../../Styles/FormStyle.module.css';
import ReCAPTCHA from "react-google-recaptcha";
import Bandeau from "../header_footer/Bandeau";
import {withUserAgent} from "react-useragent";

class Login extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            withPwd: true,
            email : '',
            otp : '',
            pwd : '',
            hasSendMail : false,
            error : false,
            errorMsg : 'Identifiants incorrects.',
            recaptchaRef : React.createRef(),
            captcha: false,
            showPwd: false
        }
        this.first = true
    }

    doesMustLog = () => {
        this.mustLog = -1
    }

    handleEnter() {
        if (this.first) {
            this.first = true
            document.addEventListener('keydown', (event) => {
                if (event.key === "Enter") {
                    this.submit()
                }
                }
            );
        }
    }

    submit = () => {
        if(this.state.withPwd){
            this.loginPwd()
        }
        else if (this.state.hasSendMail) {
            this.loginOtp()
        }
        else {
            this.state.recaptchaRef.current.executeAsync()
                .then(() => {
                    if(this.state.captcha){
                        this.sendMail()
                    }
                });
        }
    }

    loginPwd = () => {
        const emailRegex = /\S+@\S+\.\S+/;
        if(emailRegex.test(this.state.email)) this.state.email = this.state.email.toLowerCase();
        let data = {
            email: this.state.email,
            pwd : this.state.pwd,
            userAgent : this.props.ua.md.ua
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(api_url + '/client/login/pwd', {method:'POST', body: jsonD, credentials:'include'})
        this.fetchJsonOrError(request, (data) => {
            switch (data.type) {
                case 'OK':
                    let redirect = new URLSearchParams(window.location.search).get("_redirect")
                    this.setState({shouldNavigate: true, navigateTo: redirect ? redirect : '/'})
                    break
                case 'NON_MAIL':
                    this.setState({...this.state, error: true, errorMsg: 'Votre mail n\'est pas validé. Un nouveau mail de validation vous a été envoyé.'})
                    break
                default:
                    this.setState({...this.state, error: true, errorMsg : 'Identifiants incorrects.'})
            }
        })
    }

    sendMail = () => {
        let data = {
            email: this.state.email
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(api_url + '/client/login/sendmail', {method:'POST', body: jsonD, credentials:'include'})
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            switch (data.type) {
                case 'OK':
                    this.setState({...this.state, hasSendMail: true, error: false})
                    break
                default:
                    this.setState({...this.state, error: true, errorMsg : 'Vous n\'avez pas encore de compte !'})
            }
        })
    }

    loginOtp = () => {
        const emailRegex = /\S+@\S+\.\S+/;
        if(emailRegex.test(this.state.email)) this.state.email = this.state.email.toLowerCase();

        let data = {
            email: this.state.email.trim(),
            otp: this.state.otp,
            userAgent : this.props.ua.md.ua
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(api_url + '/client/login/otp', {method:'POST', body: jsonD, credentials:'include'})
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            switch (data.type) {
                case 'OK':
                    this.setState({shouldNavigate: true, navigateTo:'/'})
                    break
                case 'EXPIRED':
                    this.setState({error: true, errorMsg: 'Ce code est expiré'})
                    break
                default:
                    this.setState({...this.state, error: true, errorMsg : 'Code incorrect'})
            }
        })
    }

    renderChild() {
        this.handleEnter();

        return (

            <div className="Login">
                <Bandeau title={"Connexion"}/>

                <div className={globalStyles.globalContainer}>

                    <div className={`${globalStyles.card} ${styles.loginCard}`}>

                        <h3 style={{textAlign: "center", fontWeight: 600}}>Connectez-vous en recevant un code sécurisé par e-mail ou en utilisant votre mot de passe.</h3>

                        {this.state.error &&
                        <div className={styles.error}>
                            {this.state.errorMsg}
                        </div>
                        }

                        {(!this.state.hasSendMail || this.state.withPwd) &&
                        <div className={styles.inputGroup}>
                            <div className={formStyles.inputIcon}>
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </div>
                            <input
                                type="text"
                                name="username"
                                size={1}
                                className={formStyles.input}
                                required placeholder="E-Mail"
                                value={this.state.email}
                                onChange={(event) => {
                                    const emailRegex = /\S+@\S+\.\S+/;
                                    let cleanMail = event.target.value.replace(/\s/g, "");
                                    this.setState({...this.state, email: cleanMail.toLowerCase(), error:false})
                                }}
                            />
                        </div>
                        }

                        {this.state.hasSendMail && !this.state.withPwd &&
                        <div>
                            <p style={{textAlign: "center"}}>Un courriel contenant les instructions de connexion a été envoyé à {this.state.email}</p>
                            <div className={styles.inputGroup}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faLock}/>
                                </div>
                                <input type="text" name="otp" size={1} className={formStyles.input} required placeholder="Code reçu" onChange={(event) => this.setState({...this.state, otp: event.target.value, error: false})}/>
                            </div>
                        </div>
                        }

                        {this.state.withPwd &&
                        <div className={styles.inputGroup}>
                            <div className={formStyles.inputIcon} style={{flex: 2}}>
                                <FontAwesomeIcon icon={faLock}/>
                            </div>
                            <input style={{flex: window.innerWidth > 1020 ? 21 : 13}} size={1} type={this.state.showPwd ? "text" : "password"} name="password" className={formStyles.input} required placeholder="Mot de passe" onChange={(event) => this.setState({...this.state, pwd: event.target.value, error: false})}/>
                            <button className={formStyles.eyeIcon} onClick={() => this.setState({showPwd: !this.state.showPwd})}>
                                <FontAwesomeIcon icon={this.state.showPwd ? faEyeSlash : faEye}/>
                            </button>
                        </div>
                        }

                        {!this.state.withPwd &&
                        <ReCAPTCHA
                            sitekey={"6LdDiGIeAAAAAOFvByEGM_hcDTqkwJZIty_szaHA"}
                            onChange={() => this.setState({captcha: true})}
                            size={"invisible"}
                            ref={this.state.recaptchaRef}
                            badge={"bottomleft"}
                        />
                        }


                        <button type="submit" className={`${globalStyles.defaultBtn} ${styles.loginBtn}`} onClick={this.submit} disabled={this.state.email === ''}>
                            {!this.state.withPwd && !this.state.hasSendMail ? "Recevoir un code d'accès" : "Se connecter"}
                        </button>



                            <div className={styles.footer}>
                                    <button onClick={() => this.setState({...this.state, withPwd: !this.state.withPwd})} className={`${globalStyles.defaultLink} ${styles.linkUsePwd}`}>
                                        {this.state.withPwd ? "Recevoir un code d’accès sécurisé" : "Utiliser un mot de passe"}
                                    </button>
                                {!this.state.hasSendMail &&
                                    <div>
                                        Pas encore de compte ?<br/>
                                        <a href="/register" className={`${globalStyles.defaultLink} ${styles.linkRegister}`}>S'inscrire</a>
                                    </div>
                                }
                            </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withUserAgent(Login));
