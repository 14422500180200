import React from "react";
import globalStyles from '../../../Styles/Style.module.css';
import { faUser, faTruck, faEuroSign, faMale, faFemale, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function CardTemplateBilling(props) {

    const displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            informations.push(
                <div>
                    {infos[i]}
                </div>
            )
        }
        return informations
    }


    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: props.color ? props.color : "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "column"
            }}
        >
            <div style={{flex: 5}}>
                <h2 style={{margin: "0 0 15px 0"}}>
                    <FontAwesomeIcon icon={props.icon} style={{marginRight: "15px"}}/>
                    {props.title}
                </h2>

                <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px", color: !props.hasAddress ? "red" : "black"}}>
                    {displayInfos(props.infos)}
                </div>
            </div>

            <div
                style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end"
                }}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                    <button className={globalStyles.secondaryBtn} style={{marginRight: "10px"}} onClick={props.button[1]}>
                        {props.button[0]}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CardTemplateBilling;