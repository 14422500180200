import {useParams} from "react-router-dom";
import React from "react";
import Delivery from "./Delivery";
import {decode} from "../../../Services/ObfuscatorService";

function GetIdDelivery () {
    let {id} = useParams()

    return (
        <Delivery id={decode(id)}/>
    )
}

export default GetIdDelivery;
