import React from "react"
import LoggedComponent, {api_url, shop_url, mapStateToProps} from "../../../../Services/ConnexionService";
import {connect} from "react-redux";
import globalStyles from "../../../../Styles/Style.module.css";
import FilAriane from "../../../header_footer/FilAriane";
import {
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import image from "../../../../assets/fixtures/objectif-mars.png"
import Bandeau from "../../../header_footer/Bandeau";
import Empty from "../../../Empty";
import AddItemTemplate from "./AddItemTemplate";


class AddItem extends LoggedComponent {

    constructor(props) {
        super(props);
        this.hasAdded = false;
        this.hasResponded = false;
        this.itemsAdded = [];
    }

    setIsAddItem() {
        this.isAddItem = true
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    addItem = () => {
        if (!this.hasAdded && !this.state.shouldNavigate) {
            this.hasAdded = true
            let id = 0;
            let item = "";
            let ids = this.props.item.ids
            let refs = []
            for(const i in ids) {
                id = ids[i]
                item = this.props.item.item['item'+id]
                refs.push(item.reference)
                this.props.dispatch({
                    type: "REMOVE_ITEM",
                    id: id
                })
            }
            let jsonD = new FormData()
            jsonD.append("json", JSON.stringify(refs))
            let request = new Request(api_url + '/client/basket/add', {method:'POST', credentials:'include', body: jsonD})
            this.fetchJsonOrError(request, (json) =>{
                switch (json.type) {
                    case 'OK':
                        this.hasResponded = true
                        this.itemsAdded = json.added
                        this.setState({shouldNavigate: true, navigateTo: '/basket'})
                        break
                    case 'NONE':
                        this.setState({shouldNavigate: true, navigateTo: '/basket'})
                        break
                    default:
                        this.goError()
                }
            })
        }
    }

    displayItems() {
        let items = []
        for(let i = 0; i < this.itemsAdded.length; i++){
            let article = this.itemsAdded[i].article;
            items.push(
                <AddItemTemplate
                    name={article.name}
                    media={article.media.length > 0 ? article.media[0] : undefined}
                    priceTtc={article.priceTtc}
                    unitPriceTtc={article.unitPriceTtc}
                    theme={article.theme}
                    secondaryTheme={article.secondaryTheme}
                    description={article.description}
                />
            )
        }

        return items

    }

    renderChild () {
        this.addItem()
        return (
            <div style={{height: "1000000px", backgroundColor:"#FFF"}}/>
        );
    }
}

export default connect(mapStateToProps)(AddItem);
