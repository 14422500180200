import React from "react";
import globalStyles from  '../../Styles/Style.module.css';
import bandeau from '../../assets/utils/bandeau.svg';
import bandeauMobile from '../../assets/utils/bandeau-mobile.svg';
import bulles from "../../assets/utils/bulle-bg.svg"

function Bandeau(props) {

    return (
        <section
            style={{
                background: "#571088",
                height: "100px",
                marginTop: "80px",
                position: "relative",
                backgroundImage: window.innerWidth > 1020 ? `url(${bulles})` : "none",
                backgroundSize: "100%",
                backgroundPositionY: "7%"
        }}>
            <div style={{
                overflow: "hidden",
                position: "absolute",
                left: 0,
                width: "100%",
                lineHeight: 0,
                direction: "ltr",
                transform: "rotate(180deg)",
                bottom: "-1px",
            }}>
                <img src={bandeau} style={{height: "30px", width: "calc(100% + 1.3px)"}}/>
            </div>
            <div style={{position: "absolute", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{color: "white", width: "1020px", margin: "auto", paddingLeft: window.innerWidth > 1020 ? 0 : "10px"}}>
                    <h1 style={{marginTop: "20px", marginBottom: "20px", marginLeft: window.innerWidth < 1020 ? "10px" : undefined}}>{props.title}</h1>
                </div>
            </div>
        </section>

    );
}

export default Bandeau;
