import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSpinner} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

function CardTemplateStatus(props) {

    return (

            <div
                className={globalStyles.card}
                style={{
                    padding: "30px",
                    flex: 1,
                    backgroundColor: props.bgcolor ? props.bgcolor : "#f3f6f8",
                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                    borderRadius: "5px",
                    display: "flex",
                    height: "100%",
                    boxSizing: "border-box",
                    flexDirection: "column"
                }}
            >
                {props.isDone ?
                    <div>
                        <div style={{marginBottom: "20px"}}>
                            <span className={globalStyles.badge} style={{backgroundColor: props.color}}>
                                {props.title}
                            </span>
                        </div>

                        <FontAwesomeIcon icon={faCheck} style={{color: "#6fa939", marginRight: "10px"}}/>
                        <b>Le {moment(props.date).format("DD/MM/YYYY")} à {moment(props.date).format("HH:mm")}</b>
                    </div>
                    :
                    <div>
                        <div style={{marginBottom: "20px"}}>
                            <span className={globalStyles.badge} style={{backgroundColor: "#ddd"}}>
                                {props.title}
                            </span>
                        </div>

                        <FontAwesomeIcon icon={faSpinner} style={{marginRight: "10px", color: "#777"}}/>
                        <b style={{color: "#777"}}>Patience</b>
                    </div>
                }
            </div>

    );
}

export default CardTemplateStatus;