import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import statsAsk from "../../assets/stats/Stats_ask.png";
import statsStar from "../../assets/stats/Stats_star.png";
import statsReward from "../../assets/stats/Stats_reward.png";
import statsCheck from "../../assets/stats/Stats_check.png";
import styles from "./CardStats.module.css"
import {api_url} from "../../Services/ConnexionService";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {NavHashLink} from "react-router-hash-link";

function CardStats(props) {

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -120;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }


    const displayPictures = (nbPictures) => {
        let pictures = []
        for (let i = 0; i < props.pictures.length; i++) {
            if (i % nbPictures === 0) {
                pictures.push(<div className={globalStyles.break}/>);
            }
            pictures.push(
                <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <div style={{maxWidth: 200, borderRadius: 10, aspectRatio: 1, overflow: "hidden", position: "relative"}}>
                        <span
                            style={{
                                top: 0,
                                position: "absolute",
                                backgroundColor: "rgb(0,0,0,0.8)",
                                color: "white",
                                padding: "5px 15px",
                                fontWeight: 500,
                                fontSize: "0.8rem",
                                borderBottomRightRadius: "10px"
                        }}>
                            {moment(props.pictures[i].date.date).format("DD/MM/YYYY")}
                        </span>
                        <img
                            style={{height: "100%", width: "100%"}}
                            src={api_url + '/uploads/users/photo/' + props.pictures[i].media}
                        />
                    </div>

                    <p className={styles.badgeName}>
                        {props.pictures[i].activity}
                    </p>
                </div>
            )
        }

        if(nbPictures > 3){
            for (let i = 0; i < 3 - props.pictures.length; i++) {
                pictures.push(
                    <div style={{flex: 1}}/>
                )
            }
        }

        return pictures
    }

    const displayBadges = (nbBadges) => {
        let badges = []
        for(let i = 0; i < props.badges.length; i++){
            if(i % nbBadges === 0){
                badges.push(<div className={globalStyles.break}/>);
            }
            badges.push(
                <div
                    className={styles.badgeContainer}
                    data-tip={props.badges[i].description}
                    place={"top"}
                >
                    <div className={styles.badge}>
                        <img
                            src={api_url + '/uploads/badge/' + props.badges[i].media}
                            style={{maxWidth: "90%", maxHeight: "90%"}}
                        />
                    </div>
                    <p className={styles.badgeName}>{ props.badges[i].name }</p>
                </div>
            )
        }

        for(let i = 0; i < props.badgesToWin.length; i++){
            if((i + props.badges.length) % nbBadges === 0){
                badges.push(<div className={globalStyles.break}/>);
            }
            badges.push(
                <div
                    className={styles.badgeContainer}
                    data-tip={props.badgesToWin[i].descriptionNotWin}
                    place={"top"}
                >
                    <div className={styles.badge}>
                        <img
                            src={api_url + '/uploads/badge/' + props.badgesToWin[i].media}
                            style={{maxWidth: "90%", maxHeight: "90%", filter: "grayscale(1)", objectFit: "contain"}}
                        />
                    </div>
                    <p className={styles.badgeName} style={{color: "#aaaaaa", marginTop: 10}}>{ props.badgesToWin[i].name }</p>
                </div>

            )
        }

        if((props.badgesToWin.length + props.badges.length) % nbBadges === 0){
            badges.push(<div className={globalStyles.break}/>);
        }

        badges.push(
            <div
                className={styles.badgeContainer}
            >
                <div className={styles.badge} style={{backgroundColor: "#EEEEEE"}}>
                    <h2 className={styles.badgeInterr}>+{props.nbBadges}</h2>
                </div>
                <p className={styles.badgeName}>Badge{ props.nbBadges > 1 ? "s" : "" } à découvrir</p>
            </div>
        )



        if((props.badgesToWin.length + props.badges.length + 1) % nbBadges !== 0){
            for(let i = 0; i < nbBadges - ((props.badgesToWin.length + props.badges.length + 1) % nbBadges); i++){
                badges.push(
                    <div className={styles.badgeContainer}>
                    </div>
                )
            }
        }


        return badges
    }


    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: props.color ? props.color : "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "column",
                width: "100%"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: window.innerWidth > 1020 ? "row" : "column",
                    flex: 1,
                    alignItems: "center",
                    gap:  window.innerWidth > 565 ? undefined : "15px"
            }}>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: window.innerWidth < 1020 ? "space-evenly": undefined,
                        width: window.innerWidth > 1020 ? undefined : "100%",
                        flexDirection: window.innerWidth > 565 ? "row" : "column",
                        alignItems: window.innerWidth > 565 ? undefined : "center",
                        gap:  window.innerWidth > 565 ? undefined : "15px",
                }}>
                    <div
                        style={{
                            flex: 1,
                            margin: window.innerWidth > 1020 ? undefined : "0 5px",
                            marginLeft: window.innerWidth > 1020 ? "30px" : "5px",
                            width: window.innerWidth < 565 ? "70%" : undefined
                        }}
                        className={`${styles.stats} ${styles.statsCheck}`}
                    >
                        <div className={styles.statsDigit}>{ props.activity }</div>
                        <div className={styles.statsName}>activité{ props.activity > 1 ? "s" : "" } réalisée{ props.activity > 1 ? "s" : "" }</div>
                    </div>

                    <div
                        style={{
                            flex: 1,
                            margin: window.innerWidth > 1020 ? undefined : "0 5px",
                            marginLeft: window.innerWidth > 1020 ? "30px" : "5px",
                            width: window.innerWidth < 565 ? "70%" : undefined
                    }}
                        className={`${styles.stats} ${styles.statsStar}`}
                    >
                        <div className={styles.statsDigit}>{ props.xp }</div>
                        <div className={styles.statsName}>point{ props.xp > 1 ? "s" : "" } d’expérience</div>
                    </div>
                </div>

                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: window.innerWidth < 1020 ? "space-evenly": undefined,
                        width: window.innerWidth > 1020 ? undefined : "100%",
                        flexDirection: window.innerWidth > 565 ? "row" : "column",
                        alignItems: window.innerWidth > 565 ? undefined : "center",
                        gap:  window.innerWidth > 565 ? undefined : "15px",
                }}>
                    <div
                        style={{
                            flex: 1,
                            margin: window.innerWidth > 1020 ? undefined : "0 5px",
                            marginLeft: window.innerWidth > 1020 ? "30px" : "5px",
                            width: window.innerWidth < 565 ? "70%" : undefined
                    }}
                        className={`${styles.stats} ${styles.statsQuiz}`}>
                        <div className={styles.statsDigit}>{ props.quizz }</div>
                        <div className={styles.statsName}>quizz réussi{ props.quizz > 1 ? "s" : "" }</div>
                    </div>

                    <div
                        style={{
                            flex: 1,
                            margin: window.innerWidth > 1020 ? undefined : "0 5px",
                            marginLeft: window.innerWidth > 1020 ? "30px" : "5px",
                            marginRight: window.innerWidth > 1020 ? "30px" : "5px",
                            width: window.innerWidth < 565 ? "70%" : undefined,

                        }}
                        className={`${styles.stats} ${styles.statsReward}`}
                    >
                        <div className={styles.statsDigit}>{ props.badges.length }</div>
                        <div className={styles.statsName}>badge{ props.badges.length > 1 ? "s" : "" } récompense{ props.badges.length > 1 ? "s" : "" }</div>
                    </div>
                </div>

            </div>

            {props.pictures.length != 0 &&

                <div>
                    <h4>Photos</h4>

                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        {displayPictures(window.innerWidth > 1020 ? 3 : 1)}
                    </div>

                    <div style={{textAlign: "right", marginTop: 15}}>
                        <NavHashLink
                            to={"/pictures#user" + props.id}
                            scroll={el => scrollWithOffset(el)}
                            style={{color: "#777", textDecoration: "none"}}
                        >
                            <span className={globalStyles.defaultLink}>Voir toutes les photos</span>
                        </NavHashLink>
                    </div>
                </div>

            }

            <h4>Badges</h4>

            <div style={{display: "flex", flexWrap: "wrap", gap: "20px"}}>

                {displayBadges(window.innerWidth > 1020 ? 7 : (window.innerWidth > 800 ? 6 : (window.innerWidth > 600 ? 4 : (window.innerWidth > 565 ? 2 : 1))))}
            </div>


            <ReactTooltip multiline={true}/>
        </div>
    );
}

export default CardStats;
