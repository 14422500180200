import styles from './Home.module.css';
import React from "react"
import LoggedComponent, {api_url, mapStateToProps, shop_url} from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import {
    faBox,
    faBoxOpen, faExclamation, faFemale, faInfo, faMale,
    faShoppingBasket,
    faTruck,
    faUser, faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import FilAriane from "../header_footer/FilAriane";
import {connect} from "react-redux";
import CardTemplateHome from "../Card/CardTemplateHome";
import ReactTooltip from "react-tooltip";
import Bandeau from "../header_footer/Bandeau";
import bulles from "../../assets/utils/bulle-bg.svg";
import CardTemplate from "../Card/CardTemplate";
import CardHomeChildInfo from "./CardHomeChildInfo";
import CardSuggestNextBox from "../Card/CardSuggestNextBox";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CardUserHome from "./CardUserHome";
import {encode, decode} from "../../Services/ObfuscatorService";
import DisplayPrice from "../Utils/DisplayPrice";

const titleAriane = require("../../Helpers/TitleAriane.json");

class Home extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            infosUser: {}
        }
        this.loading = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    displayAlreadyClient = () => {
        let users = [];


        let user = this.state.infosUser.user;
        if(!user.commitment.default || user.box > 0 || user.activity > 0 || user.xp > 0) {
            users.push(
                <div style={{display: "flex", width: "100%", flexWrap: "wrap"}}>
                    <div className={styles.childCard} style={{marginRight: window.innerWidth > 1020 ? "10px" : "0"}}>
                        <CardHomeChildInfo
                            name={"Vous"}
                            icon={this.state.infosUser.user.genderLetter === "F" ? faFemale : faMale}
                            box={this.state.infosUser.user.box}
                            activity={this.state.infosUser.user.activity}
                            xp={this.state.infosUser.user.xp}
                            id={this.state.infosUser.user.id}
                            commitment={this.state.infosUser.user.commitment.name}
                            color={this.state.infosUser.user.commitment.color}
                            endDate={this.state.infosUser.user.commitment.end ? this.state.infosUser.user.commitment.end.date : null}
                            password={
                                this.state.infosUser.user.username || this.state.infosUser.user.email ?
                                    [this.state.infosUser.user.password ?
                                        "Changer le mot de passe" :
                                        "Créer un mot de passe"
                                    ]: null
                            }
                        />
                    </div>

                    {window.innerWidth < 1020 && <div className={styles.break}/> }

                    <div className={styles.childCard}
                         style={{marginLeft: window.innerWidth > 1020 ? "10px" : "0", flex: 2}}>
                        <CardSuggestNextBox
                            name={"vous"}
                            boxName={user.nextBox.info.name}
                            description={user.nextBox.info.description}
                            theme={user.nextBox.info.theme}
                            themeColor={user.nextBox.info.themeColor}
                            themeBaseColor={user.nextBox.info.themeBaseColor}
                            secondaryTheme={user.nextBox.info.secondaryTheme ? user.nextBox.info.secondaryTheme : undefined}
                            secondaryThemeColor={user.nextBox.info.secondaryThemeColor}
                            secondaryThemeBaseColor={user.nextBox.info.secondaryThemeBaseColor}
                            media={user.nextBox.info.media[0]}
                            date={user.nextBox.date ? user.nextBox.date.date : undefined}
                            commitment={user.nextBox.commitment}
                            choose={user.nextBox.choose && !user.commitment.aventure}
                            chooseId={user.nextBox.orderId}
                            reference={user.nextBox.info.reference}
                            last={user.nextBox.last}
                            commitmentRef={user.nextBox.commitmentRef}
                        />
                    </div>
                </div>
            )
        }

        for (let c in this.state.infosUser.childs) {
            let child = this.state.infosUser.childs[c].user;

            if(!child.commitment.default || child.box > 0 || child.activity > 0 || child.xp > 0) {
                users.push(
                    <div style={{display: "flex", width: "100%", flexWrap: "wrap"}}>
                        <div className={styles.childCard} style={{marginRight: window.innerWidth > 960? "10px" : "0"}}>
                            <CardHomeChildInfo
                                name={child.firstName + ", " + this.getAge(child.birthDate.date) + " ans"}
                                icon={child.genderLetter === "F" ? faFemale : faMale}
                                box={child.box}
                                activity={child.activity}
                                xp={child.xp}
                                id={child.id}
                                commitment={child.commitment.name}
                                color={child.commitment.color}
                                endDate={child.commitment.end ? child.commitment.end.date : null}
                                password={
                                    child.username || child.email ?
                                        [child.password ?
                                            "Changer le mot de passe" :
                                            "Créer un mot de passe"
                                        ]: null
                                }
                            />
                        </div>

                        {window.innerWidth < 1020 && <div className={styles.break}/> }

                        <div className={styles.childCard} style={{marginLeft: window.innerWidth > 960? "10px" : "0", flex: 2}}>
                            <CardSuggestNextBox
                                name={child.firstName}
                                boxName={child.nextBox.info.name}
                                description={child.nextBox.info.description}
                                theme={child.nextBox.info.theme}
                                themeColor={child.nextBox.info.themeColor}
                                themeBaseColor={child.nextBox.info.themeBaseColor}
                                secondaryTheme={child.nextBox.info.secondaryTheme ? user.nextBox.info.secondaryTheme : undefined}
                                secondaryThemeColor={child.nextBox.info.secondaryThemeColor}
                                secondaryThemeBaseColor={child.nextBox.info.secondaryThemeBaseColor}
                                media={child.nextBox.info.media[0]}
                                date={child.nextBox.date ? child.nextBox.date.date : undefined}
                                commitment={child.nextBox.commitment}
                                choose={child.nextBox.choose && !child.commitment.aventure}
                                chooseId={child.nextBox.orderId}
                                reference={child.nextBox.info.reference}
                                last={child.nextBox.last}
                                commitmentRef={child.nextBox.commitmentRef}
                            />
                        </div>
                    </div>
                )

                users.push(
                    <div className={styles.break}/>
                )
            }
        }


        return users;



    }

    displayUsers = (nbUsersLine) => {
        let users = []
        let currentLine = 0;

        let count = 0;
        let user = this.state.infosUser.user;
        if(!(!user.commitment.default || user.box > 0 || user.activity > 0 || user.xp > 0)) {
            users.push(
                <div className={styles.childCard} style={{
                    marginLeft: window.innerWidth < 1020 || (count) % nbUsersLine === 0 ? "0" : "10px",
                    marginRight: window.innerWidth < 1020 || (count) % nbUsersLine === nbUsersLine - 1 ? "0" : "10px",
                }}>
                    <CardUserHome
                        icon={this.state.infosUser.user.genderLetter === "F" ? faFemale : faMale}
                        title={"Vous"}
                        id={this.state.infosUser.user.id}
                        password={
                            this.state.infosUser.user.username || this.state.infosUser.user.email ?
                                [this.state.infosUser.user.password ?
                                    "Changer le mot de passe" :
                                    "Créer un mot de passe"
                                ]: null
                        }
                    />
                </div>
            )
            count++;
        }

        for (let c in this.state.infosUser.childs) {
            let child = this.state.infosUser.childs[c].user;

            if(!(!child.commitment.default || child.box > 0 || child.activity > 0 || child.xp > 0)) {

                if (parseInt(count) >= nbUsersLine * currentLine + nbUsersLine) {
                    users.push(<div className={styles.break}/>)
                    currentLine++;
                }


                users.push(
                    <div className={styles.childCard} style={{
                        marginLeft: window.innerWidth < 1020 || (count) % nbUsersLine === 0 ? "0" : "10px",
                        marginRight: window.innerWidth < 1020 || (count) % nbUsersLine === nbUsersLine - 1 ? "0" : "10px",
                    }}>
                        <CardUserHome
                            icon={child.genderLetter === "F" ? faFemale : faMale}
                            title={child.firstName + ", " + this.getAge(child.birthDate.date) + " ans"}
                            id={child.id}
                            password={
                                child.username || child.email ?
                                    [child.password ?
                                        "Changer le mot de passe" :
                                        "Créer un mot de passe"
                                    ]: null
                            }
                        />
                    </div>
                )

                count++;
            }
        }

        let missing = nbUsersLine - count % nbUsersLine;

        if(missing === 0 || missing === 3 || nbUsersLine === 1){
            users.push(<div className={styles.break}/>)
            missing = nbUsersLine;
        }

        users.push(
            <div className={styles.childCard} style={{
                marginLeft: window.innerWidth > 1020 && missing === nbUsersLine && nbUsersLine > 1 ? "0" : "10px",
                marginRight: window.innerWidth > 1020 && missing === 1 && nbUsersLine > 1 ? "0" : "10px",
            }}>
                <div className={globalStyles.card} style={{padding: "20px", flex: 1, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column"}}>
                    <h2 style={{margin: "10px 0"}}>
                        <FontAwesomeIcon icon={faUserPlus} style={{marginRight: "10px"}}/>
                        Nouveau profil
                    </h2>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1, margin: "30px 0"}}>
                        <a className={globalStyles.defaultBtn}  href={"/addchild"}>
                            Ajouter
                        </a>
                    </div>
                </div>
            </div>
        )

        missing--;

        for(let i = 0; i < missing; i++){
            users.push(
                <div style={{flex: 1, display: "flex",  flexDirection: "row", margin: "10px"}}>
                </div>
            )
        }
        return users
    }

    getAge = (date) => {
        var today = new Date();
        var birthDate = new Date(date.replace(" ", "T"));
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    getDeliveryAddress = () => {
        let delAddresses = []
        for (let i in this.state.infosUser.user.addresses) {
            let address = this.state.infosUser.user.addresses[i];
            if (address.type === 0 && address.default) {
                if(address.streetAddressMore != ""){
                    delAddresses = [
                        address.firstName + " " + address.lastName,
                        address.streetAddress,
                        address.streetAddressMore,
                        address.postalCode + " " + address.city,
                        address.country,
                        address.phone
                    ]
                } else {
                    delAddresses = [
                        address.firstName + " " + address.lastName,
                        address.streetAddress,
                        address.postalCode + " " + address.city,
                        address.country,
                        address.phone
                    ]
                }
            }
        }

        if(delAddresses.length === 0){
            delAddresses.push("Non renseigné")
        }

        return delAddresses
    }

    getUsersInfo(){
        let request = new Request(api_url + '/client/user/home', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({infosUser: data});
            this.loading = false;
            this.forceUpdate();
        })
    }

    renderChild () {

        if(!this.loading){
            return (
                <div>
                    <Bandeau title={titleAriane["home"]["title"]}/>

                    <FilAriane
                        links={[
                            [
                                titleAriane["home"]["ariane"][0],
                                ""
                            ]
                        ]}
                    />

                    <div className={`${globalStyles.globalContainer} ${styles.homeContainer}`}>

                        {this.state.user.isEntity && this.state.user.isSchool && !this.state.user.isSchoolValidate &&
                            <div className={globalStyles.bulle} style={{borderColor: "#ce3534"}}>
                            <span className={globalStyles.iconBulle} style={{backgroundColor: "#ce3534"}}>
                                <FontAwesomeIcon icon={faExclamation}/>
                            </span>
                                <span className={globalStyles.iconText}>
                                Votre compte enseignant doit être validé par nos équipes pour que vous puissiez bénéficier de nos tarifs préférentiels.
                                Nous allons prendre contact avec vous très prochainement.
                            </span>
                            </div>
                        }

                        {this.state.user.isEntity && this.state.user.isSchool && this.state.user.isSchoolValidate &&
                            <div className={globalStyles.bulle}>
                            <span className={globalStyles.iconBulle}>
                                <FontAwesomeIcon icon={faInfo}/>
                            </span>
                                <span className={globalStyles.iconText}>
                                Votre compte enseignant a été validé. Pour profiter des prix enseignant, contactez <a href="mailto://support@atorika.fr" className={globalStyles.defaultLink}>support@atorika.fr</a>.
                            </span>
                            </div>
                        }

                        <div className={styles.cardContainer} style={{flexWrap: "wrap"}}>

                            <div className={styles.childCard}>
                                <div
                                    className={globalStyles.card}
                                    style={{
                                        padding: "30px",
                                        backgroundColor: "white",
                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                        borderRadius: "5px",
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: 0
                                    }}
                                >
                                    <h2 style={{textAlign: "center"}}>
                                        Vous avez un code d'activation ?
                                    </h2>

                                    <p style={{textAlign: "center", margin: "0 15% 20px"}}>
                                        Ce code est nécessaire pour pouvoir accéder aux tutoriels liés à votre box ou votre abonnement.
                                    </p>

                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1}}>
                                        <a className={globalStyles.defaultBtn}  href={"/activation"}>
                                            Utiliser
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.break}/>

                            {this.state.infosUser.command ?
                                <div className={styles.childCard}
                                     style={{marginRight: window.innerWidth > 960 ? "10px" : "0"}}>
                                    <div
                                        className={globalStyles.card}
                                        style={{
                                            padding: "30px",
                                            flex: 1,
                                            backgroundColor: "#f3f6f8",
                                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                            borderRadius: "5px",
                                            display: "flex",
                                            height: "100%",
                                            boxSizing: "border-box",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <div style={{flex: 5}}>
                                            <h2 style={{margin: "0 0 15px 0"}}>
                                                <FontAwesomeIcon icon={faBoxOpen} style={{marginRight: "10px"}}/>
                                                Commande {this.state.infosUser.command.identifier}
                                            </h2>

                                            <div>
                                                Créée le {moment(this.state.infosUser.command.dateValidated.date).format("DD/MM/YYYY")}
                                                {window.innerWidth < 960 && <br/>}
                                                {window.innerWidth < 960 && <div style={{height: "10px"}}/>}
                                                <span
                                                    className={globalStyles.badge}
                                                    style={{
                                                        backgroundColor:
                                                            this.state.infosUser.command.stateNb === 3 ?
                                                                "#6fa939" :
                                                                (this.state.infosUser.command.stateNb === 2 ?
                                                                        "#f4bc00" :
                                                                        (this.state.infosUser.command.stateNb === 1 ?
                                                                                "#52BAEC" :
                                                                                (this.state.infosUser.command.stateNb === -1 ?
                                                                                        "#ce3534" :
                                                                                        "#C4C4C4"
                                                                                )
                                                                        )
                                                                ),
                                                        marginLeft: window.innerWidth > 960 ? "10px" : 0,
                                                        marginTop: window.innerWidth > 960 ? "10px" : 0,
                                                    }}>
                                                    {this.state.infosUser.command.state}
                                                </span>
                                            </div>


                                            <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                                                <div>{this.state.infosUser.command.number} article(s) pour un montant de <DisplayPrice price={this.state.infosUser.command.totalTtc/100}/></div>
                                                <div>
                                                    {this.state.infosUser.command.delivery.type === 1 &&
                                                        "Livraison au point relais " + this.state.infosUser.command.delivery.relayName
                                                    }
                                                    {this.state.infosUser.command.delivery.type === 2 &&
                                                        "Livraison à domicile pour " + this.state.infosUser.command.delivery.firstName + " " + this.state.infosUser.command.delivery.lastName
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                flex: 1,
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-end"
                                            }}>
                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                                                <a className={globalStyles.defaultLink} href={"/command/" + encode(this.state.infosUser.command.id) + "/view"}>
                                                    Plus de détails
                                                </a>
                                                <a className={globalStyles.defaultLink} href={"/commands"}>
                                                    Voir vos commandes
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={styles.childCard}
                                     style={{marginRight: window.innerWidth > 960 ? "10px" : "0"}}>
                                    <div
                                        className={globalStyles.card}
                                        style={{
                                            padding: "30px",
                                            background: "12% 8%/ 400% #073D50",
                                            backgroundImage: window.innerWidth > 1020 ? `url(${bulles})` : "none",
                                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                            borderRadius: "5px",
                                            flex: 1,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <h2 style={{textAlign: "center", flex: 1, color: "white"}}>
                                            Choisissez dès à présent votre prochaine box !
                                        </h2>

                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flex: 1
                                        }}>
                                            <a className={globalStyles.defaultBtn} href={shop_url}
                                               style={{backgroundColor: "#C73435"}}>
                                                Voir les ateliers
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }

                            { window.innerWidth < 960 && <div className={styles.break}/>}

                            <div className={styles.childCard} style={{marginLeft : window.innerWidth > 960 ? "10px" : 0}}>
                                <CardTemplate
                                    color={"white"}
                                    icon={faTruck}
                                    title={"Préférence de livraison"}
                                    infos={this.getDeliveryAddress()}
                                    links={[["Gérer vos adresses", "link", "/address"]]}
                                />
                            </div>

                            <div className={styles.break}/>

                            {this.displayAlreadyClient()}

                            {window.innerWidth > 1020 ?
                                this.displayUsers(3):
                                this.displayUsers(1)
                            }




                            {/*<div className={styles.childCard} style={{marginRight: window.innerWidth > 960? "10px" : "0"}}>*/}
                            {/*    <CardTemplateHome*/}
                            {/*        icon={faUser}*/}
                            {/*        title={"Votre profil"}*/}
                            {/*        link={"/profile"}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*{window.innerWidth <= 960 &&*/}
                            {/*    <div className={styles.break}/>*/}
                            {/*}*/}

                            {/*<div*/}
                            {/*    className={styles.childCard}*/}
                            {/*    style={{marginLeft: window.innerWidth > 960? "10px" : "0", marginRight: window.innerWidth > 960? "10px" : "0"}}>*/}
                            {/*    <CardTemplateHome*/}
                            {/*        icon={faBoxOpen}*/}
                            {/*        title={"Mes commandes"}*/}
                            {/*        link={"/commands"}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*{window.innerWidth <= 960 &&*/}
                            {/*    <div className={styles.break}/>*/}
                            {/*}*/}



                            {/*<div className={styles.break}/>*/}

                            {/*<div*/}
                            {/*    className={styles.childCard}*/}
                            {/*    style={{marginRight: window.innerWidth > 960? "10px" : "0"}}*/}
                            {/*    data-tip="Ce bouton ne restera pas ici. C'est pour tester lors du développement ;)"*/}
                            {/*>*/}
                            {/*    <CardTemplateHome*/}
                            {/*        icon={faTruck}*/}
                            {/*        title={"Livraison"}*/}
                            {/*        link={"/delivery"}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*{window.innerWidth <= 960 &&*/}
                            {/*    <div className={styles.break}/>*/}
                            {/*}*/}

                            {/*<div*/}
                            {/*    className={styles.childCard}*/}
                            {/*    style={{marginLeft: window.innerWidth > 960? "10px" : "0", marginRight: window.innerWidth > 960? "10px" : "0"}}*/}
                            {/*    data-tip="Ce bouton ne restera pas ici. C'est pour tester lors du développement ;)"*/}
                            {/*>*/}
                            {/*    <CardTemplateHome*/}
                            {/*        icon={faShoppingBasket}*/}
                            {/*        title={"Panier"}*/}
                            {/*        link={"/additem"}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*{window.innerWidth <= 960 &&*/}
                            {/*<div className={styles.break}/>*/}
                            {/*}*/}

                            {/*<div*/}
                            {/*    className={styles.childCard}*/}
                            {/*    style={{marginLeft: window.innerWidth > 960? "10px" : "0", marginRight: window.innerWidth > 960? "10px" : "0"}}*/}
                            {/*    data-tip="Ce bouton ne restera pas ici. C'est pour tester lors du développement ;)"*/}
                            {/*>*/}
                            {/*    <CardTemplateHome*/}
                            {/*        icon={faBox}*/}
                            {/*        title={"Abonnement"}*/}
                            {/*        link={"/commitment"}*/}
                            {/*    />*/}
                            {/*</div>*/}

                        </div>
                    </div>
                    <ReactTooltip multiline={true}/>
                </div>
            );
        } else{
            this.getUsersInfo();
            return (
                <div style={{height: "1000000px", backgroundColor:"#FFF"}}/>
            );
        }
    }
}

export default connect(mapStateToProps)(Home);
