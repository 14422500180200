import styles from './Profile.module.css';
import React from "react"
import moment from "moment";
import LoggedComponent, {mapStateToProps} from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import {
    faEuroSign, faExclamation,
    faExclamationTriangle,
    faFemale, faInfo,
    faLock,
    faMale,
    faTruck,
    faUser,
    faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import CardTemplate from "../Card/CardTemplate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FilAriane from "../header_footer/FilAriane";
import {connect} from "react-redux";
import Bandeau from "../header_footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";
import {encode} from "../../Services/ObfuscatorService";

class Profile extends LoggedComponent {


    doesMustLog = () => {
        this.mustLog = 1
    }

    getDeliveryAddress = () => {
        let delAddresses = []
        for (let i in this.state.user.addresses) {
            let address = this.state.user.addresses[i];
            if (address.type === 0 && address.default) {
                if(address.streetAddressMore != ""){
                    delAddresses = [
                        address.firstName + " " + address.lastName,
                        address.streetAddress,
                        address.streetAddressMore,
                        address.postalCode + " " + address.city,
                        address.country,
                        address.phone
                    ]
                } else {
                    delAddresses = [
                        address.firstName + " " + address.lastName,
                        address.streetAddress,
                        address.postalCode + " " + address.city,
                        address.country,
                        address.phone
                    ]
                }
            }
        }

        if(delAddresses.length === 0){
            delAddresses.push("Non renseigné")
        }

        return delAddresses
    }

    getBillingAddress = () => {
        let billAddresses = []
        for (let i in this.state.user.addresses) {
            let address = this.state.user.addresses[i]
            if (address.type === 1  && address.default) {
                billAddresses = [
                    address.firstName + " " + address.lastName,
                    address.streetAddress,
                    address.postalCode + " " + address.city,
                    address.country
                ]
            }

        }

        if(billAddresses.length === 0){
            billAddresses.push("Non renseigné")
        }

        return billAddresses
    }

    getAge = (date) => {
        var today = new Date();
        var birthDate = new Date(date.replace(" ", "T"));
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    displayChildren = (nbChild) => {
        let children = []
        let missing = nbChild - (this.state.children.length % nbChild);
        let currentLine = 0;
        for (let c in this.state.children) {
            if (c >= nbChild*currentLine + nbChild){
                children.push(<div className={styles.break}/>)
                currentLine++;
            }

            let child = this.state.children[c].user
            children.push(
                <div className={styles.childCard} style={{
                    marginLeft: c%nbChild === 0 ? "0" : "10px",
                    marginRight: c%nbChild === nbChild - 1 ? "0" : "10px",
                }}>
                    <CardTemplate
                        icon={child.genderLetter === "F" ? faFemale : faMale}
                        title={child.firstName + ", " + this.getAge(child.birthDate.date) + " ans"}
                        infos={[
                            child.firstName + " " + child.lastName,
                            child.genderLetter === "F" ? "Née le " + moment(child.birthDate.date).format("DD/MM/YYYY") : "Né le " + moment(child.birthDate.date).format("DD/MM/YYYY"),
                            child.email ? child.email : <br/>,
                            <br/>
                        ]}
                        links={[["Gérer le profil", "link", "/edit/" + encode(child.id)], child.username || child.email ? [child.password ? "Changer le mot de passe" : "Créer un mot de passe", "link", '/resetpwd/' + encode(child.id)] : null]}
                    />
                </div>
            )
        }

        if(missing === nbChild || nbChild === 1){
            children.push(<div className={styles.break}/>)
            missing = nbChild;
        }

        children.push(
            <div className={styles.childCard} style={{
                marginLeft: missing === nbChild ? "0" : "10px",
                marginRight: missing === 1 ? "0" : "10px",
            }}>
                <div className={globalStyles.card} style={{padding: "20px", flex: 1, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column"}}>
                    <h2 style={{margin: "10px 0"}}>
                        <FontAwesomeIcon icon={faUserPlus} style={{marginRight: "10px"}}/>
                        Nouveau profil
                    </h2>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1, margin: "30px 0"}}>
                        <a className={globalStyles.defaultBtn}  href={"/addchild"}>
                            Ajouter
                        </a>
                    </div>
                </div>
            </div>
        )

        missing--;

        for(let i = 0; i < missing; i++){
            children.push(
                <div style={{flex: 1, display: "flex",  flexDirection: "row", margin: "10px"}}>
                </div>
            )
        }
        return children
    }

    renderChild () {

        return (
            <div>
                <Bandeau title={titleAriane["profil"]["title"]}/>

                <FilAriane
                    links={[
                        [
                            titleAriane["profil"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["profil"]["ariane"][1],
                            ""
                        ],
                    ]}
                />

                <div className={`${globalStyles.globalContainer} ${styles.homeContainer}`}>

                    {this.state.user.isEntity && this.state.user.isSchool && !this.state.user.isSchoolValidate &&
                        <div className={globalStyles.bulle} style={{borderColor: "#ce3534"}}>
                            <span className={globalStyles.iconBulle} style={{backgroundColor: "#ce3534"}}>
                                <FontAwesomeIcon icon={faExclamation}/>
                            </span>
                            <span className={globalStyles.iconText}>
                                Votre compte enseignant doit être validé par nos équipes pour que vous puissiez bénéficier de nos tarifs préférentiels.
                                Nous allons prendre contact avec vous très prochainement.
                            </span>
                        </div>
                    }

                    {this.state.user.isEntity && this.state.user.isSchool && this.state.user.isSchoolValidate &&
                        <div className={globalStyles.bulle}>
                            <span className={globalStyles.iconBulle}>
                                <FontAwesomeIcon icon={faInfo}/>
                            </span>
                            <span className={globalStyles.iconText}>
                                Votre compte enseignant a été validé. Pour profiter des prix enseignant, contactez <a href="mailto://support@atorika.fr" className={globalStyles.defaultLink}>support@atorika.fr</a>.
                            </span>
                        </div>
                    }

                    <div className={styles.cardContainer}>
                        <CardTemplate
                            icon={faUser}
                            title={this.state.user.firstName + " " + this.state.user.lastName}
                            infos={[
                                this.state.user.genderLetter === "F" ?
                                    "Née le " + moment(this.state.user.birthDate.date).format("DD/MM/YYYY"):
                                    "Né le " + moment(this.state.user.birthDate.date).format("DD/MM/YYYY")
                                ,
                                this.state.user.email,
                                this.state.user.isEntity ? this.state.user.entityName : null
                            ]}
                            links={
                                [
                                    [
                                        "Gérer votre profil",
                                        "link",
                                        "/edit/" + encode(this.state.user.id)
                                    ],
                                    [
                                        "Changer le mot de passe",
                                        "link",
                                        '/resetpwd/' + encode(this.state.user.id)
                                    ]
                                ]
                            }
                        />
                    </div>

                    <div className={styles.cardContainer}>
                        <div className={styles.addressContainer} style={{marginRight: "10px", marginBottom : window.innerWidth > 960 ? 0 : "20px"}}>
                            <CardTemplate
                                color={"white"}
                                icon={faTruck}
                                title={"Préférence de livraison"}
                                infos={this.getDeliveryAddress()}
                                links={[["Gérer vos adresses", "link", "/address"]]}
                            />
                        </div>
                        <div className={styles.addressContainer} style={{marginLeft: "10px"}}>
                            <CardTemplate
                                color={"white"}
                                icon={faEuroSign}
                                title={"Adresse de facturation"}
                                infos={this.getBillingAddress()}
                                links={[["Gérer vos adresses", "link", "/address"]]}
                            />
                        </div>
                    </div>


                    <div className={styles.cardContainer} style={{flexWrap: "wrap", margin: "auto -10px"}}>
                        {window.innerWidth > 960 ?
                            this.displayChildren(3) :
                            this.displayChildren(1)
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Profile);
