import React from "react";
import FlashMessage from 'react-flash-message';
import styles from './Flash.module.css';
import {faCheck, faExclamation, faInfo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Flash (props) {

    return (
        <FlashMessage duration={5000} persistOnHover={true}>
            <div className={styles.flash}>
                <div className={styles.bulle} style={{borderColor: props.type === "success" || props.type === "info" ? "#6fa939" : "#ce3534"}}>
                <span className={styles.iconBulle} style={{background: props.type === "success" || props.type === "info" ? "#6fa939" : "#ce3534"}}>
                    {props.type === "success" ?
                        <FontAwesomeIcon icon={faCheck}/> :
                        (props.type === "info" ?
                            <FontAwesomeIcon icon={faInfo}/> :
                            <FontAwesomeIcon icon={faExclamation}/>
                        )
                    }
                </span>
                <span className={styles.iconText}>
                    {props.content}
                </span>
                </div>
            </div>
        </FlashMessage>


    )
}

export default Flash;
