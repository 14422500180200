import React from "react";
import globalStyles from '../../Styles/Style.module.css';

function CardTemplatePackage(props) {

    const displayLink = (links) => {
        let url = []
        for(let i in links){
            url.push(
                <a className={globalStyles.defaultLink} href={links[i][1]}>
                    {links[i][0]}
                </a>
            )
        }
        return url
    }

    const displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            informations.push(
                <div>
                    {infos[i]}
                </div>
            )
        }
        return informations
    }




    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: props.color ? props.color : "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "column"
            }}
        >
            <div style={{flex: 5}}>
                <h3 style={{margin: "0 0 15px 0"}}>
                    Colis {props.identifier}
                </h3>


                <span
                    className={globalStyles.badge}
                    style={{
                        backgroundColor:
                            props.stateNb === 3 ?
                                "#6fa939" :
                                (props.stateNb === 2 ?
                                        "#f4bc00" :
                                        (props.stateNb === 1 ?
                                                "#f4bc00" :
                                                (props.stateNb === 0 ?
                                                        "#52BAEC" :
                                                    (props.stateNb === -1 ?
                                                            "#ce3534" :
                                                            "#C4C4C4"
                                                    )
                                                )
                                        )
                                ),
                    }}>
                    {props.state}
                </span>


                <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                    {displayInfos(props.infos)}
                </div>
            </div>

            <div
                style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end"
                }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                    {displayLink(props.links)}
                </div>
            </div>
        </div>
    );
}

export default CardTemplatePackage;