import {useParams} from "react-router-dom";
import React from "react";
import EditAddress from "./EditAddress";
import {decode} from "../../Services/ObfuscatorService";

function GetIdAddressEdit () {
    let {id} = useParams()

    return (
        <EditAddress id={decode(id)}/>
    )
}

export default GetIdAddressEdit;
