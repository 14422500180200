import styles from './NotFound.module.css';
import React from "react"
import LoggedComponent, {mapStateToProps} from "../Services/ConnexionService";
import globalStyles from  '../Styles/Style.module.css';
import robot from '../assets/utils/robot404.svg';
import {connect} from "react-redux";

class NotFound extends LoggedComponent {


    doesMustLog = () => {
        this.mustLog = 1
    }

    renderChild () {
        return (
            <div className={`${globalStyles.globalContainer} ${styles.container}`}>
                <div className={styles.subContainer}>
                    <div className={styles.robot}>
                        <img src={robot} style={{width: "80%"}}/>
                    </div>
                    <div className={styles.infos}>
                        <h1 className={styles.title}>Oops !</h1>

                        <h2 className={styles.subTitle}>L'expérience a mal tourné avec cette page...</h2>

                        <div className={styles.text}>
                            Il s’agit d’une erreur 404. <br/>
                            Vous pouvez vérifier si le lien que vous essayez d’ouvrir est correct. <br/>
                            Vous pouvez aussi aller sur la page d’accueil. <br/>
                        </div>

                        <a className={globalStyles.defaultBtn} href={"/"}>
                            Aller sur la page d'accueil
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(NotFound);
