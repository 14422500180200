import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {encode} from "../../Services/ObfuscatorService";

function CardUserHome(props) {

    return (
        <div className={`${globalStyles.card} ${globalStyles.cardMore}`} style={{backgroundColor: "white"}}>
            <div style={{flex: 5}}>
                <h2 style={{margin: "0 0 15px 0"}}>
                    <FontAwesomeIcon icon={props.icon} style={{marginRight: "15px"}}/>
                    {props.title}
                </h2>

                <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                    Choisissez une première activité pour gagner de l'expérience !
                </div>
            </div>

            <div
                style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end"
                }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                    <a className={globalStyles.defaultLink} href={"/edit/" + encode(props.id)}>Modifier le profil</a>
                    {props.password &&
                        <a className={globalStyles.defaultLink} href={"/resetpwd/" + encode(props.id)}>{props.password}</a>
                    }
                </div>
            </div>
        </div>
    );
}

export default CardUserHome;
