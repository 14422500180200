import {useParams} from "react-router-dom";
import React from "react";
import {decode} from "../../Services/ObfuscatorService";
import CommitmentChoose from "./CommitmentChoose";

function GetIdCommitmentChoose () {
    let {id} = useParams()

    return (
        <CommitmentChoose id={decode(id)}/>
    )
}

export default GetIdCommitmentChoose;
