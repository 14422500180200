import React from "react";
import globalStyles from  '../../Styles/Style.module.css';
import styles from "./FilAriane.module.css"

function FilAriane(props) {

    const displayLink = (links) => {
        let url = []
        for(let i = 0; i < links.length - 1; i++){
            if(links[i][1] === "btn"){
                url.push(
                    <button type="button" className={globalStyles.defaultLink} onClick={() => props.function(props.state)}>
                        {links[i][0]}
                    </button>
                )
            } else {
                url.push(
                    <a className={globalStyles.defaultLink} href={links[i][1]}>
                        {links[i][0]}
                    </a>
                )
            }
            url.push(" › ")
        }

        url.push(links[links.length - 1][0])

        return url
    }

    const displayLinkMobile = (links) => {
        let url = []

        if(links.length > 1) {
            if (links[0][1] === "btn") {
                url.push(
                    <button type="button" className={globalStyles.defaultLink}
                            onClick={() => props.function(props.state)}>
                        {links[0][0]}
                    </button>
                )
            } else {
                url.push(
                    <a className={globalStyles.defaultLink} href={links[0][1]}>
                        {links[0][0]}
                    </a>
                )
            }

        }

        if(links.length > 3) {
            url.push(" › ")
            url.push("...");
        }



        if(links.length > 2) {
            url.push(" › ")
            if (links[links.length - 2][1] === "btn") {
                url.push(
                    <button type="button" className={globalStyles.defaultLink}
                            onClick={() => props.function(props.state)}>
                        {links[links.length - 2][0]}
                    </button>
                )
            } else {
                url.push(
                    <a className={globalStyles.defaultLink} href={links[links.length - 2][1]}>
                        {links[links.length - 2][0]}
                    </a>
                )
            }
        }

        return url
    }

    return (
        <div className={styles.mainContainer} style={{marginTop: props.alone ? "80px" : undefined}}>
            <div className={styles.contentContainer} style={{marginTop: props.alone ? "15px" : undefined}}>
                {window.innerWidth > 1020 ? displayLink(props.links) : displayLinkMobile(props.links)}
            </div>
        </div>
    );

}

export default FilAriane;
