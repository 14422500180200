import {useParams} from "react-router-dom";
import React from "react";
import EditProfile from "./EditProfile";
import {decode} from "../../Services/ObfuscatorService";

function GetIdProfileEdit () {
    let {id} = useParams()

    return (
        <EditProfile id={id.startsWith('__appli__') ? parseInt(id.substring(9)) : decode(id)}/>
    )
}

export default GetIdProfileEdit;
