import React from "react"
import LoggedComponent, {api_url, mapStateToProps} from "../../../Services/ConnexionService";
import {connect} from "react-redux";
import globalStyles from "../../../Styles/Style.module.css";
import formStyles from "../../../Styles/FormStyle.module.css";
import FilAriane from "../../header_footer/FilAriane";
import styles from "./Payement.module.css"
import {
    faCity,
    faEuroSign, faMailBulk, faMoneyBill, faShoppingBasket, faTag, faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Bandeau from "../../header_footer/Bandeau";
import CardTemplateBilling from "./CardTemplateBilling";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Basketitem from "./Basketitem";
import AddAddressDelivery from "../Delivery/AddAdressDelivery";
import Modal from "react-modal";
import ListAddress from "../../Utils/Address/ListAddress";
import titleAriane from "../../../Helpers/TitleAriane.json";

import visa from "../../../assets/payment/visa.png"
import masterCard from "../../../assets/payment/master-card.png"
import carteBleue from "../../../assets/payment/cb.jpg"
import payplug from "../../../assets/payment/payplug.svg"
import DisplayPrice from "../../Utils/DisplayPrice";
import {encode} from "../../../Services/ObfuscatorService";
import ato_mail from "../../../assets/mascottes/ato_mail.svg";
import rika_mail from "../../../assets/mascottes/rika_mail.svg";

class Payment extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            address: {
                id: undefined,
                firstName: undefined,
                lastName: undefined,
                streetAddress: undefined,
                city: undefined,
                postalCode: undefined,
                country: undefined,
            },
            basket: undefined,
            hasPromo: false,
            promo: null,
            clickPay: false,
            countries: undefined
        }
        this.loading = true;
        this.isOpen = false;
        this.loadingAddress = true;
        this.isOpenAdd = false;
        this.payUrl = '';
        this.timeoutSeconds = 0;
        this.timeout = false;
        this.loadingCountries = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    componentDidMount() {
        const scriptPayplug = document.createElement("script")
        scriptPayplug.type = "text/javascript"
        scriptPayplug.src = "https://api.payplug.com/js/1/form.latest.js"
        scriptPayplug.async = true
        document.head.appendChild(scriptPayplug)
        this.timeoutInterval = setInterval(this.computeTimeout.bind(this), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.timeoutInterval);
    }

    getCountries() {
        let request = new Request(api_url + '/client/countries', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({countries: data.countries})
            this.loadingCountries = false;
            this.forceUpdate();
        })
    }

    submit = () => {
        this.setState({clickPay: true});
        let data = new FormData();
        let dataJson = {
            firstName: this.state.address.firstName,
            lastName: this.state.address.lastName,
            streetAddress: this.state.address.streetAddress,
            city: this.state.address.city,
            postalCode: this.state.address.postalCode,
            country: this.state.address.country,
        }
        data.append('json', JSON.stringify(dataJson));

        let request = new Request(api_url + '/client/basket/validate/' + this.props.id, {method:'POST', body: data, credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            if(data["validate"]){
                let request2 = new Request(api_url + '/client/pay/retrieve/' + this.props.id + '/' + encode(this.props.id), {method:'POST', credentials:'include'})
                this.fetchJsonOrError(request2, (json) => {
                    this.payUrl = json.url;
                    this.showPayment()
                    this.forceUpdate();
                })
            }
        })
    }

    showPayment = () => {
        const scriptUsePayplug = document.createElement("script")
        scriptUsePayplug.type = "text/javascript"
        scriptUsePayplug.text = "setTimeout(()=>{Payplug.showPayment('"+this.payUrl+"');}, 500);"
        scriptUsePayplug.async = true

        document.head.appendChild(scriptUsePayplug)

        setTimeout(this.isShown, 500)
    }

    isShown = () => {
        this.setState({clickPay: false})
    }


    setBillingAddress = () => {
        for (let i in this.state.user.addresses) {
            let address = this.state.user.addresses[i];
            if (address.type === 1 && address.default) {
                this.setState({
                    address : {
                        id: address.id,
                        firstName: address.firstName,
                        lastName: address.lastName,
                        streetAddress: address.streetAddress,
                        city: address.city,
                        postalCode: address.postalCode,
                        country: address.country
                    }
                });
                this.loadingAddress = false;
                this.forceUpdate();
            }
        }
    }

    getAddress = () => {
        if(this.loadingAddress){
            this.setBillingAddress()
        }
        if(this.state.address.id){
            return [
                this.state.address.firstName + " " + this.state.address.lastName,
                this.state.address.streetAddress,
                this.state.address.postalCode + " " + this.state.address.city,
                this.state.country
            ];
        }

        return ["Merci de saisir une adresse de facturation pour pouvoir procéder au paiement"];

    }

    setAddress = (id, firstName, lastName, streetAddress, streetAddressMore, postalCode, city, country, phone) => {
        this.setState({
            address : {
                id: id,
                firstName: firstName,
                lastName: lastName,
                streetAddress: streetAddress,
                streetAddressMore: streetAddressMore,
                postalCode: postalCode,
                city: city,
                country: country,
                phone: phone
            },
        })
        this.closeModal();
    }

    openModal = () => {
        this.isOpen = true;
        this.forceUpdate();
    }

    closeModal = () => {
        this.isOpen = false;
        this.forceUpdate();
    }

    openModalAdd = () => {
        this.isOpenAdd = true;
        this.forceUpdate();
    }

    closeModalAdd = () => {
        this.isOpenAdd = false;
        this.forceUpdate();
    }

    getBasket(){
        let request = new Request(api_url + '/client/order/' + this.props.id, {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({basket: data});
            this.loading = false;
            if(this.state.basket.explanation) {
                let exp = this.state.basket.explanation
                let expSplit = exp.split('//')
                for (let i in expSplit) {
                    if (expSplit[i].startsWith('PROMOCODE=')) {
                        this.setState({promo : expSplit[i].split('=')[1], hasPromo: true})
                    }
                }
            }
            this.forceUpdate();
        })
    }

    displayBasketItem() {
        let items = [];
        for(let i = 0; i < this.state.basket.articles.length; i++){
            let isFor = "";
            if(this.state.basket.articles[i].user){
                isFor = "Pour : " + this.state.basket.articles[i].userName;
            } else {
                isFor = "Offrir en cadeau";
            }

            let duration = null;
            if(this.state.basket.articles[i].article.duration && this.state.basket.articles[i].article.type === "commitment"){
                duration = "Durée : " + this.state.basket.articles[i].article.duration + " mois";
            }

            let firstBox = null;

            if(this.state.basket.articles[i].firstBox){
                firstBox = "Première box : " + this.state.basket.articles[i].firstBox.article.name;
            }

            items.push(
                <Basketitem
                    title={this.state.basket.articles[i].article.name}
                    price={this.state.basket.articles[i].priceTtc/100}
                    img={this.state.basket.articles[i].article.media[0]}
                    unitPrice={this.state.basket.articles[i].article.unitPriceTtc ? parseInt(this.state.basket.articles[i].article.unitPriceTtc)/100 : this.state.basket.articles[i].article.priceTtc/100}
                    infos={[
                        duration,
                        isFor,
                        "Quantité : " + this.state.basket.articles[i].quantity,
                        firstBox
                    ]}
                />
            )
        }

        return items;
    }

    computeTimeout() {
        this.timeoutSeconds = this.timeoutSeconds + 1;
        if(this.timeoutSeconds >= 10*60) {
            clearInterval(this.timeoutInterval);
            this.timeout = true;
            console.log(this.timeout)
            this.forceUpdate();
        }
    }

    displayTimeoutModal() {
        if(this.timeout) {
            return (
                <Modal
                    isOpen={true}
                    contentLabel="Etes-vous encore là ?"
                    style={{
                        content : {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 50000,
                            width: "40%",
                            padding: "40px",
                            paddingTop: "20px"
                        },
                        overlay : {
                            zIndex: 150000,
                            backgroundColor: 'rgba(0, 0, 0, 0.6)'
                        }
                    }}
                    bodyOpenClassName={"noScroll"}
                >
                    <div className={styles.subContainer} style={{flexDirection: "column", display: "flex"}}>
                        <h2 className={styles.title}>Etes-vous encore là ?</h2>
                        <div style={{flexDirection: "row", width:"100%", display: "flex", justifyContent: "center", marginBottom: "40px"}}>
                            <div style={{flexDirection: "row", width:"20%", display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
                                <img src={ato_mail} style={{width: "120px"}}/>
                                <img src={rika_mail} style={{width: "120px"}}/>
                            </div>
                        </div>
                        <div className={styles.infos}>
                            <button className={globalStyles.defaultBtn} onClick={() => this.timeoutReload()}>
                                Je suis là !
                            </button>
                        </div>
                    </div>
                    <button
                        style={{
                            position: "absolute",
                            top: "20px",
                            right: "20px",
                            background: "none",
                            border: "none",
                            fontSize: "20px",
                            cursor: "pointer"
                        }}
                        onClick={() => this.timeoutReload()}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </Modal>
            )
        }
        return <div/>
    }

    timeoutReload() {
        window.location.reload()
    }

    calculateSubTotal() {
        let subtotal = 0;
        for(let i = 0; i < this.state.basket.articles.length; i++){
            subtotal += parseInt(this.state.basket.articles[i].priceTtc);
        }
        subtotal += this.state.basket.delivery.price;
        return subtotal/100;
    }

    calculateSubTotalWithPromo(){
        let subtotal = 0;
        for(let i = 0; i < this.state.basket.articles.length; i++){
            subtotal += parseInt(this.state.basket.articles[i].priceTtc);
        }
        subtotal += this.state.basket.delivery.price;
        subtotal -= this.state.basket.appliedPromo;
        return subtotal/100;
    }


    renderChild () {
        if(this.loadingCountries) this.getCountries()

        if(!this.loading){
            return (
                <div>

                    <Bandeau title={titleAriane["payment"]["title"]}/>
                    <FilAriane
                        links={[
                            [
                                titleAriane["payment"]["ariane"][0],
                                "/basket"
                            ],
                            [
                                titleAriane["payment"]["ariane"][1],
                                "/delivery/" + encode(this.props.id)
                            ],
                            [
                                titleAriane["payment"]["ariane"][2],
                                ""
                            ]
                        ]}
                    />

                    <div className={globalStyles.globalContainer} style={{flexDirection: "column", alignItems: "unset", padding: window.innerWidth > 960 ? "unset" : "10px"}}>

                        <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                            <div className={styles.cardContainer} style={{flex: 2, margin: window.innerWidth > 1020 ? "10px" : "10px 0"}}>
                                <CardTemplateBilling
                                    icon={faEuroSign}
                                    title={"Adresse de facturation"}
                                    infos={this.getAddress()}
                                    button={["Changer d'adresse", this.openModal.bind(this)]}
                                    hasAddress={this.state.address.id !== undefined}
                                />
                            </div>
                            <div className={styles.cardContainer} style={{flex: 3, margin: window.innerWidth > 1020 ? "10px" : "10px 0"}}>
                                <div
                                    className={globalStyles.card}
                                    style={{
                                        padding: "30px",
                                        flex: 1,
                                        backgroundColor: "white",
                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                        borderRadius: "5px",
                                        display: "flex",
                                        height: "100%",
                                        boxSizing: "border-box",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                                        <h2 style={{margin: "10px 0", flex: 2}}>Mode de paiement</h2>
                                        <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: window.innerWidth > 1020 ? "flex-end" : "flex-start"}}>
                                            <div style={{
                                                border: "1px solid #ced4da",
                                                padding: "10px 4px",
                                                borderRadius: "3px",
                                                margin: "0 2px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "50px"
                                            }}>
                                                <img src={visa} height={"15px"}/>
                                            </div>
                                            <div style={{
                                                border: "1px solid #ced4da",
                                                padding: "0 2px",
                                                borderRadius: "3px",
                                                margin: "0 2px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "50px"
                                            }}>
                                                <img src={masterCard} height={"35px"}/>
                                            </div>
                                            <div style={{
                                                border: "1px solid #ced4da",
                                                padding: "5px 3px",
                                                borderRadius: "3px",
                                                margin: "0 2px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "50px"
                                            }}>
                                                <img src={carteBleue} height={"25px"}/>
                                            </div>
                                        </div>
                                    </div>
                                    <p><input type={"radio"} checked={true}/> Utiliser une carte bancaire</p>

                                    <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column", flex: 1, alignItems: window.innerWidth > 1020 ? "flex-end" : "flex-start", textAlign: "left", marginTop: "10px"}}>
                                        <div style={{flex: 1}}>
                                            <p style={{margin: 0, fontSize: "0.7rem", marginBottom: "4px"}}>
                                                Paiement sécurisé par
                                            </p>
                                            <img src={payplug} height={"25px"} alt={"Logo Payplug"}/>
                                        </div>

                                        <div style={{flex: 1, display: "flex", justifyContent: "flex-end", marginTop: window.innerWidth > 1020 ? undefined : "10px"}}>
                                            <button
                                                className={!this.state.address.id || this.state.clickPay ? formStyles.disabledBtn : globalStyles.defaultBtn}
                                                onClick={this.submit}
                                                disabled={!this.state.address.id || this.state.clickPay}
                                                style={{fontSize: window.innerWidth > 1020 ? undefined : "1rem"}}
                                            >
                                                Procéder au paiement
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={styles.cardContainer} style={{margin: window.innerWidth > 1020 ? "10px" : "10px 0", width: "unset"}}>
                            <div
                                className={globalStyles.card}
                                style={{
                                    padding: "30px",
                                    flex: 1,
                                    backgroundColor: "#f3f6f8",
                                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                    borderRadius: "5px",
                                    display: "flex",
                                    height: "100%",
                                    boxSizing: "border-box",
                                    flexDirection: "column",
                                }}
                            >
                                <div style={{flex: 5}}>
                                    <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                                        <h2 style={{margin: "auto 0", flex: 2}}>
                                            <FontAwesomeIcon icon={faShoppingBasket} style={{marginRight: "15px"}}/>
                                            {window.innerWidth > 1020 ? "Récapitulatif de votre commande" : "Votre commande"}
                                        </h2>

                                        <div style={{
                                            flex: 1,
                                            textAlign: "right",
                                        }}
                                        >
                                            {this.state.hasPromo ?
                                                <h2 style={{textAlign: window.innerWidth > 1020 ? "right" : "left", margin: "10px 0"}}>
                                                    Total : <DisplayPrice price={this.calculateSubTotal()} strike={true}/> <DisplayPrice price={this.calculateSubTotalWithPromo()} color={"#6FA939"}/>
                                                </h2>
                                                :
                                                <h2 style={{textAlign: window.innerWidth > 1020 ? "right" : "left", margin: "10px 0"}}>
                                                    Total : <DisplayPrice price = {this.calculateSubTotal()}/>
                                                </h2>
                                            }
                                        </div>
                                    </div>

                                    <hr style={{border: "solid 0.1px rgb(206, 212, 218)"}}/>


                                    {this.displayBasketItem()}

                                    <div style={{marginBottom: "20px", marginTop: "20px", display: "flex", flexDirection: "row"}}>

                                        <div style={{flex: 4, flexDirection: "column"}}>
                                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                                <div style={{flex: 2}}>
                                                    <h3 style={{margin: "0"}}>
                                                        {this.state.basket.delivery.typeName}
                                                    </h3>
                                                </div>
                                                <div style={{flex: 1, textAlign: "right"}}>
                                                    <DisplayPrice price={this.state.basket.delivery.price/100}/>
                                                </div>
                                            </div>

                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <div>
                                                    {this.state.basket.delivery.firstName + " " + this.state.basket.delivery.lastName}
                                                </div>
                                                {this.state.basket.delivery.type === 1 &&
                                                    <div>
                                                        {this.state.basket.delivery.relayName}
                                                    </div>
                                                }
                                                {this.state.basket.delivery.type === 2 &&
                                                    <div>
                                                        <div>
                                                            {this.state.basket.delivery.address}
                                                        </div>
                                                        <div>
                                                            {this.state.basket.delivery.addressMore}
                                                        </div>
                                                        <div>
                                                            {this.state.basket.delivery.postCode + " " + this.state.basket.delivery.city}
                                                        </div>
                                                        <div>
                                                            {this.state.basket.delivery.country}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                {this.state.hasPromo &&
                                    <div style={{marginBottom: "20px", marginTop: "20px", display: "flex", flexDirection: "row"}}>

                                        <div style={{flex: 4, flexDirection: "column"}}>
                                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                                <div style={{flex: 2}}>
                                                    <h3 style={{margin: "0"}}>
                                                        {this.state.promo}
                                                    </h3>
                                                </div>
                                                <div style={{flex: 1, textAlign: "right"}}>
                                                    -<DisplayPrice price={this.state.basket.appliedPromo / 100}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                        <div style={{fontSize: "14px", textAlign: "center", color: "#777", marginTop: "10px", marginBottom: "50px"}}>
                            Le total de la commande inclut la TVA.
                            En passant votre commande, vous acceptez nos <a style={{color: "#777"}} href={"https://www.atorika.fr/terms"} target={"_blank"} rel={"noopener noreferrer"}>conditions générales de vente</a> et la <a style={{color: "#777"}} href={"https://www.atorika.fr/policy"} target={"_blank"} rel={"noopener noreferrer"}>politique de confidentitalité</a>.
                        </div>
                    </div>

                    <Modal
                        isOpen={this.isOpen}
                        contentLabel="Adresse de facturation"
                        style={{
                            content : {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 500,
                                width:  window.innerWidth > 1020 ? "70%" : "calc(100% - 60px)",
                                padding: "40px",
                                paddingTop: "20px",
                                borderRadius: "15px"
                            },
                            overlay : {
                                zIndex: 1500,
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            }
                        }}
                    >
                        <ListAddress
                            title={"Adresse de facturation"}
                            desktopNumber={3}
                            mobileNumber={1}
                            iconTitle={faMoneyBill}
                            type={1}
                            fetchJsonOrError={this.fetchJsonOrError}
                            state={this.state}
                            add={["btn", this.openModalAdd.bind(this)]}
                            choose={true}
                            chooseFtn={this.setAddress.bind(this)}
                        />
                        <button
                            style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                background: "none",
                                border: "none",
                                fontSize: "20px",
                                cursor: "pointer"
                            }}
                            onClick={() => this.closeModal()}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    </Modal>

                    <Modal
                        isOpen={this.isOpenAdd}
                        contentLabel="Adresse de livraison"
                        style={{
                            content : {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 500,
                                width: "70%",
                                padding: "40px",
                                paddingTop: "20px",
                                maxHeight: (window.innerHeight - 180) + "px",
                            },
                            overlay : {
                                zIndex: 1500,
                                backgroundColor: "rgba(0, 0, 0, 0.6)"
                            }
                        }}
                    >
                        <AddAddressDelivery
                            fetchJsonOrError={this.fetchJsonOrError}
                            countries={this.state.countries}
                        />
                        <button
                            style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                background: "none",
                                border: "none",
                                fontSize: "20px",
                                cursor: "pointer"
                            }}
                            onClick={() => this.closeModalAdd()}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    </Modal>

                    {this.displayTimeoutModal()}
                </div>
            );
        }

        this.getBasket()
        return (
            <div style={{height: "1000000px", backgroundColor:"#FFF"}}/>
        )
    }
}

export default connect(mapStateToProps)(Payment);
