import React from 'react';
import fr from 'date-fns/locale/fr';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faChild,
    faEnvelope,
    faFemale,
    faIdBadge, faInfo,
    faMale,
    faQuestionCircle,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import LoggedComponent, {api_url, mapStateToProps} from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import FilAriane from "../header_footer/FilAriane";
import formStyles from '../../Styles/FormStyle.module.css';
import {connect} from "react-redux";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Bandeau from "../header_footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";

class AddChild extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            lastName: '',
            firstName: '',
            birth: '',
            gender: 'F',
            mail: '',
            username: ''
        };
        this.errors = {
            mail: null,
            cannotUseMail: false,
            cannotUseUsername: false
        };
        this.isLoading = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    create = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({...this.state, mail: this.state.mail === '' ? null:this.state.mail, email: this.state.mail}));
        let request = new Request(api_url + '/client/user/addchild/' + this.state.user.id, {method:'POST', body: data, credentials:'include'});
        this.fetchJsonOrError(request, (data) => {
            if(data.canUseMail && data.created && data.canUseUsername){
                this.setState({shouldNavigate: true, navigateTo: '/'});
            } else {
                this.errors.cannotUseMail = !data.canUseMail
                this.errors.cannotUseUsername = !data.canUseUsername
                this.forceUpdate()
            }
        })
    }

    getAge = (date) => {
        if(date){
            let today = new Date();
            let birthDate = new Date(date);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        return NaN;
    }

    renderChild() {

        const emailRegex = /\S+@\S+\.\S+/;


        const validateEmail = (event) => {
            const email = event.target.value;
            this.errors.mail = !emailRegex.test(email);
        };

        const formValid = () => {
            return (this.state.lastName.length > 0) && (this.state.firstName.length > 0) && (this.state.birth !== '') && (!isNaN(this.getAge(this.state.birth)) && this.getAge(this.state.birth) < 100);
        }

        return (
            <div>
                <Bandeau title={titleAriane["newProfile"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["newProfile"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["newProfile"]["ariane"][1],
                            ""
                        ]
                    ]}
                />

                <div className={globalStyles.globalContainer}>

                    <div style={{flex: 1}}>

                        <div className={globalStyles.bulle}>
                            <span className={globalStyles.iconBulle}>
                                <FontAwesomeIcon icon={faInfo}/>
                            </span>
                            <span className={globalStyles.iconText}>
                                En créant un nouveau profil, vous pourrez suivre les progrès de la personne que vous ajoutez via ses propres points d'expériences et badges.
                                Si vous renseignez une adresse email, ou un nom d'utilisateur et un mot de passe, cette personne pourra se connecter sur ce site et sur l'application mobile de manière totalement autonome.
                            </span>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>Nom*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faIdBadge}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Nom" value={this.state.lastName} onChange={(event) => this.setState({lastName: event.target.value.toUpperCase()})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Prénom*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faChild}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Prénom" value={this.state.firstName} onChange={(event) => this.setState({firstName: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroupBirthGender}>
                                <div className={formStyles.inputGroupGender}>
                                    <label>Sexe*</label>
                                    <div className={formStyles.inputContent}>
                                        <div className={formStyles.gender}>
                                            <button
                                                type="button"
                                                onClick={() => this.setState({gender: "F"})}
                                                className={this.state.gender === "F" ? `${formStyles.btnSelected} ${formStyles.btnFemale}` : `${formStyles.btnNotSelected} ${formStyles.btnFemale}`}
                                            >
                                                <FontAwesomeIcon icon={faFemale}/>
                                            </button>
                                            <button type="button" onClick={() => this.setState({gender: "M"})}  className={this.state.gender === "M" ? `${formStyles.btnSelected} ${formStyles.btnMale}` : `${formStyles.btnNotSelected} ${formStyles.btnMale}`}>
                                                <FontAwesomeIcon icon={faMale}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={formStyles.inputGroupBirth}>
                                    <div style={{marginBottom: "5px"}}>
                                        <label>Date de naissance*</label>
                                        {!isNaN(this.getAge(this.state.birth)) &&
                                            (this.getAge(this.state.birth) > 100 ?
                                                    <span style={{fontSize: "12px", color: "red", marginLeft: "5px"}}> {this.getAge(this.state.birth)} ans</span>
                                                    :
                                                    <span style={{fontSize: "12px", marginLeft: "5px"}}> {this.getAge(this.state.birth)} ans</span>
                                            )
                                        }
                                    </div>

                                    <div className={formStyles.inputField} style={{display: "inline", border: "none"}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                                            <Stack spacing={3}>
                                                <DatePicker
                                                    label="Date de naissance"
                                                    value={this.state.birth}
                                                    openTo="year"
                                                    views={['year', 'month', 'day']}
                                                    onChange={(date) => {
                                                        if(date){
                                                            date.setHours(12);
                                                        }
                                                        this.setState({birth: date});
                                                    }}
                                                    renderInput={(params) => <TextField size={"small"} {...params} helperText={null} />}
                                                    enableFuture={false}
                                                    inputFormat={"dd/MM/yyyy"}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                            </div>



                            <div className={formStyles.inputGroup}>
                                <label>
                                    E-mail
                                    <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle} tabIndex={-1} data-tip="L'e-mail nous servira à vous contacter <br/>pour le suivi de votre commande, <br/>la connexion à votre compte ..."/>
                                </label>
                                <div className={formStyles.inputField} style={this.errors.mail ? {borderColor: 'red'} : {}} >
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </div>
                                    <input
                                        type="text"
                                        required
                                        className={formStyles.input}
                                        placeholder="prenom.nom@exemple.fr"
                                        value={this.state.mail}
                                        onChange={(event) => {
                                            let cleanMail = event.target.value.replace(/\s/g, "");
                                            cleanMail = cleanMail.toLowerCase();
                                            this.setState({mail: cleanMail});
                                            validateEmail(event)}
                                    }/>
                                </div>
                            </div>
                        </div>
                        {this.errors.cannotUseMail &&
                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}/>
                            <div className={formStyles.inputGroup}>
                                <div className={formStyles.textDanger}>
                                    Cette adresse e-mail est déjà utilisé
                                </div>
                            </div>
                        </div>
                        }

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>Nom d'utilisateur</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faUser}/>
                                    </div>
                                    <input type="text" className={formStyles.input} placeholder="Nom d'utilisateur" value={this.state.username} onChange={(event) => this.setState({username: event.target.value.toLowerCase()})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                            </div>
                        </div>
                        {this.errors.cannotUseUsername &&
                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <div className={formStyles.textDanger}>
                                    Ce nom d'utilisateur est déjà utilisé
                                </div>
                            </div>
                            <div className={formStyles.inputGroup}/>
                        </div>
                        }

                        <div className={formStyles.btnContainer}>
                            <button type="button" className={formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn} onClick={() => this.create()} disabled={!formValid()}>
                                Enregistrer
                            </button>
                        </div>
                    </div>
                </div>
                <ReactTooltip multiline={true}/>
            </div>
        );
    };

}

export default connect(mapStateToProps)(AddChild);
