import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import robot from "../../assets/utils/robot404.svg"
import {faBox, faBoxOpen, faLightbulb} from "@fortawesome/free-solid-svg-icons";
import {api_url} from "../../Services/ConnexionService";
import {encode} from "../../Services/ObfuscatorService"
import moment from "moment";
import ThemeBadge from "../Utils/ThemeBadge";

function CardSuggestNextBox(props) {

    return (
        <div
            className={`${globalStyles.card} ${globalStyles.cardMore}`}
            style={{padding: "20px",}}
        >

            <h3 style={{margin: "10px 0"}}>
                <FontAwesomeIcon icon={props.commitment ? faBoxOpen : faLightbulb} style={{marginRight: "10px"}}/>
                {
                    props.commitment ?
                        "Prochaine box pour " + props.name
                        :
                        "Notre suggestion pour " + props.name
                }
            </h3>

            <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>

                <div style={{flexDirection: "column", marginTop: "26px", display: "flex", flex: 1}}>
                    <div style={{textAlign: "center", fontSize: "1,3em", display: "flex", alignItems: "center", justifyContent:"center"}}>
                        <div style={{width: "150px"}}>
                            <img
                                src={api_url + '/uploads/article/' + props.media}
                                style={{maxWidth: "100%", maxHeight: "100%", borderRadius: "8px", marginBottom: "5px"}}
                            />
                        </div>
                    </div>
                    <div style={{marginLeft: "10px", marginTop: "5px", textAlign: "center"}}>
                        <div style={{textAlign: "center", fontWeight: 500}}>
                            {props.boxName}
                        </div>
                        <div style={{fontSize: "14px", marginTop: "15px"}}>
                            <ThemeBadge
                                theme={props.theme}
                                bgColor={props.themeColor}
                                baseColor={props.themeBaseColor}
                            />

                            {props.secondaryTheme &&
                                <ThemeBadge
                                    theme={props.secondaryTheme}
                                    bgColor={props.secondaryThemeColor}
                                    baseColor={props.secondaryThemeBaseColor}
                                    style={{marginLeft: "5px"}}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 2,
                        flexDirection: "column",
                        marginTop: window.innerWidth < 1020 ? "10px" : undefined
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                textAlign: "center",
                                color: "black",
                                marginBottom: props.last ? "15px" : "32px",
                            }}
                        >
                            {
                                props.commitment ?
                                    <span>Voilà la box que nous préparons pour vous. <br/>
                                        {props.choose ?
                                            <span>Vous avez jusqu'au <b>{moment(props.date).format("DD/MM/YYYY")}</b> pour changer.</span>
                                            :
                                            <span>Vous la recevrez d'ici le <b>{moment(props.date).format("DD/MM/YYYY")}</b>.</span>}
                                </span>
                                    :
                                    props.description
                            }
                        </div>


                        {
                            props.commitment ?
                                (props.choose ?
                                        <a
                                            className={globalStyles.defaultBtn}
                                            href={"/commitment/choose/" + encode(props.chooseId)}
                                            style={{fontSize: "1rem"}}
                                        >
                                            Changer
                                        </a>
                                        :
                                        ""
                                )
                                :
                                <a
                                    className={globalStyles.defaultBtn}
                                    href={"/additem/" + props.reference}
                                    style={{fontSize: "1rem"}}
                                >
                                    Ajouter au panier
                                </a>
                        }
                    </div>

                    {props.last &&
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flex: 2,
                                flexDirection: "column",
                                marginTop: window.innerWidth < 1020 ? "10px" : undefined
                            }}
                        >
                            <div
                                style={{
                                    textAlign: "center",
                                    color: "black",
                                    marginTop: "32px",
                                    marginBottom: "15px"
                                }}
                            >
                                C'est la dernière box de votre abonnement, <br/> pensez à le renouveler !
                            </div>

                            <a
                                className={globalStyles.defaultBtn}
                                href={"/additem/" + props.commitmentRef}
                                style={{fontSize: "1rem"}}
                            >
                                Renouveler
                            </a>
                        </div>
                    }

                </div>



            </div>

        </div>
    );
}

export default CardSuggestNextBox;
