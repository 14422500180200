const initialState = {
    lastId: 0,
    ids : [],
    item: {}
};

export default function itemReducer (state = initialState, action) {
    switch (action.type) {
        case "ADD_ITEM":
            let new_item = {
                reference : action.reference
            }
            let id = state.lastId + 1
            let newItem = {
                ...state.item,
            }
            newItem['item'+id] = new_item
            let newIds = state.ids
            newIds.push(id)
            return {
                ...state,
                lastId: id,
                ids : newIds,
                item: newItem
            }
        case "REMOVE_ITEM":
            let remIds = state.ids
            let ind = remIds.indexOf(action.id)
            if(ind > -1) {
                remIds.splice(ind, 1)
            }
            return {
                ...state,
                ids : remIds
            }
        default:
            return state
    }
}
