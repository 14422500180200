import React from "react";
import {
    Navigate,
    useParams
} from "react-router-dom";
import {useState} from "react"
import Empty from "../Empty";
import {api_url, addFlash} from "../../Services/ConnexionService"
import {useDispatch} from "react-redux";
import {withUserAgent} from "react-useragent";

function LoginLongOTP (props) {
    let {token} = useParams()
    let [canGoHome, setCanGoHome] = useState(false)
    let [canGo404, setCanGo404] = useState(false)
    let [canGoExpired, setCanGoExpired] = useState(false)
    const dispatch = useDispatch()

    if (canGoHome) {
        return (
            <Navigate to={"/"}/>
        )
    }
    else if (canGoExpired) {
        addFlash(dispatch, 'error', 'Ce code a expiré, veuillez vous connecter.')
        return (
            <Navigate to={"/login"}/>
        )
    }
    else if (canGo404) {
        return (
            <Navigate to={"/error"}/>
        )
    }
    else {
        let data = {
            userAgent : props.ua.md.ua
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(api_url + '/client/login/otp/long/' + token, {method:'POST', body: jsonD, credentials:'include'})
        fetch(request).then((response) => {
            let json = {}
            try {
                json = response.json()
            } catch (e) {
                setCanGo404(true)
            }
            return json
        }).then((data) => {
            switch (data.type) {
                case 'OK':
                    setCanGoHome(true)
                    break
                case 'EXPIRED':
                    setCanGoExpired(true)
                    break
                default:
                    setCanGo404(true)
            }
        })

        return (
            <Empty/>
        )
    }
}

export default withUserAgent(LoginLongOTP);
