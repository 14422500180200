import React from "react";
import {api_url} from "../../../Services/ConnexionService";
import DisplayPrice from "../../Utils/DisplayPrice";

function Basketitem(props) {

    const displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            informations.push(
                <div style={{marginTop: "5px"}}>
                    {infos[i]}
                </div>
            )
        }
        return informations
    }

    return (
        <div style={{marginBottom: "20px", marginTop: "20px", display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>

            {props.img &&
                <div style={{flex: 1, display: "flex", alignItems: "center"}}>
                    <img src={api_url + "/uploads/article/" + props.img} style={{width: window.innerWidth > 1020 ? "100%" : "50%"}}/>
                </div>
            }

            <div style={{flex: 4, flexDirection: "column", marginLeft: props.img && window.innerWidth > 1020 ? "20px" : undefined, marginTop: window.innerWidth > 1020 ? undefined : "10px"}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{flex: 2}}>
                        <h3 style={{margin: "0"}}>
                            {props.title}
                        </h3>
                    </div>
                    <div style={{flex: 1, textAlign: "right"}}>
                        <DisplayPrice price={props.price}/>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "column"}}>
                    {displayInfos(props.infos)}
                    {props.unitPrice &&
                        <div style={{marginTop: "5px"}}>Prix unitaire : <DisplayPrice price={props.unitPrice}/></div>
                    }
                </div>
            </div>

        </div>
    );
}

export default Basketitem;