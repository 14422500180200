import React from "react"
import LoggedComponent, {api_url, mapStateToProps, shop_url} from "../../../Services/ConnexionService";
import {connect} from "react-redux";
import globalStyles from "../../../Styles/Style.module.css";
import FilAriane from "../../header_footer/FilAriane";
import CardTemplateBasket from "./CardTemplateBasket";
import Bandeau from "../../header_footer/Bandeau";
import formStyles from "../../../Styles/FormStyle.module.css";
import Empty from "../../Empty";
import moment from "moment";
import {faCartArrowDown, faCheckCircle, faTag, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import titleAriane from "../../../Helpers/TitleAriane.json";
import DisplayPrice from "../../Utils/DisplayPrice";
import styles from "../Payment/Payement.module.css";
import {encode} from "../../../Services/ObfuscatorService";
import AddItemTemplate from "./AddItem/AddItemTemplate";


class ResumeBasket extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            basket: {},
            repartition: {},
            boxes: {},
            promo: null,
            errorPromo: false,
            hasPromo: false,
        }
        this.boxesInfos = {};
        this.loading = true;
        this.basket = undefined;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    setBox = (oa, reference) => {
        this.state.boxes[oa] = reference;
        this.forceUpdate();
    }

    getBox = (oa) => {
        return this.state.boxes[oa];
    }

    setBoxInfos = (boxRef, infos) => {
        this.boxesInfos[boxRef] = infos;
    }

    getBoxInfos = (boxRef) => {
        return this.boxesInfos[boxRef];
    }


    setQuantity = (oa, user, quantity, all) => {
        if(!this.state.repartition[oa] || all){
            this.state.repartition[oa] = {};
        }
        this.state.repartition[oa][user] = parseInt(quantity);
        this.forceUpdate();
        let qty = 0;

        for(let c in this.state.repartition[oa]){
            qty += parseInt(this.state.repartition[oa][c]);
        }
        this.changeQuantity(oa, qty);
    }

    cleanQuantity = (oa) => {
        this.state.repartition[oa] = {};
        this.forceUpdate();
    }

    getQuantity = (oa) => {
        for(let i = 0; i < this.state.basket.articles.length; i++){
            if(oa === this.state.basket.articles[i].id){
                return parseInt(this.state.basket.articles[i].quantity);
            }
        }
    }

    changeQuantity = (oa, qty, user) => {
        for(let i = 0; i < this.state.basket.articles.length; i++){
            if(oa === this.state.basket.articles[i].id){
                this.state.basket.articles[i].quantity = qty;
                if(user) this.state.repartition[oa][user] = qty;
                this.forceUpdate();
            }
        }
    }

    calculateSubTotal() {
        let subtotal = 0;
        for(let i = 0; i < this.state.basket.articles.length; i++){
            subtotal += parseInt(this.state.basket.articles[i].article.priceTtc) * parseInt(this.state.basket.articles[i].quantity);
        }
        return subtotal/100;
    }

    calculateSubTotalWithPromo(){
        let subtotal = 0;
        for(let i = 0; i < this.state.basket.articles.length; i++){
            subtotal += parseInt(this.state.basket.articles[i].article.priceTtc) * parseInt(this.state.basket.articles[i].quantity);
        }
        subtotal -= this.state.basket.appliedPromo;
        return subtotal/100;
    }

    getBasket(){
        let request = new Request(api_url + '/client/basket', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({basket: data});
            this.loading = false;
            if(Object.keys(data).length > 0){
                for(let i = 0; i < data.articles.length; i++){
                    if(data.articles[i].user){
                        this.setQuantity(data.articles[i].id, data.articles[i].user, data.articles[i].quantity);
                    }
                    if(data.articles[i].firstBox) {
                        this.setBox(data.articles[i].id, data.articles[i].firstBox.article.reference);
                        this.setBoxInfos(data.articles[i].firstBox.article.reference, data.articles[i].firstBox.article.name)
                    }
                }
                if(this.state.basket.explanation) {
                    let exp = this.state.basket.explanation
                    let expSplit = exp.split('//')
                    for (let i in expSplit) {
                        if (expSplit[i].startsWith('PROMOCODE=')) {
                            this.setState({promo : expSplit[i].split('=')[1], hasPromo: true})
                        }
                    }
                }
            }
            this.basket = (Object.keys(data).length > 0);
            this.forceUpdate();
        })
    }

    formValid(){
        let validate = true;
        validate = validate && (this.state.basket.articles.length > 0);
        for(let i = 0; i < this.state.basket.articles.length; i++){
            validate = validate && (this.state.basket.articles[i].quantity > 0);
            if(this.state.basket.articles[i].article.needChooseBox) {
                validate = validate &&  this.state.boxes[this.state.basket.articles[i].id] && this.state.boxes[this.state.basket.articles[i].id] !== ''
            }
            if(this.state.repartition[this.state.basket.articles[i].id]){
                validate = validate && (Object.keys(this.state.repartition[this.state.basket.articles[i].id]).length > 0);
            } else {
                if(this.state.basket.articles[i].article.type !== "other"){
                    validate = false;
                }
            }
        }
        return validate;
    }

    getUsers(){
        let users = [];
        users.push({
            id: this.state.user.id,
            fullName: this.state.user.firstName + " " + this.state.user.lastName,
            firstName: this.state.user.firstName,
            birthDate: "Né le " + moment(this.state.user.birthDate.date).format("DD/MM/YYYY")
        })

        for(let i = 0; i < this.state.children.length; i++){
            users.push({
                id: this.state.children[i].user.id,
                fullName: this.state.children[i].user.firstName + " " + this.state.children[i].user.lastName,
                firstName: this.state.children[i].user.firstName,
                birthDate: "Né le " + moment(this.state.children[i].user.birthDate.date).format("DD/MM/YYYY")
            })
        }

        return users
    }

    displayBasket(){
        let basket = [];

        for(let i = 0; i < this.state.basket.articles.length; i++){
            let needChooseBox = false;
            let canChangeQuantity = true;
            let article = this.state.basket.articles[i].article;

            if(article.needChooseBox) {
                needChooseBox = article.needChooseBox
            }

            let infos = [];
            if(article.duration && article.type === "commitment"){
                canChangeQuantity = false
                infos.push("Durée : " + article.duration + " mois")
            }
            infos.push(
                article.description
            )

            basket.push(
                <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
                    <CardTemplateBasket
                        title={article.name}
                        price={article.priceTtc/100}
                        unitPrice={article.unitPriceTtc ? article.unitPriceTtc/100 : undefined}
                        quantity={this.state.basket.articles[i].quantity}
                        stock={article.stock}
                        isComm={article.isCommitment}
                        infos={infos}
                        for={article.user}
                        users={this.getUsers()}
                        icon={faCartArrowDown}
                        fetchJsonOrError={this.fetchJsonOrError.bind(this)}
                        oaid={this.state.basket.articles[i].id}
                        userId={this.state.basket.articles[i].user}
                        img={this.state.basket.articles[i].article.media.length > 0 ? this.state.basket.articles[i].article.media[0] : undefined}
                        setQuantity={this.setQuantity.bind(this)}
                        cleanQuantity={this.cleanQuantity.bind(this)}
                        changeQuantity={this.changeQuantity.bind(this)}
                        getQuantity={this.getQuantity.bind(this)}
                        canChangeQuantity={canChangeQuantity}
                        needChooseBox={needChooseBox}
                        setBox={this.setBox.bind(this)}
                        getBox={this.getBox.bind(this)}
                        setBoxInfos={this.setBoxInfos.bind(this)}
                        getBoxInfos={this.getBoxInfos.bind(this)}
                        type={article.type}
                        dependencies={this.state.basket.articles[i].dependencies}
                        fromDependency={this.state.basket.articles[i].fromDependency}
                        dispatch={this.props.dispatch.bind(this)}
                        orderId={this.state.basket.id}
                        childOAid={this.state.basket.articles[i].firstBox ? this.state.basket.articles[i].firstBox.id : null}
                    />
                </div>
            )
        }

        return basket;
    }

    validate = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({repartition: this.state.repartition, basket: this.state.basket, boxes: this.state.boxes}));
        let request = new Request(api_url + '/client/basket/update/' + this.state.basket.id, {method:'POST', body: data, credentials:'include'});
        this.fetchJsonOrError(request, (data) => {
            if(data["updated"]){
                this.setState({shouldNavigate: true, navigateTo: '/delivery/' + encode(this.state.basket.id)});
            }
        })
    }

    getPromo(){
        let request = new Request(api_url + '/client/basket', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({basket: { ...this.state.basket, explanation : data.explanation, appliedPromo : data.appliedPromo}});
            if(Object.keys(data).length > 0){
                if(this.state.basket.explanation) {
                    let exp = this.state.basket.explanation
                    let expSplit = exp.split('//')
                    for (let i in expSplit) {
                        if (expSplit[i].startsWith('PROMOCODE=')) {
                            this.setState({promo : expSplit[i].split('=')[1], hasPromo: true})
                        }
                    }
                } else {
                    this.setState({promo: null, hasPromo: false});
                }
            }
            this.forceUpdate();
        })
    }

    applyPromo = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({promo: this.state.promo}));
        let request = new Request(api_url + '/client/basket/' + this.state.basket.id + '/promo', {method:'POST', body: data, credentials:'include'});
        this.fetchJsonOrError(request, (data) => {
            if(data["applied"]){
                this.getPromo();
            } else {
                this.setState({errorPromo: true})
            }
        })
    }

    denyPromo = () => {
        let request = new Request(api_url + '/client/basket/' + this.state.basket.id + '/promo/delete', {method:'POST', credentials:'include'});
        this.fetchJsonOrError(request, (data) => {
            this.getPromo()
        })
    }

    renderChild () {
        if(this.loading){
            this.getBasket()
            return (
                <div style={{height: "1000000px", backgroundColor:"#FFF"}}/>
            )
        }

        return (
            <div>
                <Bandeau title={titleAriane["basket"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["basket"]["ariane"][0],
                            "/basket"
                        ]
                    ]}
                />

                <div className={globalStyles.globalContainer} style={{flexDirection: "column", alignItems: "unset", padding: window.innerWidth > 960 ? "unset" : "10px"}}>

                    {this.basket && this.state.basket.articles.length > 0 ?
                        <div>
                            {this.displayBasket()}

                            <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column", marginBottom: "20px"}}>
                                <div
                                    className={globalStyles.card}
                                    style={{
                                        padding: "20px",
                                        flex: 1,
                                        backgroundColor: "#f3f6f8",
                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                        borderRadius: "5px",
                                        display: "flex",
                                        boxSizing: "border-box",
                                        flexDirection: "column",
                                        marginRight: window.innerWidth > 1020 ? "10px" : undefined,
                                        marginBottom: "10px",
                                        marginTop: "10px"
                                    }}
                                >
                                    {this.state.hasPromo ?
                                        <div>
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <div style={{flex: 1}}>
                                                    <h3 style={{textAlign: "left", color: "#6FA939", letterSpacing: "1px"}}>
                                                        <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: "10px", fontSize: "1.5em", position: "relative", bottom: "-3px"}}/>
                                                        {this.state.promo}
                                                    </h3>
                                                </div>
                                                <div style={{flex: 1}}>
                                                    <h2 style={{textAlign: "right"}}>
                                                        -<DisplayPrice price = {this.state.basket.appliedPromo / 100}/>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                                                <button className={globalStyles.defaultLink} onClick={this.denyPromo}>
                                                    Supprimer
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className={formStyles.inputLine} style={{marginBottom: "10px"}}>
                                            <div className={formStyles.inputGroup} style={{marginLeft: 0, marginRight: window.innerWidth > 1020 ? undefined : 0}}>
                                                <div className={formStyles.inputField} style={{borderColor: this.state.errorPromo ? "#CE3534" : "#ced4da"}}>
                                                    <div className={formStyles.inputIcon} style={{flex: 2}}>
                                                        <FontAwesomeIcon icon={faTag}/>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        required
                                                        className={formStyles.input}
                                                        placeholder="Code promo"
                                                        value={this.state.promo}
                                                        onChange={(event) => this.setState({...this.state, promo: event.target.value.toUpperCase(), errorPromo: false})}
                                                    />
                                                </div>
                                            </div>


                                            <div style={{
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: window.innerWidth > 1020 ? "flex-end" : "center",
                                                paddingBottom: "10px"
                                            }}>
                                                <button
                                                    className={globalStyles.defaultBtn}
                                                    style={{fontSize: "1rem"}}
                                                    onClick={this.applyPromo}
                                                >
                                                    Appliquer
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div
                                    className={globalStyles.card}
                                    style={{
                                        padding: "20px 30px",
                                        flex: 1,
                                        backgroundColor: "#f3f6f8",
                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                        borderRadius: "5px",
                                        boxSizing: "border-box",
                                        flexDirection: "row",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: window.innerWidth > 1020 ? "flex-end" : "center",
                                        marginLeft: window.innerWidth > 1020 ? "10px" : undefined,
                                        marginBottom: "10px",
                                        marginTop: "10px"
                                    }}
                                >
                                    {this.state.hasPromo ?
                                        <h2 style={{textAlign: "right"}}>
                                            Sous-total : <DisplayPrice price={this.calculateSubTotal()} strike={true}/> <DisplayPrice price={this.calculateSubTotalWithPromo()} color={"#6FA939"}/>
                                        </h2>
                                        :
                                        <h2 style={{textAlign: "right"}}>
                                            Sous-total : <DisplayPrice price = {this.calculateSubTotal()}/>
                                        </h2>
                                    }
                                </div>
                            </div>


                            <div className={formStyles.btnContainer}>
                                <button className={this.formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn}
                                        onClick={() => this.validate()} disabled={!this.formValid()}>
                                    Continuer
                                </button>
                            </div>
                        </div>
                        :

                        <div>
                            <div
                                className={globalStyles.card}
                                style={{
                                    padding: "30px",
                                    flex: 1,
                                    backgroundColor: "#f3f6f8",
                                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                    borderRadius: "5px",
                                    display: "flex",
                                    height: "100%",
                                    boxSizing: "border-box",
                                    flexDirection: "row",
                                    marginBottom: "20px",
                                    marginTop: "10px"
                                }}
                            >
                                Votre panier est vide.
                            </div>

                            <div className={formStyles.btnContainer}>
                                <a
                                    className={globalStyles.defaultBtn}
                                    href={shop_url}
                                >
                                    Ajouter des articles
                                </a>
                            </div>

                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ResumeBasket);
