import React from "react"
import LoggedComponent, {api_url, help_url, mapStateToProps} from "../../Services/ConnexionService";
import {connect} from "react-redux";
import globalStyles from "../../Styles/Style.module.css";
import FilAriane from "../header_footer/FilAriane";
import {
    faShoppingBasket
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Bandeau from "../header_footer/Bandeau";
import Lottie from "react-lottie";
import check from "../../assets/lottie/Atorika-check.json";
import cross from "../../assets/lottie/Atorika-cross.json";
import loading from "../../assets/lottie/loading.json";
import styles from "./Payment/Payement.module.css";
import Basketitem from "./Payment/Basketitem";
import img from "../../assets/fixtures/objectif-mars.png";
import basket from "./Basket/TestBasket/Basket";
import titleAriane from "../../Helpers/TitleAriane.json";
import {encode} from "../../Services/ObfuscatorService";


class ResumeBasket extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            isPaid: false,
            basket: undefined
        }
        this.loading = true;
        this.notPaidCount = 0;
    }

    doesMustLog = () => {
        this.mustLog = 1;
        this.state = {
            isPaid: false,
            basket: undefined
        }
    }

    getBasket(){
        if (this.loading) {
            let request = new Request(api_url + '/client/order/' + this.props.id, {method:'GET', credentials:'include'});
            fetch(request).then((response) => {
                return response.json()
            }).then((data) => {
                this.setState({basket: data});
                this.loading = false;
                this.forceUpdate();
            })
        }
    }

    checkIsPaid(){
        if(this.notPaidCount >= 30) {
            clearInterval(this.interval);
            this.forceUpdate();
        }
        else {
            let request = new Request(api_url + '/client/order/ispaid/' + this.props.id, {method:'GET', credentials:'include'});
            fetch(request).then((response) => {
                return response.json()
            }).then((data) => {
                this.setState({isPaid: data.ispaid});
                if(data.ispaid){
                    clearInterval(this.interval);
                    if(!this.state.user.isEmailValid) {
                        let request = new Request(api_url + '/client/logout', {credentials: 'include'})
                        fetch(request).then((response) => {})
                    }
                }
                this.forceUpdate();
            })
            this.notPaidCount += 1
            this.forceUpdate();
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.checkIsPaid(), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    renderChild () {
        if(this.state.isPaid && !this.loading){
            return (
                <div style={{marginTop: "20px"}}>
                    <Bandeau title={titleAriane["finishShopping"]["title"]}/>
                    <FilAriane
                        links={[
                            [
                                titleAriane["finishShopping"]["ariane"][0],
                                ""
                            ],
                        ]}
                    />
                    <div className={globalStyles.globalContainer} style={{padding: window.innerWidth > 960 ? "unset" : "10px"}}>
                        <div style={{flex: 1}}>

                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: "30px", marginBottom:"10px"}}>
                                <span style={{marginRight: "30px"}}>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: check,
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}
                                        height={150}
                                        width={150}
                                    />
                                </span>
                                <h2>Merci pour votre confiance !</h2>
                            </div>

                            Votre commande est enregistrée sous le numéro <b>{this.state.basket.identifier}</b>. <br/>
                            Un mail de confirmation vous a été envoyé.

                            <br/><br/>

                            Retrouvez votre commande, le suivi des livraisons et votre facture dans l'espace <a className={globalStyles.defaultLink} href={"/commands"}>Vos commandes</a>.

                            <h2>Un souci, une question ?</h2>

                            Nous vous invitons à vous rendre dans notre <a href={help_url} className={globalStyles.defaultLink}>centre d'assistance</a> pour parcourir tous nos articles et poser vos questions.

                        </div>

                    </div>
                </div>
            );
        }
        else if (this.notPaidCount >= 30 && !this.loading) {
            return (
                <div style={{marginTop: "20px"}}>
                    <Bandeau title={titleAriane["finishShopping"]["title"]}/>
                    <FilAriane
                        links={[
                            [
                                titleAriane["finishShopping"]["ariane"][0],
                                ""
                            ],
                        ]}
                    />
                    <div className={globalStyles.globalContainer}>
                        <div style={{flex: 1}}>

                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: "30px", marginBottom:"10px"}}>
                                <span style={{marginRight: "30px"}}>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: cross,
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}
                                        height={150}
                                        width={150}
                                    />
                                </span>
                                <h2>Oups...</h2>
                            </div>

                            Votre commande est enregistrée sous le numéro <b>{this.state.basket.identifier}</b>. <br/>
                            Nous avons cependant rencontré un problème avec votre paiement, qui n'est pas validé. <br/>
                            Vous pourrez retenter le paiement en accédant à l'espace <a className={globalStyles.defaultLink} href={"/commands"}>Vos commandes</a>

                            <br/>

                            <h2>Un souci, une question ?</h2>

                            Nous vous invitons à vous rendre dans notre <a href={help_url} className={globalStyles.defaultLink}>centre d'assistance</a> pour parcourir tous nos articles et poser vos questions.

                        </div>

                    </div>
                </div>
            )
        }
        else if (this.notPaidCount >= 30) {
            return (
                <div style={{marginTop: "20px"}}>
                    <Bandeau title={titleAriane["finishShopping"]["title"]}/>
                    <FilAriane
                        links={[
                            [
                                titleAriane["finishShopping"]["ariane"][0],
                                ""
                            ],
                        ]}
                    />
                    <div className={globalStyles.globalContainer}>
                        <div style={{flex: 1}}>

                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: "30px", marginBottom:"10px"}}>
                                <span style={{marginRight: "30px"}}>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: cross,
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}
                                        height={150}
                                        width={150}
                                    />
                                </span>
                                <h2>Merci pour votre confiance !</h2>
                            </div>

                            Nous avons rencontré un problème avec votre paiement, qui n'est pas validé.
                            Vous pourrez retenter le paiement en accédant à l'espace <a className={globalStyles.defaultLink} href={"/commands"}>Vos commandes</a>

                            <br/>

                            <h2>Un souci, une question ?</h2>

                            Nous vous invitons à vous rendre dans notre <a href={help_url} className={globalStyles.defaultLink}>centre d'assistance</a> pour parcourir tous nos articles et poser vos questions.

                        </div>

                    </div>
                </div>
            )
        }
        else {
            this.getBasket()
            return(
                <div style={{marginTop: "20px"}}>
                    <Bandeau title={"Patientez quelques instants ..."}/>
                    <FilAriane
                        links={[
                            [
                                titleAriane["finishShopping"]["ariane"][0],
                                ""
                            ],
                        ]}
                    />
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: loading,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        height={400}
                        width={400}
                    />
                </div>
            )
        }

    }
}

export default connect(mapStateToProps)(ResumeBasket);
