import React from "react"
import LoggedComponent, {api_url, mapStateToProps} from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import FilAriane from "../header_footer/FilAriane";
import {connect} from "react-redux";
import Bandeau from "../header_footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";
import CardStats from "./CardStats";

class Stats extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            stats : undefined
        }
        this.loading = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getStats(){
        let request = new Request(api_url + '/client/stats', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({stats: data});
            this.loading = false;
            this.forceUpdate();
        })
    }

    displayStats(){
        let stats = [];
        for(let i = 0; i < this.state.stats.length; i++){
            stats.push(<h2 id={"user" + this.state.stats[i].id} style={{alignSelf: "flex-start"}}>{this.state.stats[i].user}</h2>);
            stats.push(
                <CardStats
                    xp={this.state.stats[i].xp}
                    nbBadges={this.state.stats[i].nbBadges}
                    activity={this.state.stats[i].activity}
                    quizz={this.state.stats[i].quiz}
                    badges={this.state.stats[i].badges}
                    badgesToWin={this.state.stats[i].badgesToWin}
                    pictures={this.state.stats[i].pictures}
                    id={this.state.stats[i].id}
                />
            )
            stats.push(<br/>)
        }
        return stats;
    }


    renderChild () {

        if(this.state.stats){
            return (
                <div>
                    <Bandeau title={titleAriane["stats"]["title"]}/>

                    <FilAriane
                        links={[
                            [
                                titleAriane["stats"]["ariane"][0],
                                "/"
                            ],
                            [
                                titleAriane["stats"]["ariane"][1],
                                ""
                            ]
                        ]}
                    />

                    <div className={globalStyles.globalContainer} style={{flexDirection: "column", padding: window.innerWidth > 960 ? "unset" : "10px"}}>
                        {this.displayStats()}
                    </div>
                </div>
            );
        } else {
            this.getStats()
            return (
                <div/>
            )
        }
    }
}

export default connect(mapStateToProps)(Stats);