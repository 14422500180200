import React, {useState} from "react"
import LoggedComponent, {api_url, mapStateToProps} from "../../../Services/ConnexionService";
import globalStyles from '../../../Styles/Style.module.css';
import FilAriane from "../../header_footer/FilAriane";
import {connect} from "react-redux";
import Bandeau from "../../header_footer/Bandeau";
import titleAriane from "../../../Helpers/TitleAriane.json";
import CardBox from "../../Box/CardBox";
import moment from "moment";
import {encode} from "../../../Services/ObfuscatorService";
import {faCity, faGlobeEurope, faMailBulk, faTimes, faTruck} from "@fortawesome/free-solid-svg-icons";
import CardTemplate from "../../Card/CardTemplate";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "../../Shopping/Delivery/Delivery.module.css";
import ListAddress from "../../Utils/Address/ListAddress";
import formStyles from "../../../Styles/FormStyle.module.css";
import ListPoints from "../../Shopping/Delivery/ListPoints";
import MapPoints from "../../Shopping/Delivery/MapPoints";
import AddAddressDelivery from "../../Shopping/Delivery/AddAdressDelivery";
import carriers from "../../../Helpers/carriers.json";
import CardBoxCommitment from "./CardBoxCommitment";

class UserCommitment extends LoggedComponent {

    constructor(props) {
        super(props);
        this.toSend = {
            articles: {},
            delivery: {},
        }
        this.state = {
            order: {},
            postalCode: null,
            city: null,
            country: null,
            address: {},
            listpoints: [],
            selectedPoint: undefined,
            data : undefined,
            countries: undefined
        }
        this.loading = true;
        this.loadingCountries = true;
    }


    doesMustLog = () => {
        this.mustLog = 1
    }

    getCountries() {
        let request = new Request(api_url + '/client/countries', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({countries: data.countries})
            this.loadingCountries = false;
            this.forceUpdate();
        })
    }

    submitAndRefresh() {
        if(Object.keys(this.toSend.delivery).length > 0 || Object.keys(this.toSend.articles).length > 0){
            let data = new FormData();
            data.append('json', JSON.stringify(this.toSend))
            let request = new Request(api_url + '/client/commitment/choose/' + this.state.data.commitment.toChooseId, {method:'POST', body: data, credentials:'include'});
            this.fetchJsonOrError(request, (data) => {
                if(data.success) {
                    this.addFlash("success", "Votre choix a bien été pris en compte");
                    this.hasdisplayedFlash = false;
                    this.toSend = {
                        delivery: {},
                        articles: {}
                    }
                    this.hydrateDisplayFlash();
                    this.getInfos();
                    this.forceUpdate();
                }
            })
        }
    }

    getListPoints() {
        if(this.state.data.commitment.delivery.relay){
            if(Object.keys(this.state.data.commitment.delivery.relay.carriers).length > 0 && this.state.city && this.state.postalCode && this.state.country){
                let data = new FormData();
                data.append('json', JSON.stringify({carriers: this.state.data.commitment.delivery.relay.carriers}));
                let request = new Request(api_url + '/client/delivery/listpoints/' + this.state.city + "/" + this.state.postalCode + '/' + this.state.country, {method:'POST', body: data, credentials:'include'});
                fetch(request).then((response) => {
                    return response.json()
                }).then((data) => {
                    let points = []
                    if(data.carrier instanceof Array){
                        var coordsRef = [0, 0];
                        let count = 0;
                        for(let carrier in data.carrier) {
                            let carrier_code = data.carrier[carrier].operator;
                            if(data.carrier[carrier].points.point) {
                                coordsRef[0] += parseFloat(data.carrier[carrier].points.point[0].latitude);
                                coordsRef[1] += parseFloat(data.carrier[carrier].points.point[0].longitude);
                                count++;
                            }
                        }

                        coordsRef[0] /= count;
                        coordsRef[1] /= count;

                        for(let carrier in data.carrier) {
                            let carrier_code = data.carrier[carrier].operator;
                            if(data.carrier[carrier].points.point){
                                for (let i = 0; i < data.carrier[carrier].points.point.length; i++) {
                                    points.push(
                                        {
                                            name: data.carrier[carrier].points.point[i].name,
                                            carrier: carriers[carrier_code].img,
                                            address: data.carrier[carrier].points.point[i].address,
                                            zipcode: data.carrier[carrier].points.point[i].zipcode,
                                            city: data.carrier[carrier].points.point[i].city,
                                            country: data.carrier[carrier].points.point[i].country,
                                            schedule: data.carrier[carrier].points.point[i].schedule,
                                            code: data.carrier[carrier].points.point[i].code,
                                            latitude: data.carrier[carrier].points.point[i].latitude,
                                            longitude: data.carrier[carrier].points.point[i].longitude,
                                            selected: false
                                        }
                                    )
                                }
                            }
                        }
                    } else {
                        let carrier_code = data.carrier.operator;
                        var coordsRef = [data.carrier.points.point[0].latitude, data.carrier.points.point[0].longitude];
                        for (let i = 0; i < data.carrier.points.point.length; i++) {
                            points.push(
                                {
                                    name: data.carrier.points.point[i].name,
                                    carrier: carriers[carrier_code].img,
                                    address: data.carrier.points.point[i].address,
                                    zipcode: data.carrier.points.point[i].zipcode,
                                    city: data.carrier.points.point[i].city,
                                    country: data.carrier.points.point[i].country,
                                    schedule: data.carrier.points.point[i].schedule,
                                    code: data.carrier.points.point[i].code,
                                    latitude: data.carrier.points.point[i].latitude,
                                    longitude: data.carrier.points.point[i].longitude,
                                    selected: false
                                }
                            )
                        }
                    }
                    points.sort(function (a, b) {
                        let distA = (a.latitude - coordsRef[0])**2 + (a.longitude - coordsRef[1])**2;
                        let distB = (b.latitude - coordsRef[0])**2 + (b.longitude - coordsRef[1])**2;
                        return distA-distB;
                    })
                    this.setState({listpoints: points});
                    this.loading = false;
                    this.reloadRelay = false;
                    this.forceUpdate();
                })
            } else {
                this.loading = false;
                this.reloadRelay = false;
                this.forceUpdate();
            }
        }

    }

    setAddress = (id, firstName, lastName, streetAddress, streetAddressMore, postalCode, city, country, phone) => {
        let addressCall = {
            id: id,
            firstName: firstName,
            lastName: lastName,
            streetAddress: streetAddress,
            streetAddressMore: streetAddressMore,
            postalCode: postalCode,
            city: city,
            country: country,
            phone: phone
        }
        this.setState({
            address : addressCall,
        })
        this.toSend.delivery = addressCall;
    }

    setRelay = (newListpoint) => {
        let addressRelay = {
            relayName: newListpoint.name,
            streetAddress: newListpoint.address,
            postalCode: newListpoint.zipcode,
            city: newListpoint.city,
            country: newListpoint.country,
            relayPoint: newListpoint.code
        }
        this.setState({
            address : addressRelay,
        })
        this.toSend.delivery = addressRelay;
        this.forceUpdate();
    }

    selected = (code) => {
        let newListpoint = this.state.listpoints;
        let newSelectedPoint = {};
        for (let i in newListpoint) {
            newListpoint[i].selected = (newListpoint[i].code === code);
            if(newListpoint[i].code === code){
                newSelectedPoint = newListpoint[i]
            }
        }
        this.setState({
            listpoints: newListpoint,
            selectedPoint: newSelectedPoint
        });
        this.setRelay(newSelectedPoint);
    }

    openDelivery() {
        this.isOpenDelivery = true;
        this.forceUpdate()
    }

    closeDelivery = () => {
        this.isOpenDelivery = false;
        this.forceUpdate();
        this.submitAndRefresh()
    }

    openModalAdd() {
        this.isOpenModalAdd = true;
        this.forceUpdate()
    }

    closeModalAdd() {
        this.isOpenModalAdd = false;
        this.forceUpdate()
    }

    setBox(oaid, reference){
        this.toSend.articles[oaid] = reference

        this.submitAndRefresh()
    }

    setBoxInfos(ref, name){}

    getInfos(){
        let request = new Request(api_url + '/client/user/commitment/' + this.props.id + '/box', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({data: data, country: data.country});
            this.loading = false;
            this.forceUpdate();
        })
    }

    displayBox = (nbBox) => {
        let lenBox = 0;
        for(let i = 0; i < this.state.data.boxes.length; i++){
            if(new Date(this.state.data.boxes[i].date.date) <  new Date()){
                lenBox++;
            }
        }

        let boxes = []

        if(lenBox > 0){
            boxes.push(<h2 style={{alignSelf: "flex-start", marginBottom: "7px"}}>Box reçues ou en cours de livraison</h2>)
            boxes.push(<div className={globalStyles.break}/>)
        }

        let missing = (lenBox % nbBox);
        let currentLine = 0;
        let counter = 0;
        for (let i = 0; i < this.state.data.boxes.length; i++) {
            if(new Date(this.state.data.boxes[i].date.date) <  new Date()){
                if (counter >= nbBox*currentLine + nbBox){
                    boxes.push(<div className={globalStyles.break}/>)
                    currentLine++;
                }

                boxes.push(
                    <div className={globalStyles.childCard} style={{
                        marginLeft: counter%nbBox === 0 ? "0" : "10px",
                        marginRight: counter%nbBox === nbBox - 1 ? "0" : "10px",
                    }}>
                        <CardBox
                            media={this.state.data.boxes[i].media[0]}
                            title={this.state.data.boxes[i].name}
                            theme={this.state.data.boxes[i].theme}
                            secondaryTheme={this.state.data.boxes[i].secondaryTheme}
                            themeColor={this.state.data.boxes[i].themeColor}
                            themeBaseColor={this.state.data.boxes[i].themeBaseColor}
                            secondaryThemeColor={this.state.data.boxes[i].secondaryThemeColor}
                            secondaryThemeBaseColor={this.state.data.boxes[i].secondaryThemeBaseColor}
                            date={this.state.data.boxes[i].date.date}
                            order={this.state.data.boxes[i].order}
                            infos={[
                                this.state.data.boxes[i].description
                            ]}
                        />
                    </div>
                )

                counter++;
            }
        }

        for(let i = 0; i < missing; i++){
            boxes.push(
                <div style={{flex: 1, display: "flex",  flexDirection: "row", margin: "10px"}}>
                </div>
            )
        }
        return boxes
    }

    displayNextBox = (nbBox) => {
        let lenBox = 0;
        for(let i = 0; i < this.state.data.boxes.length; i++){
            if(new Date(this.state.data.boxes[i].date.date) >=  new Date()){
                lenBox ++;
            }
        }

        let boxes = []

        if(lenBox > 0){
            boxes.push(<h2 style={{alignSelf: "flex-start", marginBottom: "7px"}}>Prochaine box</h2>)
            boxes.push(<div className={globalStyles.break}/>)
        }

        let missing = (lenBox % nbBox);
        let currentLine = 0;
        let counter = 0;
        for (let i = 0; i < this.state.data.boxes.length; i++) {
            if(new Date(this.state.data.boxes[i].date.date) >=  new Date()){
                if (counter >= nbBox*currentLine + nbBox){
                    boxes.push(<div className={globalStyles.break}/>)
                    currentLine++;
                }

                boxes.push(
                    <div className={globalStyles.childCard} style={{
                        marginLeft: counter%nbBox === 0 ? "0" : "10px",
                        marginRight: counter%nbBox === nbBox - 1 ? "0" : "10px",
                    }}>
                        <CardBoxCommitment
                            media={this.state.data.boxes[i].media[0]}
                            title={this.state.data.boxes[i].name}
                            theme={this.state.data.boxes[i].theme}
                            secondaryTheme={this.state.data.boxes[i].secondaryTheme}
                            themeColor={this.state.data.boxes[i].themeColor}
                            themeBaseColor={this.state.data.boxes[i].themeBaseColor}
                            secondaryThemeColor={this.state.data.boxes[i].secondaryThemeColor}
                            secondaryThemeBaseColor={this.state.data.boxes[i].secondaryThemeBaseColor}
                            date={this.state.data.boxes[i].date.date}
                            order={this.state.data.boxes[i].order}
                            infos={[
                                this.state.data.boxes[i].description
                            ]}
                            oaid={this.state.data.boxes[i].oaid}
                            setBox={this.setBox.bind(this)}
                            setBoxInfos={this.setBoxInfos.bind(this)}
                            dispatch={this.props.dispatch}
                        />
                    </div>
                )

                counter++;
            }
        }

        for(let i = 0; i < missing; i++){
            boxes.push(
                <div style={{flex: 1, display: "flex",  flexDirection: "row", margin: "10px"}}>
                </div>
            )
        }
        return boxes
    }

    renderChild () {
        if(this.loadingCountries) this.getCountries()


        if(this.state.data){
            return (
                <div>
                    <Bandeau title={titleAriane["commitmentBox"]["title"].replace("_name_", this.state.data.user)}/>

                    <FilAriane
                        links={[
                            [
                                titleAriane["commitmentBox"]["ariane"][0],
                                "/"
                            ],
                            [
                                titleAriane["commitmentBox"]["ariane"][1],
                                "/commitment"
                            ],
                            [
                                titleAriane["commitmentBox"]["ariane"][2],
                                ""
                            ]
                        ]}
                    />

                    <div className={globalStyles.globalContainer} style={{flexDirection: "column", padding: window.innerWidth > 960 ? "unset" : "10px"}}>

                        <div className={globalStyles.childCard} style={{width: "100%", gap: "20px", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                            <div
                                className={globalStyles.card}
                                style={{
                                    padding: "30px",
                                    flex: 2,
                                    backgroundColor: "#f3f6f8",
                                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                    borderRadius: "5px",
                                    display: "flex",
                                    boxSizing: "border-box",
                                    flexDirection: "column",
                                }}
                            >
                                <div style={{flexDirection: window.innerWidth > 1020 ? "row" : "column", display: "flex"}}>
                                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <img
                                            src={api_url + '/uploads/article/' + this.state.data.commitment.media[0]}
                                            style={{maxWidth: "100%", maxHeight: "100%", borderRadius: "8px"}}
                                        />
                                    </div>
                                    <div style={{flex: 4, marginLeft: "10px"}}>
                                        <h3 style={{margin: "0 0 15px 0"}}>
                                            {this.state.data.commitment.name}
                                        </h3>

                                        <span className={globalStyles.badge} style={{backgroundColor: this.state.data.commitment.end.date < new Date() ? "#6FA939" : "#F4BC00"}}>
                                            {this.state.data.commitment.end.date < new Date() ? "Terminé" : "En cours"}
                                        </span>

                                        <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                                            <div style={{margin: "5px auto"}}>
                                                {moment(this.state.data.commitment.begin.date).format("DD/MM/YYYY")} - {moment(this.state.data.commitment.end.date).format("DD/MM/YYYY")}
                                            </div>
                                            <div style={{margin: "5px auto"}}>{this.state.data.commitment.description}</div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end"
                                    }}>
                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                                        <a className={globalStyles.defaultLink} href={"/command/" + encode(this.state.data.order) + "/view"}>
                                            Voir la commande associée
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div style={{flex: 1}}>
                                <CardTemplate
                                    color={"white"}
                                    icon={faTruck}
                                    title={"Livraison"}
                                    infos={[
                                        this.state.data.commitment.delivery.typeName,
                                        this.state.data.commitment.delivery.firstName + " " + this.state.data.commitment.delivery.lastName,
                                        this.state.data.commitment.delivery.relayName,
                                        this.state.data.commitment.delivery.address,
                                        this.state.data.commitment.delivery.addressMore,
                                        this.state.data.commitment.delivery.postCode && this.state.data.commitment.delivery.city ? this.state.data.commitment.delivery.postCode + " " + this.state.data.commitment.delivery.city : undefined,
                                        this.state.data.commitment.delivery.country
                                    ]}
                                    links={this.state.data.commitment.toChoose && this.state.data.commitment.delivery.type > 0 ? [["Changer", "btn", this.openDelivery.bind(this)]] : []}
                                />
                            </div>
                        </div>




                        <div className={globalStyles.cardContainer} style={{flexWrap: "wrap", margin: "auto -10px"}}>
                            {this.displayNextBox(window.innerWidth > 1020 ? 2 : 1)}
                        </div>

                        <div className={globalStyles.cardContainer} style={{flexWrap: "wrap", margin: "auto -10px"}}>
                            {this.displayBox(window.innerWidth > 1020 ? 2 : 1)}
                        </div>

                    </div>

                    <Modal
                        isOpen={this.isOpenDelivery}
                        contentLabel="Adresse de livraison"
                        bodyOpenClassName={"noScroll"}
                        style={{
                            content : {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 500,
                                width:  window.innerWidth > 1020 ? "70%" : "calc(100% - 60px)",
                                padding: window.innerWidth > 1020 ? "40px" : "20px",
                                paddingTop: "20px",
                                maxHeight: window.innerWidth > 960 ? (window.innerHeight - 180) + "px" : (window.innerHeight - 80) + "px",
                                borderRadius: "15px"
                            },
                            overlay : {
                                zIndex: 1500,
                                backgroundColor: "rgba(0, 0, 0, 0.8)"
                            }
                        }}
                    >
                        <button
                            style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                background: "none",
                                border: "none",
                                fontSize: "20px",
                                cursor: "pointer"
                            }}
                            onClick={() => {this.closeDelivery()}}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>

                        {this.state.data.commitment.delivery.type === 2 && // HOME
                            <div>
                                <div className={styles.cardContainer}>
                                    <div
                                        className={globalStyles.card}
                                        style={{
                                            padding: "20px 30px 30px",
                                            flex: 1,
                                            backgroundColor: "#f3f6f8",
                                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                            borderRadius: "5px",
                                            display: "flex",
                                            height: "100%",
                                            boxSizing: "border-box",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <ListAddress
                                            title={"Vos adresses"}
                                            desktopNumber={3}
                                            mobileNumber={1}
                                            type={0}
                                            state={this.state}
                                            fetchJsonOrError={this.fetchJsonOrError}
                                            add={["btn", this.openModalAdd.bind(this)]}
                                            choose={true}
                                            chooseFtn={this.setAddress.bind(this)}
                                        />
                                    </div>
                                </div>

                            </div>
                        }

                        {this.state.data.commitment.delivery.type === 1 && // RELAIS
                            <div className={styles.cardContainer}>
                                <div
                                    className={globalStyles.card}
                                    style={{
                                        padding: "30px",
                                        flex: 1,
                                        backgroundColor: "#f3f6f8",
                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                        borderRadius: "5px",
                                        display: "flex",
                                        height: "100%",
                                        boxSizing: "border-box",
                                        flexDirection: "column"
                                    }}
                                >
                                    <div style={{flex: 5}}>

                                        <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>

                                            <div className={formStyles.inputLine} style={{marginBottom: "10px"}}>
                                                <div className={formStyles.inputGroup} style={{marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined}}>
                                                    <label>
                                                        Code postal*
                                                    </label>
                                                    <div className={formStyles.inputField}>
                                                        <div className={formStyles.inputIcon}>
                                                            <FontAwesomeIcon icon={faMailBulk}/>
                                                        </div>
                                                        <input type="text" required className={formStyles.input}
                                                               placeholder="00000" value={this.state.postalCode}
                                                               onChange={(event) => this.setState({postalCode: event.target.value})}
                                                        />
                                                    </div>
                                                </div>

                                                <div
                                                    className={formStyles.inputGroup}
                                                    style={{
                                                        marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                        marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                    }}
                                                >
                                                    <label>Ville*</label>
                                                    <div className={formStyles.inputField}>
                                                        <div className={formStyles.inputIcon}>
                                                            <FontAwesomeIcon icon={faCity}/>
                                                        </div>
                                                        <input type="text" required className={formStyles.input}
                                                               placeholder="Ville"
                                                               value={this.state.city}
                                                               onChange={(event) => this.setState({city: event.target.value})}/>
                                                    </div>
                                                </div>

                                                <div
                                                    className={formStyles.inputGroup}
                                                    style={{
                                                        marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                        marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                    }}
                                                >
                                                    <label>Pays*</label>
                                                    <div className={formStyles.inputField}>
                                                        <div className={formStyles.inputIcon}>
                                                            <FontAwesomeIcon icon={faGlobeEurope}/>
                                                        </div>
                                                        <input value={this.state.country} className={formStyles.input} disabled/>
                                                    </div>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-end",
                                                    paddingBottom: "10px"
                                                }}>
                                                    <button
                                                        className={this.state.postalCode && this.state.city && this.state.country ? globalStyles.defaultBtn : formStyles.disabledBtn}
                                                        disabled={!(this.state.postalCode && this.state.city && this.state.country)}
                                                        style={{padding: "10px 20px"}}
                                                        onClick={() => {
                                                            this.reloadRelay = true;
                                                            this.forceUpdate();
                                                            this.getListPoints();
                                                        }}>
                                                        Chercher
                                                    </button>
                                                </div>
                                            </div>

                                            {Object.keys(this.state.listpoints).length > 0 &&
                                                (!this.reloadRelay &&
                                                    <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                                                        <ListPoints
                                                            listpoints={this.state.listpoints}
                                                            select={this.selected.bind(this)}
                                                        />
                                                        <div
                                                            style={{
                                                                flex: window.innerWidth > 1020 ? 1 : undefined,
                                                                height: "500px",
                                                                marginTop: window.innerWidth < 1020 ? "10px" : undefined,
                                                            }}>
                                                            <MapPoints listpoints={this.state.listpoints}
                                                                       selected={this.selected.bind(this)}/>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            {Object.keys(this.state.listpoints).length === 0 &&
                                                <div>
                                                    Aucun point relais disponible
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                        <div className={formStyles.btnContainer}>
                            <button
                                className={globalStyles.defaultBtn}
                                onClick={this.closeDelivery}
                            >
                                Valider
                            </button>
                        </div>
                    </Modal>


                    <Modal
                        isOpen={this.isOpenModalAdd}
                        contentLabel="Adresse de livraison"
                        bodyOpenClassName={"noScroll"}
                        style={{
                            content : {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 100,
                                width: "70%",
                                padding: "40px",
                                paddingTop: "20px",
                                maxHeight: (window.innerHeight - 180) + "px",
                                borderRadius: "15px"
                            },
                            overlay : {
                                zIndex: 1500,
                                backgroundColor: "rgba(0, 0, 0, 0.8)"
                            }
                        }}
                    >
                        <AddAddressDelivery
                            fetchJsonOrError={this.fetchJsonOrError}
                        />
                        <button
                            style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                background: "none",
                                border: "none",
                                fontSize: "20px",
                                cursor: "pointer"
                            }}
                            onClick={() => this.closeModalAdd()}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    </Modal>

                </div>
            );
        } else {
            this.getInfos()
            return (
                <div/>
            )
        }

    }
}

export default connect(mapStateToProps)(UserCommitment);
