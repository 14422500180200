import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {encode} from "../../Services/ObfuscatorService";
import moment from "moment";
import {NavHashLink} from "react-router-hash-link";

function CardHomeChildInfo(props) {

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -120;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    function getXp(){
        if(props.xp < 1000){
            return props.xp;
        } else {
            let xp = props.xp - props.xp % 100;
            return (xp / 1000) + "k";
        }
    }

    return (
        <div className={`${globalStyles.card} ${globalStyles.cardMore}`} style={{backgroundColor: "white"}}>
            <div style={{flex: 5}}>
                <h2 style={{margin: "0 0 15px 0"}}>
                    <FontAwesomeIcon icon={props.icon} style={{marginRight: "15px"}}/>
                    {props.name}
                </h2>

                <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                    <span style={{
                        backgroundColor: props.color,
                        marginBottom: "10px",
                        borderRadius: "5px",
                        fontSize: "0.8em",
                        padding: "2px 10px",
                        display: "inline-flex",
                        color:"white"
                    }}>
                        {props.commitment}
                    </span>

                    {props.endDate &&
                        <span style={{fontSize: "0.85rem", color: "#555", marginLeft: "10px"}}>
                            Fin le {moment(props.endDate).format("DD/MM/YYYY")}
                        </span>
                    }

                    <div style={{display: "flex", marginTop: "10px"}}>
                        <div style={{
                            flex: 1,
                            backgroundColor: "rgb(243, 246, 248)",
                            padding: "5px 0",
                            margin: "0 5px",
                            borderRadius: "10px",
                            borderBottom: "2px solid",
                            borderBottomColor: props.color
                        }}>
                            <NavHashLink
                                to={"/box#user" + props.id}
                                scroll={el => scrollWithOffset(el)}
                                style={{color: "#777", textDecoration: "none"}}
                            >
                                <span style={{
                                    display: "block",
                                    textAlign: "center",
                                    fontSize: "150%",
                                    fontWeight: 500,
                                    color: "black"
                                }}>
                                    {props.box}
                                </span>
                                <span style={{
                                    display: "block",
                                    textAlign: "center",
                                    fontSize: "0.8em",
                                    color: "#777",
                                    alignItems: "center"
                                }}>
                                    box{props.box > 1 ? 's' : ''}
                                </span>
                            </NavHashLink>
                        </div>


                        <div style={{
                            flex: 1,
                            backgroundColor: "rgb(243, 246, 248)",
                            padding: "5px 0",
                            margin: "0 5px",
                            borderRadius: "10px",
                            borderBottom: "2px solid",
                            borderBottomColor: props.color
                        }}>
                            <NavHashLink
                                to={"/stats#user" + props.id}
                                scroll={el => scrollWithOffset(el)}
                                style={{color: "#777", textDecoration: "none"}}
                            >
                                <span style={{
                                    display: "block",
                                    textAlign: "center",
                                    fontSize: "150%",
                                    fontWeight: 500,
                                    color: "black"
                                }}>
                                    {props.activity}
                                </span>
                                <span style={{
                                    display: "block",
                                    textAlign: "center",
                                    fontSize: "0.8em",
                                    color: "#777",
                                    alignItems: "center"
                                }}>
                                    activité{props.activity > 1 ? 's' : ''}
                                </span>
                            </NavHashLink>
                        </div>


                        <div style={{
                            flex: 1,
                            backgroundColor: "rgb(243, 246, 248)",
                            padding: "5px 0",
                            margin: "0 5px",
                            borderRadius: "10px",
                            borderBottom: "2px solid",
                            borderBottomColor: props.color
                        }}>
                            <NavHashLink
                                to={"/stats#user" + props.id}
                                scroll={el => scrollWithOffset(el)}
                                style={{color: "#777", textDecoration: "none"}}
                            >
                                <span style={{
                                    display: "block",
                                    textAlign: "center",
                                    fontSize: "150%",
                                    fontWeight: 500,
                                    color: "black"
                                }}>
                                    {getXp()}
                                </span>
                                <span style={{
                                    display: "block",
                                    textAlign: "center",
                                    fontSize: "0.8em",
                                    color: "#777",
                                    alignItems: "center"
                                }}>
                                    XP
                                </span>
                            </NavHashLink>
                        </div>
                    </div>

                </div>


            </div>

            <div
                style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end"
                }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                    <a className={globalStyles.defaultLink} href={"/edit/" + encode(props.id)}>Modifier le profil</a>
                    {props.password &&
                        <a className={globalStyles.defaultLink} href={"/resetpwd/" + encode(props.id)}>{props.password}</a>
                    }
                </div>
            </div>
        </div>
    );
}

export default CardHomeChildInfo;
