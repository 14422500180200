import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

function CardTemplateAddress(props) {

    const displayLink = (links) => {
        let url = []
        for(let i in links){
            if(links[i][1] === "link"){
                url.push(
                    <a className={globalStyles.defaultLink} href={links[i][2]}>
                        {links[i][0]}
                    </a>
                )
            } else if(links[i][1] === "btn"){
                url.push(
                    <button className={globalStyles.defaultLink} onClick={links[i][2]}>
                        {links[i][0]}
                    </button>
                )
            }

        }
        return url
    }

    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: props.color ? props.color : "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "column"
            }}
        >
            <div style={{flex: 5}}>
                <h3 style={{margin: "0 0 15px 0"}}>
                    {props.choosen &&
                        <FontAwesomeIcon icon={faCheck} style={{marginRight: "15px", color: "#6fa939"}}/>
                    }
                    {props.name}
                    {props.default &&
                        " (par défaut)"
                    }
                </h3>

                <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                    <div>{props.firstName} {props.lastName.toUpperCase()} </div>
                    <div>{props.streetAddress} </div>
                    {props.streetAddressMore &&
                        <div>
                            {props.streetAddressMore}
                        </div>
                    }
                    <div>{props.postalCode} {props.city} </div>
                    <div>{props.country}</div>
                    <div>{props.phone}</div>
                </div>
            </div>




            <div
                style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px", width: "100%"}}>
                    {!props.choose && !props.default &&
                        <button style={{paddingRight: 0, marginRight: 0}} onClick={() => props.setDefault(props.id)} className={globalStyles.defaultLink}>Adresse par défaut</button>
                    }

                    {displayLink(props.links)}
                    {props.choose && !props.choosen &&
                        <button
                            style={{paddingRight: 0, marginRight: 0}}
                            onClick={() => props.chooseFtn(props.id, props.firstName, props.lastName, props.streetAddress, props.streetAddressMore, props.postalCode, props.city, props.country, props.phone)}
                            className={globalStyles.defaultLink}
                        >
                            Choisir
                        </button>
                    }
                    {!props.choose &&
                        <button style={{paddingRight: 0, marginRight: 0}} onClick={() => props.delete(props.id)} className={globalStyles.defaultLink}>Supprimer</button>
                    }
                </div>
            </div>
        </div>
    );
}

export default CardTemplateAddress;
