import {useParams} from "react-router-dom";
import React from "react";
import Registration from "./Registration";

function GetMailRegistration () {
    let {mail} = useParams()

    return (
        <Registration mail={mail}/>
    )
}

export default GetMailRegistration;
