import {createStore, applyMiddleware} from '@reduxjs/toolkit';
import {createStateSyncMiddleware} from "redux-state-sync";
import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import profileReducer from "./Reducers/ProfileReducer";
import flashReducer from "./Reducers/flashReducer";
import itemReducer from "./Reducers/itemReducer"

const persistConfig = {
    key: "root",
    storage,
};

export default createStore(
    persistCombineReducers(persistConfig, {profile: profileReducer, flash: flashReducer, item: itemReducer}),
    applyMiddleware(createStateSyncMiddleware({
        blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
    }))
);
