import {useParams} from "react-router-dom";
import React from "react";
import ResetPwd from "./ResetPwd";
import {decode} from "../../Services/ObfuscatorService";

function GetIdPwdReset () {
    let {id} = useParams()

    return (
        <ResetPwd id={decode(id)}/>
    )
}

export default GetIdPwdReset;
