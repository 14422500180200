import React from 'react';
import Bandeau from "../header_footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";
import LoggedComponent, {api_url, mapStateToProps} from "../../Services/ConnexionService";
import {connect} from "react-redux";
import globalStyles from "../../Styles/Style.module.css";
import formStyles from "../../Styles/FormStyle.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation, faQuestion, faSchool} from "@fortawesome/free-solid-svg-icons";
import FilAriane from "../header_footer/FilAriane";
import Empty from "../Empty";
import {encode} from "../../Services/ObfuscatorService";

class DeleteProfile extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            id: props.id,
            price: false,
            time: false,
            age: false,
            other: false,
            raison: "",
            canDelete: null,
            firstName: '',
            raisons: {
                price: "Je trouve que c'est trop cher.",
                time: "Je n'ai pas assez de temps.",
                age: "Mes enfants ont grandi.",
                other: "Autre raison :",
            }
        };
        this.loading = true;
        this.isChild = false;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    deletePossible = () => {
        let request = new Request(api_url + '/client/user/delete/possible/' + this.state.id, {method:'GET', credentials:'include'});
        this.fetchJsonOrError(request, (data) => {
            this.setState({canDelete: data.possible});

            let user = null;

            if(this.state.user.id == this.state.id){
                user = this.state.user;
            } else {
                for(let child in this.state.children){
                    if(this.state.children[child].user.id == this.state.id){
                        user = this.state.children[child].user;
                        this.isChild = true
                    }
                }
            }

            this.setState({firstName: user.firstName});

            this.loading = false;
            this.forceUpdate();
        })

    }

    delete = () => {
        let reason = "";
        if(this.state.age){
            reason = this.state.raisons.age;
        } else if(this.state.price){
            reason = this.state.raisons.price;
        } else if(this.state.age){
            reason = this.state.raisons.age;
        } else if(this.state.other){
            reason = this.state.raisons.other + " " + this.state.raison;
        }

        let data = new FormData();
        data.append('json', JSON.stringify({reason: reason}));
        let request = new Request(
            api_url + '/client/user/delete/' + this.state.id,
            {method:'POST', credentials:'include', body: data}
        );
        this.fetchJsonOrError(request, (data) => {
            if(data.disconnected){
                this.setState({shouldNavigate: true, navigateTo: '/logout'});
            } else {
                this.setState({shouldNavigate: true, navigateTo: '/'});
            }
        })

    }


    renderChild() {

        if(!this.loading){
            return (
                <div>
                    <Bandeau title={titleAriane["deleteProfile"]["title"].replace("_name_", this.state.firstName)}/>
                    <FilAriane
                        links={[
                            [
                                titleAriane["deleteProfile"]["ariane"][0],
                                "/"
                            ],
                            [
                                titleAriane["deleteProfile"]["ariane"][1],
                                "/edit/" + encode(this.state.id)
                            ],
                            [
                                titleAriane["deleteProfile"]["ariane"][2],
                                ""
                            ]
                        ]}
                    />

                    <div className={globalStyles.globalContainer}>
                        <div style={{flex: 1}}>

                            <h2 style={{marginLeft: "10px"}}>
                                {!this.isChild ?
                                    "Supprimer votre profil":
                                    "Supprimer le profil " + this.state.firstName
                                }

                            </h2>

                            {this.state.canDelete &&
                                <div>
                                    <div className={globalStyles.bulle}>
                                    <span className={globalStyles.iconBulle} style={{backgroundColor: "#ce3534"}}>
                                        <FontAwesomeIcon icon={faExclamation}/>
                                    </span>
                                        <span className={globalStyles.iconText}>
                                        {this.isChild ?
                                            <span>
                                                En supprimant le profil {this.state.firstName}, vous supprimerez toutes les données personnelles qu'Atorika
                                                possède sur {this.state.firstName}, excepté son historique de connexion avec ses adresses IP et
                                                ses factures pour des raisons légales. <br/>
                                                Cette action est <span style={{fontWeight: "bold", textDecoration: "underline"}}>irreversible</span>.<br/>
                                                <span style={{fontWeight: "bold", textDecoration: "underline"}}>
                                                    Le profil supprimé n'aura plus accès aux tutoriels sur l'application Atorika,
                                                    perdra ses points d'expérience et tout son historique d'activité.
                                                </span>
                                            </span>
                                            :
                                            <span>
                                                En supprimant votre profil, vous supprimerez toutes les données personnelles qu'Atorika
                                                possède sur vous, excepté votre historique de connexion avec vos adresses IP et
                                                vos factures pour des raisons légales. <br/>
                                                Cette action est <span style={{fontWeight: "bold", textDecoration: "underline"}}>irreversible</span>.<br/>
                                                Vos profils enfant seront également supprimés sauf si le profil possède sa propre
                                                adresse e-mail ou si le profil à un autre profil parent.
                                                <span style={{fontWeight: "bold", textDecoration: "underline"}}>Les profils supprimés n'auront plus accès aux tutoriels sur l'application Atorika.</span>
                                            </span>
                                        }
                                    </span>
                                    </div>


                                    {!this.isChild &&
                                        <div>
                                            <h3 style={{marginLeft: "10px"}}>Pourquoi souhaitez-vous supprimer votre
                                                profil ?</h3>

                                            <div style={{flex: 1, display: "flex"}} className={formStyles.inputLine}>
                                                <div className={formStyles.inputGroup} style={{flex: "none"}}>
                                                    <input
                                                        type="radio"
                                                        name="raison"
                                                        value="price"
                                                        style={{marginRight: "10px"}}
                                                        checked={this.state.price}
                                                        onChange={() => {
                                                            this.setState({
                                                                price: true,
                                                                time: false,
                                                                age: false,
                                                                other: false
                                                            })
                                                        }
                                                        }/>
                                                    <label>
                                                        {this.state.raisons.price}
                                                    </label>
                                                </div>
                                            </div>
                                            <div style={{flex: 1, display: "flex"}} className={formStyles.inputLine}>
                                                <div className={formStyles.inputGroup} style={{flex: "none"}}>
                                                    <input
                                                        type="radio"
                                                        name="raison"
                                                        value="time"
                                                        style={{marginRight: "10px"}}
                                                        checked={this.state.time}
                                                        onChange={() => {
                                                            this.setState({
                                                                price: false,
                                                                time: true,
                                                                age: false,
                                                                other: false
                                                            })
                                                        }
                                                        }/>
                                                    <label>
                                                        {this.state.raisons.time}
                                                    </label>
                                                </div>
                                            </div>
                                            <div style={{flex: 1, display: "flex"}} className={formStyles.inputLine}>
                                                <div className={formStyles.inputGroup} style={{flex: "none"}}>
                                                    <input
                                                        type="radio"
                                                        name="raison"
                                                        value="age"
                                                        style={{marginRight: "10px"}}
                                                        checked={this.state.age}
                                                        onChange={() => {
                                                            this.setState({
                                                                price: false,
                                                                time: false,
                                                                age: true,
                                                                other: false
                                                            })
                                                        }
                                                        }/>
                                                    <label>
                                                        {this.state.raisons.age}
                                                    </label>
                                                </div>
                                            </div>
                                            <div style={{flex: 1, display: "flex"}} className={formStyles.inputLine}>
                                                <div className={formStyles.inputGroup} style={{flex: "none"}}>
                                                    <input
                                                        type="radio"
                                                        name="other"
                                                        value="age"
                                                        style={{marginRight: "10px"}}
                                                        checked={this.state.other}
                                                        onChange={() => {
                                                            this.setState({
                                                                price: false,
                                                                time: false,
                                                                age: false,
                                                                other: true
                                                            })
                                                        }
                                                        }/>
                                                    <label>
                                                        {this.state.raisons.other}
                                                    </label>
                                                </div>
                                            </div>
                                            <div style={{flex: 1, display: "flex"}} className={formStyles.inputLine}>
                                                <div className={formStyles.inputGroup} style={{marginTop: 0}}>
                                                    <div className={formStyles.inputField}>
                                                        <div className={formStyles.inputIcon}>
                                                            <FontAwesomeIcon icon={faQuestion}/>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            required
                                                            className={formStyles.input}
                                                            placeholder="Précisez la raison" value={this.state.raison}
                                                            onChange={(event) => {
                                                                this.setState({raison: event.target.value});
                                                                this.setState({
                                                                    price: false,
                                                                    time: false,
                                                                    age: false,
                                                                    other: true
                                                                });
                                                            }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }




                                    <div className={formStyles.btnContainer} style={{marginTop: "20px"}}>
                                        <button type="button" className={globalStyles.defaultBtn} style={{backgroundColor: "#ce3534"}} onClick={() => this.delete()}>
                                            Supprimer
                                        </button>
                                    </div>
                                </div>
                            }

                            {!this.state.canDelete &&
                                <div className={globalStyles.bulle}>
                                <span className={globalStyles.iconBulle} style={{backgroundColor: "#ce3534"}}>
                                    <FontAwesomeIcon icon={faExclamation}/>
                                </span>
                                    <span className={globalStyles.iconText}>
                                    La situation de votre profil ne permet pas la suppression automatique.
                                    Nous vous invitons à contacter le support sur le <a href={"https://help.atorika.fr"} className={globalStyles.defaultLink} style={{fontSize: "0.9rem"}}>centre d'assistance</a>.
                                </span>
                                </div>
                            }



                        </div>
                    </div>

                </div>
            );
        } else {
            this.deletePossible();
            return <Empty/>
        }

    };

}

export default connect(mapStateToProps)(DeleteProfile);
