import React from "react"
import LoggedComponent, {api_url, mapStateToProps} from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import FilAriane from "../header_footer/FilAriane";
import {connect} from "react-redux";
import Bandeau from "../header_footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";
import CardStats from "./CardStats";
import CardPictures from "./CardPictures";

class Pictures extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            pictures : undefined
        }
        this.loading = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getPictures(){
        let request = new Request(api_url + '/client/stats/pictures', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({pictures: data});
            this.loading = false;
            this.forceUpdate();
        })
    }

    displayPictures(){
        let pictures = [];
        for(let i = 0; i < this.state.pictures.length; i++){
            pictures.push(<h2 id={"user" + this.state.pictures[i].id} style={{alignSelf: "flex-start"}}>{this.state.pictures[i].user}</h2>);
            pictures.push(
                <CardPictures
                    pictures={this.state.pictures[i].pictures}
                />
            )
            pictures.push(<br/>)
        }
        return pictures;
    }


    renderChild () {

        if(this.state.pictures){
            return (
                <div>
                    <Bandeau title={titleAriane["pictures"]["title"]}/>

                    <FilAriane
                        links={[
                            [
                                titleAriane["pictures"]["ariane"][0],
                                "/"
                            ],
                            [
                                titleAriane["pictures"]["ariane"][1],
                                "/stats"
                            ],
                            [
                                titleAriane["pictures"]["ariane"][2],
                                "/pictures"
                            ]
                        ]}
                    />

                    <div className={globalStyles.globalContainer} style={{flexDirection: "column", padding: window.innerWidth > 960 ? "unset" : "10px"}}>
                        {this.displayPictures()}
                    </div>
                </div>
            );
        } else {
            this.getPictures()
            return (
                <div/>
            )
        }
    }
}

export default connect(mapStateToProps)(Pictures);