import {useParams} from "react-router-dom";
import React from "react";
import ViewCommand from "./ViewCommand";
import {decode} from "../../Services/ObfuscatorService";

function GetIdCommand () {
    let {id} = useParams()

    return (
        <ViewCommand id={decode(id)}/>
    )
}

export default GetIdCommand;
