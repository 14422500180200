import styles from './Empty.module.css';
import React, {Component} from "react"
import Header from "./header_footer/Header";
import Footer from "./header_footer/Footer";
import Bandeau from "./header_footer/Bandeau";

class Empty extends Component {

    render () {

        return (
            <div className={styles.div_100}>
                <Header empty={true}/>
            </div>
        );
    }
}

export default Empty;
