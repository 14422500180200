import {useParams} from "react-router-dom";
import React from "react";
import Tracking from "./Tracking";
import {decode} from "../../Services/ObfuscatorService";

function GetIdTracking () {
    let {id} = useParams()

    return (
        <Tracking id={decode(id)}/>
    )
}

export default GetIdTracking;
