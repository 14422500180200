import React from "react";
import globalStyles from '../../../Styles/Style.module.css';
import {
    faTimes,
    faMousePointer, faUsers, faQuestionCircle, faUser, faInfo
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import AddChildBasket from "./AddChildBasket";
import ListUser from "./ListUser";
import formStyles from "../../../Styles/FormStyle.module.css";
import {api_url} from "../../../Services/ConnexionService";
import styles from "./CardTemplateBasket.module.css";
import ChooseBoxCard from "../../Commitment/ChooseBoxCard"
import DisplayPrice from "../../Utils/DisplayPrice";
import ReactTooltip from "react-tooltip";

class CardTemplateBasket extends React.Component {

    constructor(props) {
        super(props);
        this.isOpen = false;
        this.bmIsOpen = false;
        this.state = {
            value: undefined,
        }
        this.select = false;
    }

    displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            informations.push(
                <div style={{marginTop: "10px"}}>
                    {infos[i]}
                </div>
            )
        }
        return informations
    }

    displayStock = (stock, qt, isComm) => {
        if (isComm)
            return []
        if(stock < qt) {
            return (
                <span className={globalStyles.badge} style={{backgroundColor:"#52BAEC"}}>
                    En précommande
                </span>
            )
        }
        if(stock < 10) {
            return (
                <span className={globalStyles.badge} style={{backgroundColor:"#f4bc00"}}>
                    Moins de 10 en stock
                </span>
            )
        }
        return (
            <span className={globalStyles.badge} style={{backgroundColor:"#6fa939"}}>
                En stock
            </span>
        )
    }

    openModal = () => {
        this.isOpen = true;
        this.forceUpdate();
    }

    closeModal = () => {
        this.isOpen = false;
        this.bmIsOpen = false;
        this.forceUpdate();
    }

    handleChange = (event) => {
        this.setState({value: event.target.value});
        if(event.target.value === "new"){
            this.openModal()
        } else if (event.target.value === "gift"){
            this.props.setQuantity(this.props.oaid, -1, this.props.quantity, true)
        } else if (!isNaN(parseInt(event.target.value))){
            this.props.setQuantity(this.props.oaid, event.target.value, this.props.quantity, true);
        } else if (event.target.value === "selection"){
            this.select = true;
            this.forceUpdate();
            this.props.cleanQuantity(this.props.oaid)
            this.props.setQuantity(this.props.oaid, this.props.users[0].id, this.props.quantity, false);
        } else if (event.target.value === ""){
            this.props.cleanQuantity(this.props.oaid)
        }
        this.forceUpdate();
    }

    getOptions() {
        let options = [];
        options.push(
            <option value="">

            </option>
        )
        for(let i = 0; i < this.props.users.length; i++){
            options.push(
                <option value={this.props.users[i].id} selected={this.props.userId === this.props.users[i].id}>{this.props.users[i].fullName}</option>
            )
            if(!this.state.value && this.props.userId === this.props.users[i].id){
                this.setState({value: this.props.userId});
            }
        }
        options.push(
            <option value="new">
                Nouveau profil
            </option>
        )
        options.push(
            <option value="gift">
                Offrir en cadeau
            </option>
        )
        if (this.props.canChangeQuantity) {
            options.push(
                <option value="selection">
                    Selection détaillée
                </option>
            )
        }
        return options;
    }

    deleteOA = () => {
        let request = new Request(api_url + '/client/oa/delete/' + this.props.oaid, {method:'POST', credentials:'include'});
        this.props.fetchJsonOrError(request, (data) => {
            if(data["deleted"]){
                window.location.reload();
            }
        })
    }

    decreaseQty = () => {
        this.props.changeQuantity(this.props.oaid, this.props.getQuantity(this.props.oaid) - 1, this.props.userId);
    }

    increaseQty = () => {
        this.props.changeQuantity(this.props.oaid, this.props.getQuantity(this.props.oaid) + 1, this.props.userId);
        if(!isNaN(parseInt(this.state.value))){
            this.props.setQuantity(this.props.oaid, this.state.value, this.props.getQuantity(this.props.oaid), true);
        }
    }

    openChooseBoxModal = () => {
        this.bmIsOpen = true;
        this.forceUpdate();
    }

    getDependencies = () => {
        let dep = "";
        for(let i = 0; i < this.props.dependencies.length; i++){
            dep += this.props.dependencies[i].name;
            if(i !== this.props.dependencies.length - 1){
                dep += ", ";
            }
        }

        return dep;
    }


    render() {
        return (
            <div style={{flex: 1}}>
                <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                    <div
                        className={globalStyles.card}
                        style={{
                            padding: "30px",
                            backgroundColor: "white",
                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                            borderRadius: "5px",
                            display: "flex",
                            boxSizing: "border-box",
                            flexDirection: "column",
                            flex: 2,
                            marginRight: window.innerWidth > 1020 && this.props.type !== "other" ? "10px" : undefined,
                            marginBottom: "10px",
                            marginTop: "10px"
                        }}
                    >
                        <div style={{flex: 5}}>
                            <h2 style={{margin: "0 0 15px 0"}}>
                                <FontAwesomeIcon icon={this.props.icon} style={{marginRight: "15px"}}/>
                                {this.props.title}
                            </h2>

                            <div style={{flexDirection: window.innerWidth > 1020 ? "row" : "column", display: "flex"}}>
                                {this.props.img &&
                                    <div style={{flex: 1}}>
                                        <img
                                            src={api_url + '/uploads/article/' + this.props.img}
                                            style={{width: window.innerWidth > 1020 ? "calc(100% - 40px)" : "50%", margin: "20px"}}
                                        />
                                    </div>
                                }
                                <div style={{flex: 2, marginLeft: "15px"}}>
                                    {this.displayInfos(this.props.infos)}

                                    {this.props.dependencies.length > 0 &&
                                        <div style={{margin: "10px 0"}}>
                                            <div className={styles.bulle}>
                                                <span className={styles.iconBulle}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </span>
                                                <span className={styles.iconText}>
                                                    Avec cet article, vous avez besoin de <b>{this.getDependencies()}</b> qui a automatiquement été ajouté à votre panier.
                                                </span>
                                            </div>
                                        </div>
                                    }

                                    {this.props.fromDependency &&
                                        <div style={{margin: "10px 0"}}>
                                            <div className={styles.bulle}>
                                                <span className={styles.iconBulle}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </span>
                                                <span className={styles.iconText}>
                                                    Cet article a été ajouté automatiquement car il est nécessaire pour l'une des activités de votre panier.
                                                    Vous pouvez décider de le supprimer si vous le possédez déjà.
                                                </span>
                                            </div>
                                        </div>
                                    }

                                    <div style={{fontSize: "1.2rem", fontWeight: 600, margin: this.props.unitPrice ? "15px auto 5px" : "15px auto"}}>
                                        Prix : <DisplayPrice price = {this.props.price}/>
                                    </div>

                                    {this.props.unitPrice &&
                                        <span className={globalStyles.badge} style={{backgroundColor:"#6fa939"}}>
                                            Equivaut à <DisplayPrice price = {this.props.unitPrice}/>/mois
                                        </span>
                                    }

                                    <div style={{marginTop: "10px"}}>
                                        {this.displayStock(this.props.stock, this.props.quantity, this.props.isComm)}
                                    </div>

                                    <div style={{flexDirection: "row", marginTop: "15px", marginBottom: "15px"}}>
                                        {this.props.canChangeQuantity &&
                                            <div style={{display: "flex", flexDirection: "row", margin: "10px 0"}}>
                                                <div>
                                                    Quantité :
                                                </div>
                                                <button
                                                    className={
                                                        this.state.value === "selection" || this.props.getQuantity(this.props.oaid) <= 1  ?
                                                            styles.disabledControleBtn :
                                                            styles.controleBtn
                                                    }
                                                    onClick={() => this.decreaseQty()}
                                                    style={{paddingBottom: "2px"}}
                                                    disabled={this.state.value === "selection" || this.props.getQuantity(this.props.oaid) <= 1}
                                                >
                                                    -
                                                </button>
                                                {this.props.getQuantity(this.props.oaid)}
                                                <button
                                                    className={
                                                        this.state.value === "selection" ?
                                                            styles.disabledControleBtn :
                                                            styles.controleBtn
                                                    }
                                                    onClick={() => this.increaseQty()}
                                                    disabled={this.state.value === "selection"}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                                <button className={globalStyles.defaultLink} onClick={() => this.deleteOA()}>
                                    Supprimer du panier
                                </button>
                            </div>
                        </div>
                    </div>


                    {this.props.type !== "other" &&
                        <div
                            className={globalStyles.card}
                            style={{
                                padding: "30px",
                                backgroundColor: "rgb(243, 246, 248)",
                                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                borderRadius: "5px",
                                display: "flex",
                                boxSizing: "border-box",
                                flexDirection: "column",
                                flex: 1,
                                marginTop: "10px",
                                marginLeft: window.innerWidth > 1020 ? "10px" : undefined,
                                marginBottom: "10px"
                            }}
                        >
                            <div>
                                <h2 style={{margin: "0 0 15px 0"}}>
                                    <FontAwesomeIcon icon={faUser} style={{marginRight: "15px"}}/>
                                    Pour qui est-ce ?
                                </h2>
                                <p style={{marginTop: "5px"}}>
                                    Vous devez indiquer quel utilisateur accédera aux tutoriels de l'activité.
                                </p>
                                <div style={{marginBottom: "10px"}}>
                                    <select
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        style={{
                                            padding: "3px",
                                            border: "1px solid #ced4da",
                                            borderRadius: "0.25rem"
                                        }}
                                    >
                                        {this.getOptions()}
                                    </select>
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        tabIndex={-1}
                                        data-tip={"Si vous offrez un cadeau à quelqu'un, choisissez \"Offrir en cadeau\". Vous recevrez alors un code d'activation à lui communiquer."}
                                    />
                                </div>
                                {this.props.needChooseBox && this.props.getBoxInfos(this.props.getBox(this.props.oaid)) &&
                                    <div style={{
                                        flex: 1
                                    }}>
                                        <p style={{marginBottom: "10px"}}>
                                            La première box sélectionnée pour cet abonnement est la suivante :
                                        </p>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start"
                                        }}>
                                            <b>{this.props.getBoxInfos(this.props.getBox(this.props.oaid))}</b>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        }}>
                                            <button className={globalStyles.defaultLink} style={{marginTop: "15px"}} onClick={() => this.openChooseBoxModal()}>
                                                Changer...
                                            </button>
                                        </div>
                                    </div>
                                }

                                {this.props.needChooseBox && !this.props.getBoxInfos(this.props.getBox(this.props.oaid)) &&
                                    <div style={{
                                        flex: 1
                                    }}>
                                        <p style={{marginBottom: "10px"}}>
                                            Vous pouvez choisir votre première box. A défaut, la sélection sera automatique.
                                        </p>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        }}>
                                            <button className={globalStyles.defaultLink} style={{marginTop: "15px"}} onClick={() => this.openChooseBoxModal()}>
                                                Choisir
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>

                {this.state.value === "selection" &&
                    <div
                        className={globalStyles.card}
                        style={{
                            padding: "30px",
                            backgroundColor: "white",
                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                            borderRadius: "5px",
                            display: "flex",
                            boxSizing: "border-box",
                            flexDirection: "column",
                            flex: 1,
                            marginTop: "10px",
                            marginBottom: "10px"
                        }}
                    >
                        <ListUser
                            title={"Selection détaillée"}
                            iconTitle={faUsers}
                            desktopNumber={3}
                            mobileNumber={1}
                            users={this.props.users}
                            setQuantity={this.props.setQuantity.bind(this)}
                            oaid={this.props.oaid}
                            quantity={this.props.quantity}
                            fetchJsonOrError={this.props.fetchJsonOrError}
                        />
                    </div>
                }


                <Modal
                    isOpen={this.isOpen}
                    bodyOpenClassName={"noScroll"}
                    contentLabel="Ajouter un profil"
                    style={{
                        content : {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 500,
                            width:  window.innerWidth > 1020 ? "70%" : "calc(100% - 60px)",
                            padding: window.innerWidth > 1020 ? "40px" : "20px",
                            paddingTop: "20px",
                            borderRadius: "15px"
                        },
                        overlay : {
                            zIndex: 1500,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)'
                        }
                    }}
                >
                    <AddChildBasket
                        fetchJsonOrError={this.props.fetchJsonOrError}
                        oaid={this.props.oaid}
                        title={"Ajouter un enfant"}
                    />
                    <button
                        style={{
                            position: "absolute",
                            top: "20px",
                            right: "20px",
                            background: "none",
                            border: "none",
                            fontSize: "20px",
                            cursor: "pointer"
                        }}
                        onClick={() => this.closeModal()}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </Modal>


                <Modal
                    isOpen={this.bmIsOpen}
                    bodyOpenClassName={"noScroll"}
                    contentLabel="Choisir la première box"
                    style={{
                        content : {
                            margin: "auto",
                            zIndex: 500,
                            width: window.innerWidth > 1020 ? "70%" : "calc(100% - 60px)",
                            padding: window.innerWidth > 1020 ? "40px" : "20px",
                            maxHeight: (window.innerHeight - 120) + "px",
                            overflowY: "hidden",
                            display: "flex",
                            inset: 0,
                            borderRadius: "15px"
                        },
                        overlay : {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1500,
                            backgroundColor: "rgba(0, 0, 0, 0.8)"
                        }
                    }}
                >
                    <ChooseBoxCard
                        dispatch={this.props.dispatch}
                        setBox={this.props.setBox}
                        setBoxInfos={this.props.setBoxInfos}
                        childOAid={this.props.childOAid}
                        oaid={this.props.oaid}
                        close={this.closeModal.bind(this)}
                        fetchJsonOrError={this.props.fetchJsonOrError}
                        orderId={this.props.orderId}
                    />
                    <button
                        style={{
                            position: "absolute",
                            top: "20px",
                            right: "20px",
                            background: "none",
                            border: "none",
                            fontSize: "20px",
                            cursor: "pointer"
                        }}
                        onClick={() => this.closeModal()}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </Modal>
                <ReactTooltip multiline={true}/>
            </div>
        );
    }

}

export default CardTemplateBasket;
