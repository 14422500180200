import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function CardTemplate(props) {

    const displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            if(infos[i]){
                informations.push(
                    <div>
                        {infos[i]}
                    </div>
                )
            }
        }
        return informations
    }

    const displayLink = (links) => {
        let url = []
        for(let i in links){
            if(links[i]){
                if(links[i][1] === "link"){
                    url.push(
                        <a className={globalStyles.defaultLink} href={links[i][2]}>
                            {links[i][0]}
                        </a>
                    )
                } else if (links[i][1] === "btn"){
                    url.push(
                        <button className={globalStyles.defaultLink} onClick={links[i][2]}>
                            {links[i][0]}
                        </button>
                    )
                }
            }
        }
        return url
    }

    return (
        <div
            className={globalStyles.card}
             style={{
                 padding: "30px",
                 flex: 1,
                 backgroundColor: props.color ? props.color : "#f3f6f8",
                 boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                 borderRadius: "5px",
                 display: "flex",
                 height: "100%",
                 boxSizing: "border-box",
                 flexDirection: "column"
             }}
        >
            <div style={{flex: 5}}>
                <h2 style={{margin: "0 0 15px 0"}}>
                    <FontAwesomeIcon icon={props.icon} style={{marginRight: "15px"}}/>
                    {props.title}
                </h2>

                <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                    {displayInfos(props.infos)}
                </div>
            </div>

            <div
                style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end"
                }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                    {displayLink(props.links)}
                </div>
            </div>
        </div>
    );
}

export default CardTemplate;
