import {useParams} from "react-router-dom";
import React from "react";
import {decode} from "../../../Services/ObfuscatorService";
import UserCommitment from "./UserCommitment";

function GetIdUserCommitment () {
    let {id} = useParams()

    return (
        <UserCommitment id={decode(id)}/>
    )
}

export default GetIdUserCommitment;