import React from "react"
import LoggedComponent, {api_url, mapStateToProps} from "../../../Services/ConnexionService";
import globalStyles from '../../../Styles/Style.module.css';
import FilAriane from "../../header_footer/FilAriane";
import {connect} from "react-redux";
import Bandeau from "../../header_footer/Bandeau";
import titleAriane from "../../../Helpers/TitleAriane.json";
import CardCommitment from "./CardCommitment";
import moment from "moment";

class Commitment extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            commitments : undefined
        }
        this.loading = true;
    }


    doesMustLog = () => {
        this.mustLog = 1
    }

    getCommitments(){
        let request = new Request(api_url + '/client/user/commitments', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({commitments: data});
            this.loading = false;
            this.forceUpdate();
        })
    }

    displayUserCommitments = (nbCommitment) => {
        let userCommitments = [];
        for(let i in this.state.commitments){
            userCommitments.push(<h2 style={{alignSelf: "flex-start"}}>{this.state.commitments[i].user}</h2>)
            userCommitments.push(<div className={globalStyles.cardContainer} style={{flexWrap: "wrap", margin: "auto -10px"}}>{this.displayCommitment(i, nbCommitment)}</div>)
            userCommitments.push(<br/>)
        }
        return userCommitments;
    }


    displayCommitment = (index, nbCommitment) => {
        let commitments = []
        let missing = (this.state.commitments[index].commitment.length % nbCommitment);
        let currentLine = 0;
        for (let c in this.state.commitments[index].commitment) {
            if (c >= nbCommitment*currentLine + nbCommitment){
                commitments.push(<div className={globalStyles.break}/>)
                currentLine++;
            }

            commitments.push(
                <div className={globalStyles.childCard} style={{
                    marginLeft: c%nbCommitment === 0 ? "0" : "10px",
                    marginRight: c%nbCommitment === nbCommitment - 1 ? "0" : "10px",
                }}>
                    <CardCommitment
                        id={this.state.commitments[index].commitment[c].idUC}
                        media={this.state.commitments[index].commitment[c].media[0]}
                        title={this.state.commitments[index].commitment[c].name}
                        begin={moment(this.state.commitments[index].commitment[c].begin.date).format("DD/MM/YYYY")}
                        end={moment(this.state.commitments[index].commitment[c].end.date).format("DD/MM/YYYY")}
                        color={this.state.commitments[index].commitment[c].end.date < new Date() ? "#6FA939" : "#F4BC00"}
                        state={this.state.commitments[index].commitment[c].end.date < new Date() ? "Terminé" : "En cours"}
                        infos={[
                            this.state.commitments[index].commitment[c].duration + " mois",
                            this.state.commitments[index].commitment[c].description
                        ]}
                    />
                </div>
            )
        }

        for(let i = 0; i < missing; i++){
            commitments.push(
                <div style={{flex: 1, display: "flex",  flexDirection: "row", margin: "10px"}}>
                </div>
            )
        }
        return commitments
    }

    renderChild () {

        if(this.state.commitments){
            return (
                <div>
                    <Bandeau title={titleAriane["commitment"]["title"]}/>

                    <FilAriane
                        links={[
                            [
                                titleAriane["commitment"]["ariane"][0],
                                "/"
                            ],
                            [
                                titleAriane["commitment"]["ariane"][1],
                                ""
                            ]
                        ]}
                    />

                    <div className={globalStyles.globalContainer} style={{flexDirection: "column", padding: window.innerWidth > 960 ? "unset" : "10px"}}>


                        {this.displayUserCommitments(window.innerWidth > 1020 ? 2 : 1)}

                    </div>
                </div>
            );
        } else {
            this.getCommitments()
            return (
                <div/>
            )
        }

    }
}

export default connect(mapStateToProps)(Commitment);