import React, { useState } from "react";
import logo from '../../assets/logo/logo-horizontal.svg';
import styles from './Header.module.css';
import globalStyles from  '../../Styles/Style.module.css';
import useWindowDimensions from "../../Services/WindowsDimensions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faShoppingCart, faTimes, faUserCircle} from "@fortawesome/free-solid-svg-icons";



function Header(props) {
    const {height, width} = useWindowDimensions();

    const [showMenu, setShowMenu] = useState(false);
    const [showMenuConnected, setShowMenuConnected] = useState(false);


    if(width > 960){
        return (
            <div>
                <header className={styles.navbar}>

                    <div className={styles.logoContainer}>
                        <a href="https://atorika.fr"><img src={logo} alt="logo" className={styles.logo}/></a>
                    </div>

                    <a className={styles.link} href="https://www.atorika.fr/decouvrir">
                        Découvrir
                    </a>
                    <a className={styles.link} href="https://www.atorika.fr/maison">
                        A la maison
                    </a>
                    <a className={styles.link} href="https://www.atorika.fr/scolaires">
                        A l'école
                    </a>
                    <a className={`${styles.btn} ${styles.redBtn}`} href="https://www.atorika.fr/ateliers">
                        Box et ateliers
                    </a>

                    {props.connected ?
                        <div className={styles.leftBtn} style={{display: "flex"}}>
                            <a
                                className={styles.mobileUserBtn}
                                href={'/basket'}
                                style={{fontSize: "1.8rem", marginRight: "20px"}}
                            >
                                <div style={{
                                    fontSize: "0.5em",
                                    position: "relative",
                                    left: "80%",
                                    top: "-12px",
                                    backgroundColor: "#571088",
                                    color: "white",
                                    height: "25px",
                                    width: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "20px",
                                    zIndex: 100
                                }}>
                                    {props.basket}
                                </div>
                                <FontAwesomeIcon icon={faShoppingCart} style={{color: "black", top: "3px", position: "relative"}}/>
                            </a>
                            <button className={`${globalStyles.defaultBtn} ${styles.btn}`} onClick={() => setShowMenuConnected(!showMenuConnected)}>
                                Bonjour {props.user.firstName}
                            </button>
                        </div>
                        :
                        props.empty ?
                            <div/>
                            :
                            <a className={`${globalStyles.defaultBtn} ${styles.btn} ${styles.leftBtn}`} href={"/login"}>
                                <span className="Connect-span">Se connecter</span>
                            </a>
                    }

                </header>

                {props.connected && showMenuConnected &&

                <div className={styles.dropdownMenuConnected}>

                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="/">
                            Votre espace
                        </a>
                    </div>

                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="/commands">
                            Vos commandes
                        </a>
                    </div>

                    {props.user.hasCommitment &&
                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/commitment">
                                Vos abonnements
                            </a>
                        </div>
                    }

                    {props.user.hasBox &&
                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/box">
                                Vos box et tutoriels
                            </a>
                        </div>
                    }


                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="/stats">
                            Vos activités
                        </a>
                    </div>

                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="/logout">
                            Se déconnecter
                        </a>
                    </div>

                </div>
                }
            </div>
        );
    } else {
        return(
            <div>
                <header className={styles.navbar}>

                    <div className={styles.logoContainer}>
                        <a href="https://www.atorika.fr"><img src={logo} alt="logo" className={styles.logo}/></a>
                    </div>

                    <div className={styles.leftBtnContainer}>
                        <button
                            className={styles.menu}
                            onClick={() => {
                                if(!showMenu){
                                    setShowMenuConnected(false)
                                }
                                setShowMenu(!showMenu)
                            }}
                        >
                            <FontAwesomeIcon icon={showMenu ? faTimes : faBars} color={"black"}/>
                        </button>

                        {props.connected ?
                            <div style={{display: "flex", alignItems: "center"}}>
                                <a
                                    className={styles.mobileUserBtn}
                                    href={'/basket'}
                                    style={{fontSize: "1.8rem"}}
                                >
                                    <div style={{
                                        fontSize: "0.5em",
                                        position: "absolute",
                                        right: "-7px",
                                        top: "-7px",
                                        backgroundColor: "#571088",
                                        color: "white",
                                        height: "25px",
                                        width: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "20px",
                                        zIndex: 100
                                    }}>
                                        {props.basket}
                                    </div>
                                    <FontAwesomeIcon icon={faShoppingCart} style={{color: "black"}}/>
                                </a>

                                <button
                                    className={styles.mobileUserBtn}
                                    onClick={() => {
                                        if (!showMenuConnected) {
                                            setShowMenu(false)
                                        }
                                        setShowMenuConnected(!showMenuConnected)
                                    }}
                                    style={{fontSize: "2.2rem"}}
                                >
                                    <FontAwesomeIcon icon={faUserCircle}/>
                                </button>
                            </div>
                            :
                            props.empty ?
                                <div/>
                                :
                                <a className={styles.mobileUserBtn} href="/">
                                    <FontAwesomeIcon icon={faUserCircle} style={{fontSize: "2.2rem"}}/>
                                </a>
                        }


                    </div>

                </header>

                {showMenu &&

                <div className={styles.dropdownMenu}>
                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="https://www.atorika.fr/decouvrir">
                            Découvrir
                        </a>
                    </div>

                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="https://www.atorika.fr/maison">
                            A la maison
                        </a>
                    </div>

                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="https://www.atorika.fr/scolaires">
                            A l'école
                        </a>
                    </div>

                    <div style={{paddingTop: "20px", paddingBottom: "20px"}}>
                        <a className={`${styles.mobileBtn} ${styles.mobileRedBtn}`} href="https://www.atorika.fr/ateliers">
                            Box et ateliers
                        </a>
                    </div>

                </div>
                }

                {props.connected && showMenuConnected &&

                <div className={styles.mobileDropdownMenuConnected}>

                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="/">
                            Votre espace
                        </a>
                    </div>

                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="/commands">
                            Vos commandes
                        </a>
                    </div>

                    {props.user.hasCommitment &&
                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/commitment">
                                Vos abonnements
                            </a>
                        </div>
                    }

                    {props.user.hasBox &&
                        <div className={styles.mobileMenuItem}>
                            <a className={styles.mobileLink} href="/box">
                                Vos box et tutoriels
                            </a>
                        </div>
                    }

                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="/stats">
                            Vos activités
                        </a>
                    </div>

                    <div className={styles.mobileMenuItem}>
                        <a className={styles.mobileLink} href="/logout">
                            Se déconnecter
                        </a>
                    </div>

                </div>
                }

            </div>

        )
    }
}

export default Header;
