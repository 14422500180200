import {useParams} from "react-router-dom";
import React from "react";
import FinishShopping from "./FinishShopping";
import {decode} from "../../Services/ObfuscatorService";

function GetIdPay () {
    let {id} = useParams()

    return (
        <FinishShopping id={decode(id)}/>
    )
}

export default GetIdPay;
