import {Navigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import Empty from "../../../Empty";
import {useDispatch} from "react-redux";
import {api_url} from "../../../../Services/ConnexionService";

function AddItemReference () {
    let {reference} = useParams()
    let [canGo404, setCanGo404] = useState(false)
    let [canGoAdded, setCanGoAdded] = useState(false)
    const dispatch = useDispatch()

    if (canGo404) {
        return (
            <Navigate to={"/error"}/>
        )
    }
    if (canGoAdded) {
        return (
            <Navigate to={"/additem"}/>
        )
    }

    let request = new Request(api_url + '/client/article/exists/' + reference, {method:'POST', credentials:'include'})
    fetch(request).then((response) => {
        let json = {}
        try {
            json = response.json()
        } catch (e) {
            setCanGo404(true)
        }
        return json
    }).then((data) => {
        switch (data.type) {
            case 'OK':
                dispatch({
                    type: "ADD_ITEM",
                    reference: reference
                })
                setCanGoAdded(true)
                break
            default:
                setCanGo404(true)
        }
    })
    return (
        <Empty/>
    )
}

export default AddItemReference;
