import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faLock} from "@fortawesome/free-solid-svg-icons";
import LoggedComponent, {api_url, mapStateToProps} from "../../Services/ConnexionService";
import FilAriane from "../header_footer/FilAriane";
import globalStyles from "../../Styles/Style.module.css";
import formStyles from '../../Styles/FormStyle.module.css';
import {connect} from "react-redux";
import Bandeau from "../header_footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";


class ResetPwd extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            id: props.id,
            password: '',
            confirmPassword: '',
            effectiveUser: {
                firstName: '',
                lastName: '',
            },
            showPwd: false,
            showConfirmPwd: false
        };
        this.hasEffective = false;
        this.errors = {
            password: '',
        }
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    renderChild () {

        if(!this.hasEffective){
            this.hasEffective = true;
            if(this.state.user.id != this.state.id){
                for(let child in this.state.children){
                    if(this.state.children[child].user.id == this.state.id){
                        this.setState({effectiveUser: this.state.children[child].user});
                    }
                }
            } else {
                this.setState({effectiveUser: this.state.user})
            }
        }

        const reset = () => {
            let data = new FormData();
            data.append('json', JSON.stringify(this.state));
            let request = new Request(api_url + '/client/user/resetpwd/' + this.state.id, {method:'POST', body: data, credentials:'include'});
            this.fetchJsonOrError(request, (json) => {
                if(json["reset"]){
                    this.addFlash('success', 'Le mot de passe a bien été modifié.')
                    this.setState({shouldNavigate: true, navigateTo: '/'})
                    this.forceUpdate();
                }
            })
        }

        const formValid = () => {
            let passwordCheck = (this.state.password.length > 0) && (this.state.confirmPassword.length > 0) && !(this.errors.password.length > 0);
            return passwordCheck;
        }

        const isSubmit = () => {
            if(formValid()){
                reset();
            }
        }

        return (


            <div>
                <Bandeau title={titleAriane["password"]["title"].replace("_name_", this.state.effectiveUser.firstName)}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["password"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["password"]["ariane"][1],
                            ""
                        ]
                    ]}
                />

                <div className={globalStyles.globalContainer}>
                    <div style={{flex: 1}}>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>Mot de passe*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </div>
                                    <input
                                        type={this.state.showPwd ? "text" : "password"}
                                        required
                                        className={formStyles.input}
                                        placeholder="••••••••"
                                        value={this.state.password}
                                        onChange={(event) => {
                                            this.setState({password: event.target.value});
                                            if (this.state.confirmPassword !== "" && event.target.value !== "") {
                                                if (this.state.password !== event.target.value) {
                                                    this.errors.password = "Les mots de passe ne correspondent pas.";
                                                } else {
                                                    this.errors.password = "";
                                                }
                                            } else {
                                                this.errors.password = "";
                                            }
                                        }}
                                    />
                                    <button className={formStyles.eyeIcon} onClick={() => this.setState({showPwd: !this.state.showPwd})}>
                                        <FontAwesomeIcon icon={this.state.showPwd ? faEyeSlash : faEye}/>
                                    </button>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Confirmation du mot de passe*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </div>
                                    <input
                                        type={this.state.showConfirmPwd ? "text" : "password"}
                                        required
                                        className={formStyles.input}
                                        placeholder="••••••••"
                                        value={this.state.confirmPassword}
                                        onChange={(event) => {
                                            this.setState({confirmPassword: event.target.value});
                                            if (this.state.password !== "" && event.target.value !== "") {
                                                if (this.state.password !== event.target.value) {
                                                    this.errors.password = "Les mots de passe ne correspondent pas.";
                                                } else {
                                                    this.errors.password = "";
                                                }
                                            } else {
                                                this.errors.password = "";
                                            }
                                        }}
                                    />
                                    <button className={formStyles.eyeIcon} onClick={() => this.setState({showConfirmPwd: !this.state.showConfirmPwd})}>
                                        <FontAwesomeIcon icon={this.state.showConfirmPwd ? faEyeSlash : faEye}/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.textDanger}>{this.errors.password}</div>
                        </div>

                        <div className={formStyles.btnContainer} style={{marginTop: "20px"}}>
                            <button type="button" className={formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn} onClick={() => isSubmit(this.state)} disabled={!formValid()}>
                                Enregistrer
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ResetPwd);
