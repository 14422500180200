import React from "react";

function DisplayPrice(props) {

    const getDecimals = () => {
        let price = props.price.toString();
        let priceArray = price.split(".");
        let intPrice = priceArray[0];
        let floatprice = "00";
        if(priceArray.length > 1){
            floatprice = priceArray[1];
            if(floatprice.length === 1){
                floatprice = floatprice + "0";
            }
        }
        return {intPrice, floatprice}
    }

    const {intPrice, floatprice} =  getDecimals()

    return (
        <span style={{color: props.color ? props.color : undefined}}>
            <span>
                {props.strike ?
                    <strike>{intPrice}</strike>
                    :
                    intPrice
                }
            </span>
            <span style={{fontSize: "60%"}}>
                {props.strike ?
                    <strike>€{floatprice}</strike>
                    :
                    "€" + floatprice
                }
            </span>
        </span>
    );
}

export default DisplayPrice;