import React from "react";
import globalStyles from '../../../Styles/Style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "./CardTemplateBasket.module.css";

class CardTemplate extends React.Component{

    constructor(props) {
        super(props);
        this.isOpen = false;
        this.state = {
            quantity: props.quantity,
        }
    }

    decreaseQty = () => {
        this.props.setQuantity(this.props.oaid, this.props.id, this.state.quantity - 1, false);
        this.setState({quantity: this.state.quantity - 1});
    }

    increaseQty = () => {
        this.props.setQuantity(this.props.oaid, this.props.id, this.state.quantity + 1, false);
        this.setState({quantity: this.state.quantity + 1});
    }

    render() {
        return (
            <div
                className={globalStyles.card}
                style={{
                    padding: "30px",
                    flex: 1,
                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                    borderRadius: "5px",
                    display: "flex",
                    height: "100%",
                    boxSizing: "border-box",
                    flexDirection: "column"
                }}
            >
                <div style={{flex: 5}}>
                    <h3 style={{margin: "10px 0", marginTop: 0}}>
                        <FontAwesomeIcon icon={this.props.icon} style={{marginRight: "15px"}}/>
                        {this.props.firstName}
                    </h3>

                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div>
                            Quantité :
                        </div>
                        <button
                            className={
                                this.state.quantity <= 0  ?
                                    styles.disabledControleBtn :
                                    styles.controleBtn
                            }
                            onClick={() => this.decreaseQty()}
                            style={{paddingBottom: "2px"}}
                            disabled={this.state.quantity <= 0}
                        >
                            -
                        </button>
                        {this.state.quantity}
                        <button
                            className={styles.controleBtn}
                            onClick={() => this.increaseQty()}
                        >
                            +
                        </button>
                    </div>
                </div>

            </div>
        );
    }

}

export default CardTemplate;
