import {useParams} from "react-router-dom";
import React from "react";
import Payment from "./Payment";
import {decode} from "../../../Services/ObfuscatorService";

function GetIdPayment () {
    let {id} = useParams()

    return (
        <Payment id={decode(id)}/>
    )
}

export default GetIdPayment;
