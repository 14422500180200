let initialState = {
    userConnected : false,
    dateConnected : new Date(),
    user : {
        birthDate : {},
        phones : [],
        addresses : []
    },
    children : []
};


export default function profileReducer(state = initialState, action) {
    switch (action.type) {
        case "ALREADY":
            return {
                ...state,
                userConnected: true
            }
        default:
            return state
    }
}