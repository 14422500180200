import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faBox, faCheck, faSpinner, faTruck} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import carriers from "../../Helpers/carriers.json"

function CardTemplateDelivery(props) {

    const displayTracking = () => {
        let trackings = [];
        for(let i = 0; i < props.tracking.length; i++){
            if(i > 0){
                trackings.push(<hr style={{borderTop: "#777"}}/>);
            }
            trackings.push(
                <div style={{ width: "100%", margin: "10px"}}>
                    <h3>
                        <FontAwesomeIcon
                            icon={
                                props.tracking[i].etatLetter === "LIV" ?
                                    faBox :
                                    props.tracking[i].etatLetter === "ANL" ?
                                        faBan : faTruck
                            }
                            style={{marginRight: "10px"}}
                        />
                        {moment(props.tracking[i].date.date).format("DD/MM/YYYY")} à {moment(props.tracking[i].date.date).format("HH:mm")}
                    </h3>
                    <p style={{margin: 0}}>{props.tracking[i].etat}</p>
                    <p style={{margin: 0}}>{props.tracking[i].localisation}</p>
                    <p style={{marginTop: 0}}>{props.tracking[i].text}</p>
                </div>
            )
        }

        return trackings
    }

    let img = "";

    if(props.carrier){
        img = carriers[props.carrier].img;
    }

    return (

        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: props.bgcolor ? props.bgcolor : "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "column"
            }}
        >
            {props.isDone ?
                <div>
                    <div style={{marginBottom: "20px"}}>
                        <span className={globalStyles.badge} style={{backgroundColor: props.color}}>
                            {props.title}
                        </span>
                    </div>

                    {props.date &&
                        <div>
                            <FontAwesomeIcon icon={faCheck} style={{color: "#6fa939", marginRight: "10px"}}/>
                            <b>Le {moment(props.date).format("DD/MM/YYYY")} à {moment(props.date).format("HH:mm")}</b>
                        </div>
                    }

                    {props.carrier && props.carrierReference &&
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{flex: 2}}>
                                <p style={{marginBottom: "5px"}}><b>Transporteur :</b> {carriers[props.carrier].name}
                                </p>
                                <p style={{marginTop: "5px"}}><b>Numéro de suivi :</b> {props.carrierReference}</p>
                            </div>
                            <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                <img style={{height: "50px"}} src={require(`../../assets/carriers/${img}`).default}
                                     alt={"Logo transporteur"}/>
                            </div>
                        </div>
                    }



                    {displayTracking()}

                </div>
                :
                <div>
                    <div style={{marginBottom: "20px"}}>
                            <span className={globalStyles.badge} style={{backgroundColor: "#ddd"}}>
                                {props.title}
                            </span>
                    </div>

                    <h2 style={{color: "#777"}}>
                        <FontAwesomeIcon icon={faSpinner} style={{marginRight: "10px"}}/>
                        Patience
                    </h2>

                    <p style={{color: "#777"}}>Dès que votre colis sera expédié, vous trouverez ici des informations sur le suivi.</p>
                </div>
            }
        </div>

    );
}

export default CardTemplateDelivery;