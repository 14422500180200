import React from "react"
import LoggedComponent, {api_url, help_url, mapStateToProps} from "../../../Services/ConnexionService";
import {connect} from "react-redux";
import globalStyles from "../../../Styles/Style.module.css";
import ListPoints from "./ListPoints";
import FilAriane from "../../header_footer/FilAriane";
import styles from "./Delivery.module.css"
import MapPoints from "./MapPoints";
import carriers from "../../../Helpers/carriers.json";
import {
    faAt,
    faChild,
    faCity, faGlobeEurope, faIdBadge,
    faMailBulk, faMapMarker, faMapMarkerAlt, faMarker, faPhone, faQuestionCircle,
    faStore, faTimes,
    faTruck
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import formStyles from '../../../Styles/FormStyle.module.css';
import Bandeau from "../../header_footer/Bandeau";
import Modal from 'react-modal';
import ListAddress from "../../Utils/Address/ListAddress";
import AddAddressDelivery from "./AddAdressDelivery";
import titleAriane from "../../../Helpers/TitleAriane.json";
import DisplayPrice from "../../Utils/DisplayPrice";
import {encode} from "../../../Services/ObfuscatorService";
import ReactTooltip from "react-tooltip";

class Delivery extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            listpoints: [],
            countries: [],
            selectedPoint: undefined,
            postalCode: '',
            city: '',
            country: undefined,
            deliveryOptions: undefined,
            order: undefined,
            relais: {
                lastName: "",
                firstName: "",
                email: "",
                phone: ""
            },
            address: {
                id: undefined,
                firstName: undefined,
                lastName: undefined,
                streetAddress: undefined,
                city: undefined,
                postalCode: undefined,
                country: undefined,
                phone: undefined
            },
            come: {
                lastName: "",
                firstName: "",
                email: "",
                phone: ""
            },
            option: undefined,
            errors: {
                phone: false,
                comePhone: false
            }
        }
        this.isOpen = false;
        this.isOpenAdd = false;
        this.loadingDO = true;
        this.loading = true;
        this.loadingPts = true;
        this.loadingAddress = true;
        this.loadingCountries = true;
        this.reloadRelay = false;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getCountries() {
        let request = new Request(api_url + '/client/countries', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({countries: data.countries})
            this.loadingCountries = false;
            this.forceUpdate();
        })
    }

    async getDO(callback) {
        let data = new FormData();
        data.append('json', JSON.stringify({
            countryRelay: this.state.country ?? this.state.address.country ?? 'France',
            countryHome: this.state.address.country ?? 'France'
        }))

        let request = new Request(api_url + '/client/delivery/basket/' + this.props.id, {method:'POST', body: data, credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({deliveryOptions: data.deliveryOptions})
            if(!this.state.order){
                this.setState({order: data.order});
                if(data.order.type === 0){
                    this.setState({
                        come : {
                            firstName: data.order.firstName,
                            lastName: data.order.lastName,
                            phone: data.order.phone,
                            email: data.order.email
                        },
                        option: "come"
                    })
                    this.selected(data.order.relayId);
                } else if(data.order.type === 1){
                    this.setState({
                        relais : {
                            firstName: data.order.firstName,
                            lastName: data.order.lastName,
                            phone: data.order.phone,
                            email: data.order.email
                        },
                        option: "relais"
                    })
                    if(data.order.city && data.order.postalCode){
                        this.setState({
                            city: data.order.city,
                            postalCode: data.order.postalCode,
                            country: data.order.country ?? 'France'
                        })
                    }
                } else if(data.order.type === 2){
                    this.setState({
                        option: "home"
                    })
                }
                this.loadingDO = false;
                this.forceUpdate();
                this.getAddress();
            }
        }).then(() => {
            if(callback) callback()
        })
    }

    getListPoints(init = false) {
        if(Object.keys(this.state.deliveryOptions.relay.carriers).length > 0 && this.state.city && this.state.postalCode && this.state.country){
            let data = new FormData();
            data.append('json', JSON.stringify({carriers: this.state.deliveryOptions.relay.carriers}));
            let request = new Request(api_url + '/client/delivery/listpoints/' + this.state.city + "/" + this.state.postalCode + '/' + this.state.country, {method:'POST', body: data, credentials:'include'});
            fetch(request).then((response) => {
                return response.json()
            }).then((data) => {
                let points = []
                if(data.carrier instanceof Array){
                    var coordsRef = [0, 0];
                    let count = 0;
                    for(let carrier in data.carrier) {
                        let carrier_code = data.carrier[carrier].operator;
                        if(data.carrier[carrier].points.point) {
                            coordsRef[0] += parseFloat(data.carrier[carrier].points.point[0].latitude);
                            coordsRef[1] += parseFloat(data.carrier[carrier].points.point[0].longitude);
                            count++;
                        }
                    }

                    coordsRef[0] /= count;
                    coordsRef[1] /= count;

                    for(let carrier in data.carrier) {
                        let carrier_code = data.carrier[carrier].operator;
                        if(data.carrier[carrier].points.point){
                            for (let i = 0; i < data.carrier[carrier].points.point.length; i++) {
                                points.push(
                                    {
                                        name: data.carrier[carrier].points.point[i].name,
                                        carrier: carriers[carrier_code].img,
                                        address: data.carrier[carrier].points.point[i].address,
                                        zipcode: data.carrier[carrier].points.point[i].zipcode,
                                        city: data.carrier[carrier].points.point[i].city,
                                        country: data.carrier[carrier].points.point[i].country,
                                        schedule: data.carrier[carrier].points.point[i].schedule,
                                        code: data.carrier[carrier].points.point[i].code,
                                        latitude: data.carrier[carrier].points.point[i].latitude,
                                        longitude: data.carrier[carrier].points.point[i].longitude,
                                        selected: false
                                    }
                                )
                            }
                        }
                    }
                } else {
                    let carrier_code = data.carrier.operator;
                    var coordsRef = [data.carrier.points.point[0].latitude, data.carrier.points.point[0].longitude];
                    for (let i = 0; i < data.carrier.points.point.length; i++) {
                        points.push(
                            {
                                name: data.carrier.points.point[i].name,
                                carrier: carriers[carrier_code].img,
                                address: data.carrier.points.point[i].address,
                                zipcode: data.carrier.points.point[i].zipcode,
                                city: data.carrier.points.point[i].city,
                                country: data.carrier.points.point[i].country,
                                schedule: data.carrier.points.point[i].schedule,
                                code: data.carrier.points.point[i].code,
                                latitude: data.carrier.points.point[i].latitude,
                                longitude: data.carrier.points.point[i].longitude,
                                selected: false
                            }
                        )
                    }
                }
                points.sort(function (a, b) {
                    let distA = (a.latitude - coordsRef[0])**2 + (a.longitude - coordsRef[1])**2;
                    let distB = (b.latitude - coordsRef[0])**2 + (b.longitude - coordsRef[1])**2;
                    return distA-distB;
                })
                this.setState({listpoints: points});
                this.loadingPts = false;
                this.reloadRelay = false;
                this.forceUpdate();
                if(init) this.selected(this.state.order.relayId);
            })
        } else {
            this.loadingPts = false;
            this.reloadRelay = false;
            this.forceUpdate();
        }
    }

    setDeliveryAddress = () => {
        for (let i in this.state.user.addresses) {
            let address = this.state.user.addresses[i];
            if(this.state.order.address){
                if(address.id == this.state.order.address){
                    this.setState({
                        address : {
                            id: address.id,
                            firstName: address.firstName,
                            lastName: address.lastName,
                            streetAddress: address.streetAddress,
                            streetAddressMore: address.streetAddressMore,
                            city: address.city,
                            postalCode: address.postalCode,
                            country: address.country,
                            phone: address.phone
                        },
                    });
                    if(!this.state.city) {
                        this.setState({
                            city: address.city,
                            postalCode: address.postalCode,
                            country: address.country
                        })
                    }
                    this.loadingAddress = false;
                    this.forceUpdate();
                }
            } else if (address.type === 0 && address.default) {
                this.setState({
                    address : {
                        id: address.id,
                        firstName: address.firstName,
                        lastName: address.lastName,
                        streetAddress: address.streetAddress,
                        streetAddressMore: address.streetAddressMore,
                        city: address.city,
                        postalCode: address.postalCode,
                        country: address.country,
                        phone: address.phone
                    },
                });
                if(!this.state.city) {
                    this.setState({
                        city: address.city,
                        postalCode: address.postalCode,
                        country: address.country
                    })
                }
                this.loadingAddress = false;
                this.forceUpdate();
            }
        }
    }

    getAddress = () => {
        if(this.loadingAddress){
            this.setDeliveryAddress()
        }
        return [
            this.state.address.firstName + " " + this.state.address.lastName,
            this.state.address.streetAddress,
            this.state.address.streetAddressMore,
            this.state.address.postalCode + " " + this.state.address.city,
            this.state.address.country,
            this.state.address.phone
        ];
    }

    getDetails = (type) => {
        if(
            Object.keys(this.state.deliveryOptions[type].details).length === 1 &&
            this.state.deliveryOptions[type].details[Object.keys(this.state.deliveryOptions[type].details)[0]] === 1
        ){
            return ["Livraison d'ici le "+this.state.deliveryOptions[type].delay]
        }
        let details = [];
        let first = true;
        for(let i = Object.keys(this.state.deliveryOptions[type].details).length-1; i >= 0 ; i--) {
            let price = parseInt(Object.keys(this.state.deliveryOptions[type].details)[i]);
            let duration = parseInt(this.state.deliveryOptions[type].details[price]);
            if(first){
                first = false;
                details.push("Livraison initale d'ici le "+this.state.deliveryOptions[type].delay+" : ")
                details.push(<span style={{whiteSpace: "nowrap"}}>1 x <DisplayPrice price={price/100}/></span>)
                details.push(<br/>)
                if(parseInt(duration) > 1){
                    details.push("Pour les " + (duration - 1) + " prochains mois : ")
                    details.push(<span style={{whiteSpace: "nowrap"}}>{(duration - 1)} x <DisplayPrice price={price/100}/></span>)
                    details.push(<br/>)
                }
            } else {
                details.push("Pour les " + duration + " prochains mois : " + duration + " x ")
                details.push(<DisplayPrice price={price/100}/>)
                details.push(<br/>)
            }

        }
        return details
    }


    selected = (code) => {
        let newListpoint = this.state.listpoints;
        let newSelectedPoint = {};
        for (let i in newListpoint) {
            newListpoint[i].selected = (newListpoint[i].code === code);
            if(newListpoint[i].code === code){
                newSelectedPoint = newListpoint[i]
            }
        }
        this.setState({
            listpoints: newListpoint,
            selectedPoint: newSelectedPoint
        });
    }

    openModal = () => {
        this.isOpen = true;
        this.forceUpdate();
    }

    closeModal = () => {
        this.isOpen = false;
        this.forceUpdate();
    }

    openModalAdd = () => {
        this.isOpenAdd = true;
        this.forceUpdate();
    }

    closeModalAdd = () => {
        this.isOpenAdd = false;
        this.forceUpdate();
    }

    choose = () => {
        let data = new FormData();

        if(this.state.option === "relais"){
            data.append('json', JSON.stringify({
                type: 1,
                relayPoint: this.state.selectedPoint.code,
                relayName: this.state.selectedPoint.name,
                address: this.state.selectedPoint.address,
                postalCode: this.state.selectedPoint.zipcode,
                city: this.state.selectedPoint.city,
                country: this.state.selectedPoint.country,
                firstName: this.state.relais.firstName,
                lastName: this.state.relais.lastName,
                email: this.state.relais.email,
                phone: this.state.relais.phone
            }));
        } else if(this.state.option === "home"){
            data.append('json', JSON.stringify({address: this.state.address, type: 2}));
        } else {
            data.append('json', JSON.stringify({
                type: 0,
                firstName: this.state.come.firstName,
                lastName: this.state.come.lastName,
                email: this.state.come.email,
                phone: this.state.come.phone
            }));
        }

        let request = new Request(api_url + '/client/delivery/choose/' + this.props.id, {method:'POST', body: data, credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            if(data["choose"]){
                this.setState({shouldNavigate: true, navigateTo: '/payment/' + encode(this.props.id)});
            }
        })
    }

    setAddress = (id, firstName, lastName, streetAddress, streetAddressMore, postalCode, city, country, phone) => {
        this.setState({
            address : {
                id: id,
                firstName: firstName,
                lastName: lastName,
                streetAddress: streetAddress,
                streetAddressMore: streetAddressMore,
                postalCode: postalCode,
                city: city,
                country: country,
                phone: phone
            },
        }, () => this.getDO())
        this.closeModal();
    }

    getInfos = () => {
        if(!this.state.relais.lastName){
            this.setState({
                relais: {
                    lastName: this.state.user.lastName,
                    firstName: this.state.user.firstName,
                    email: this.state.user.email,
                    phone: this.state.user.addresses.length > 0 ? this.state.user.addresses[0].phone : undefined
                },
            })
        }

        if(!this.state.come.lastName){
            this.setState({
                come: {
                    lastName: this.state.user.lastName,
                    firstName: this.state.user.firstName,
                    email: this.state.user.email,
                    phone: this.state.user.addresses.length > 0 ? this.state.user.addresses[0].phone : undefined
                },
            })
        }
    }

    formValid = () => {
        if(this.state.option === "relais"){
            return this.state.selectedPoint &&
                this.state.relais.firstName && this.state.relais.firstName.length > 0 &&
                this.state.relais.lastName && this.state.relais.lastName.length > 0 &&
                this.state.relais.email && this.state.relais.email.length > 0 &&
                !this.state.errors.phone
        }

        if(this.state.option === "home") return this.state.address.id != null

        if(this.state.option === "come"){
            return this.state.come.firstName && this.state.come.firstName.length > 0 &&
                this.state.come.lastName && this.state.come.lastName.length > 0 &&
                this.state.come.email && this.state.come.email.length > 0 &&
                !this.state.errors.comePhone
        }

        return false;

    }

    renderChild () {
        if(this.loadingCountries){
            this.getCountries()
        }

        if(this.loadingDO){
            this.getDO();
        }else if(!this.loadingDO && this.loadingPts && !this.loadingAddress){
            this.getListPoints(true)
            if(!this.state.relais.lastName) this.getInfos()
        } else if(!this.loadingDO && !this.loadingPts) {
            return (
                <div>
                    <Bandeau title={titleAriane["delivery"]["title"]}/>
                    <FilAriane
                        links={[
                            [
                                titleAriane["delivery"]["ariane"][0],
                                "/basket"
                            ],
                            [
                                titleAriane["delivery"]["ariane"][1],
                                ""
                            ]
                        ]}
                    />
                    <div className={globalStyles.globalContainer} style={{flexDirection: "column", alignItems: "unset", padding: window.innerWidth > 960 ? "unset" : "10px"}}>

                        <div style={{flexDirection: window.innerWidth > 1020 ? "row" : "column", display: "flex"}}>

                            {this.state.deliveryOptions.relay.total && this.state.deliveryOptions.home.total &&
                                <div className={`${globalStyles.card} ${globalStyles.cardMore}`} style={{
                                    marginRight: window.innerWidth > 1020 ? "10px" : 0,
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    background: this.state.option !== "come" ? "white" : "rgb(243, 246, 248)",
                                    height: "unset"
                                }}>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div style={{flex: 3}}>
                                            <h2 style={{margin: "0 0 15px 0"}}>
                                                <input
                                                    type={"radio"}
                                                    style={{height: "20px", width: "20px", marginRight: "20px"}}
                                                    value={"come"}
                                                    name={"delivery"}
                                                    checked={this.state.option === "come"}
                                                    onChange={(event) => this.setState({option: event.target.value})}
                                                />
                                                <FontAwesomeIcon icon={faMapMarkerAlt} style={{marginRight: "15px"}}/>
                                                Retrait
                                            </h2>
                                            <p style={{marginTop: "5px", marginBottom: "0", color: "#555"}}>
                                                Retrait sur rendez-vous
                                            </p>
                                            <div style={{textAlign: "right", marginTop: "15px"}}>
                                                <span className={globalStyles.badge}
                                                      style={{backgroundColor: "#6fa939"}}>
                                                    Gratuit
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.deliveryOptions.relay.total &&
                                <div className={`${globalStyles.card} ${globalStyles.cardMore}`} style={{
                                    marginRight: window.innerWidth > 1020 ? "10px" : 0,
                                    marginLeft: window.innerWidth > 1020 ? "10px" : 0,
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    background: this.state.option !== "relais" ? "white" : "rgb(243, 246, 248)",
                                    height: "unset"
                                }}>
                                    <div>
                                        <h2 style={{margin: "0 0 15px 0"}}>
                                            <input
                                                type={"radio"}
                                                style={{height: "20px", width: "20px", marginRight: "20px"}}
                                                value={"relais"}
                                                name={"delivery"}
                                                checked={this.state.option === "relais"}
                                                onChange={(event) => this.setState({option: event.target.value})}
                                            />
                                            <FontAwesomeIcon icon={faStore} style={{marginRight: "15px"}}/>
                                            En point relais
                                        </h2>
                                        <p style={{marginTop: "5px", marginBottom: "0", color: "#555"}}>
                                            {this.getDetails("relay")}
                                        </p>
                                        {Object.keys(this.state.deliveryOptions.relay.details).length === 1 && this.state.deliveryOptions.relay.details[Object.keys(this.state.deliveryOptions.relay.details)[0]] === 1 &&
                                            <div style={{textAlign: "right"}}>
                                                <h2 style={{margin: "0 0 5px 0"}}>
                                                    <DisplayPrice price={this.state.deliveryOptions.relay.total / 100}/>
                                                </h2>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {this.state.deliveryOptions.home.total &&
                                <div className={`${globalStyles.card} ${globalStyles.cardMore}`} style={{marginLeft: window.innerWidth > 1020 ? "10px" : 0, marginTop: "10px", marginBottom: "10px", background: this.state.option !== "home" ? "white" : "rgb(243, 246, 248)", height: "unset"}}>
                                    <div>
                                        <h2 style={{margin: "0 0 15px 0"}}>
                                            <input
                                                type={"radio"}
                                                style={{height: "20px", width: "20px", marginRight: "20px"}}
                                                value={"home"}
                                                name={"delivery"}
                                                checked={this.state.option === "home"}
                                                onChange={(event) => this.setState({option: event.target.value})}
                                            />
                                            <FontAwesomeIcon icon={faTruck} style={{marginRight: "15px"}}/>
                                            A domicile
                                        </h2>
                                        <p style={{marginTop: "5px", marginBottom: "0", color: "#555"}}>
                                            {this.getDetails("home")}
                                        </p>
                                        {Object.keys(this.state.deliveryOptions.home.details).length === 1 && this.state.deliveryOptions.home.details[Object.keys(this.state.deliveryOptions.home.details)[0]] === 1 &&
                                            <div style={{flex: 1, textAlign: "right"}}>
                                                <h2 style={{margin: "0 0 5px 0"}}>
                                                    <DisplayPrice price={this.state.deliveryOptions.home.total / 100}/>
                                                </h2>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        {this.state.deliveryOptions.home.total && this.state.option === "home" &&
                            <div>
                                <div className={styles.cardContainer}>
                                    <div
                                        className={globalStyles.card}
                                        style={{
                                            padding: "20px 30px 30px",
                                            flex: 1,
                                            backgroundColor: "#f3f6f8",
                                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                            borderRadius: "5px",
                                            display: "flex",
                                            height: "100%",
                                            boxSizing: "border-box",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <ListAddress
                                            title={"Vos adresses"}
                                            desktopNumber={3}
                                            mobileNumber={1}
                                            type={0}
                                            state={this.state}
                                            fetchJsonOrError={this.fetchJsonOrError}
                                            add={["btn", this.openModalAdd.bind(this)]}
                                            choose={true}
                                            chooseFtn={this.setAddress.bind(this)}
                                        />
                                    </div>
                                </div>

                            </div>
                        }


                        <Modal
                            isOpen={this.isOpenAdd}
                            contentLabel="Adresse de livraison"
                            bodyOpenClassName={"noScroll"}
                            style={{
                                content : {
                                    top: '50%',
                                    left: '50%',
                                    right: 'auto',
                                    bottom: 'auto',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 500,
                                    width: "70%",
                                    padding: "40px",
                                    paddingTop: "20px",
                                    maxHeight: (window.innerHeight - 180) + "px",
                                    borderRadius: "15px"
                                },
                                overlay : {
                                    zIndex: 1500,
                                    backgroundColor: "rgba(0, 0, 0, 0.8)"
                                }
                            }}
                        >
                            <AddAddressDelivery
                                fetchJsonOrError={this.fetchJsonOrError}
                                countries={this.state.countries}
                            />
                            <button
                                style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "20px",
                                    background: "none",
                                    border: "none",
                                    fontSize: "20px",
                                    cursor: "pointer"
                                }}
                                onClick={() => this.closeModalAdd()}
                            >
                                <FontAwesomeIcon icon={faTimes}/>
                            </button>
                        </Modal>

                        {this.state.deliveryOptions.relay.total && this.state.option === "relais" &&
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <div className={styles.cardContainer}>
                                    <div
                                        className={globalStyles.card}
                                        style={{
                                            padding: "30px",
                                            flex: 1,
                                            backgroundColor: "#f3f6f8",
                                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                            borderRadius: "5px",
                                            display: "flex",
                                            height: "100%",
                                            boxSizing: "border-box",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <div style={{flex: 5}}>
                                            <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                                                <div className={formStyles.inputLine} style={{marginBottom: "10px"}}>
                                                    <div className={formStyles.inputGroup} style={{marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined}}>
                                                        <label>
                                                            Code postal*
                                                        </label>
                                                        <div className={formStyles.inputField}>
                                                            <div className={formStyles.inputIcon}>
                                                                <FontAwesomeIcon icon={faMailBulk}/>
                                                            </div>
                                                            <input type="text" required className={formStyles.input}
                                                                   placeholder="00000" value={this.state.postalCode}
                                                                   onChange={(event) => this.setState({postalCode: event.target.value})}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={formStyles.inputGroup}
                                                        style={{
                                                            marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                            marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                    }}
                                                    >
                                                        <label>Ville*</label>
                                                        <div className={formStyles.inputField}>
                                                            <div className={formStyles.inputIcon}>
                                                                <FontAwesomeIcon icon={faCity}/>
                                                            </div>
                                                            <input type="text" required className={formStyles.input}
                                                                   placeholder="Ville"
                                                                   value={this.state.city}
                                                                   onChange={(event) => this.setState({city: event.target.value})}/>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={formStyles.inputGroup}
                                                        style={{
                                                            marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                            marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                        }}
                                                    >
                                                        <label>Pays*</label>
                                                        <div className={formStyles.inputField}>
                                                            <div className={formStyles.inputIcon}>
                                                                <FontAwesomeIcon icon={faGlobeEurope}/>
                                                            </div>
                                                            <select value={this.state.country} className={formStyles.input} onChange={(event) => {
                                                                this.setState({country: event.target.value})
                                                            }}>
                                                                {
                                                                    this.state.countries?.map(country =>
                                                                        <option
                                                                            value={country.label}>{country.label}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "flex-end",
                                                        justifyContent: "flex-end",
                                                        paddingBottom: "10px"
                                                    }}>
                                                        <button
                                                            className={this.state.postalCode && this.state.city && this.state.country ? globalStyles.defaultBtn : formStyles.disabledBtn}
                                                            disabled={!(this.state.postalCode && this.state.city && this.state.country)}
                                                            style={{padding: "10px 20px"}}
                                                            onClick={() => {
                                                                this.getDO(() => {
                                                                    this.reloadRelay = true;
                                                                    this.forceUpdate();
                                                                    this.getListPoints();
                                                                })
                                                            }}>
                                                            Chercher
                                                        </button>
                                                    </div>
                                                </div>

                                                {Object.keys(this.state.listpoints).length > 0 &&
                                                    (!this.reloadRelay &&
                                                        <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                                                            <ListPoints
                                                                listpoints={this.state.listpoints}
                                                                select={this.selected.bind(this)}
                                                            />
                                                            <div
                                                                style={{
                                                                    flex: window.innerWidth > 1020 ? 1 : undefined,
                                                                    height: "500px",
                                                                    marginTop: window.innerWidth < 1020 ? "10px" : undefined,
                                                                }}>
                                                                <MapPoints listpoints={this.state.listpoints}
                                                                           selected={this.selected.bind(this)}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {Object.keys(this.state.listpoints).length === 0 &&
                                                    <div>
                                                        Aucun point relais disponible
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.cardContainer}>
                                    <div
                                        className={globalStyles.card}
                                        style={{
                                            padding: "30px",
                                            flex: 1,
                                            backgroundColor: "#f3f6f8",
                                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                            borderRadius: "5px",
                                            display: "flex",
                                            height: "100%",
                                            boxSizing: "border-box",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <div>
                                            <div style={{flexDirection: "column", marginTop: "15px"}}>
                                                <div className={formStyles.inputLine}>
                                                    <div className={formStyles.inputGroup} style={{marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined}}>
                                                        <label>
                                                            Nom du destinataire*
                                                        </label>
                                                        <div className={formStyles.inputField}>
                                                            <div className={formStyles.inputIcon}>
                                                                <FontAwesomeIcon icon={faIdBadge}/>
                                                            </div>
                                                            <input type="text" required className={formStyles.input}
                                                                   placeholder="Nom" value={this.state.relais.lastName.toUpperCase()}
                                                                   onChange={(event) =>
                                                                       this.setState({relais: {...this.state.relais, lastName: event.target.value.toUpperCase()}})
                                                                   }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={formStyles.inputGroup}
                                                        style={{
                                                            marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                            marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                        }}
                                                    >
                                                        <label>Prénom du destinataire*</label>
                                                        <div className={formStyles.inputField}>
                                                            <div className={formStyles.inputIcon}>
                                                                <FontAwesomeIcon icon={faChild}/>
                                                            </div>
                                                            <input type="text" required className={formStyles.input}
                                                                   placeholder="Prénom"
                                                                   value={this.state.relais.firstName}
                                                                   onChange={(event) =>
                                                                       this.setState({relais: {...this.state.relais, firstName: event.target.value}})
                                                                   }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div style={{flexDirection: "column", marginBottom: "25px"}}>
                                                    <div className={formStyles.inputLine} style={{marginBottom: "10px"}}>
                                                        <div className={formStyles.inputGroup} style={{marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined}}>
                                                            <label>
                                                                Adresse e-mail du destinataire*
                                                                <FontAwesomeIcon
                                                                    className={formStyles.iconHelp}
                                                                    icon={faQuestionCircle}
                                                                    data-tip="Cela permettera de faciliter le suivi et la livraison."
                                                                    tabIndex={-1}
                                                                />
                                                            </label>
                                                            <div className={formStyles.inputField}>
                                                                <div className={formStyles.inputIcon}>
                                                                    <FontAwesomeIcon icon={faAt}/>
                                                                </div>
                                                                <input type="text" required className={formStyles.input}
                                                                       placeholder="Adresse e-mail" value={this.state.relais.email}
                                                                       onChange={(event) =>
                                                                           this.setState({relais: {...this.state.relais, email: event.target.value}})
                                                                       }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={formStyles.inputGroup}
                                                            style={{
                                                                marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                                marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                            }}
                                                        >
                                                            <label>
                                                                Numéro de téléphone
                                                                <FontAwesomeIcon
                                                                    className={formStyles.iconHelp}
                                                                    icon={faQuestionCircle}
                                                                    data-tip="Cela permettera de faciliter le suivi et la livraison."
                                                                    tabIndex={-1}
                                                                />
                                                            </label>
                                                            <div className={formStyles.inputField}>
                                                                <div className={formStyles.inputIcon}>
                                                                    <FontAwesomeIcon icon={faPhone}/>
                                                                </div>
                                                                <input type="text" required className={formStyles.input}
                                                                       placeholder="0600000000"
                                                                       pattern={
                                                                           this.state.countries?.map(country =>
                                                                               country.phoneRegex
                                                                           )?.join('|')
                                                                       }
                                                                       value={this.state.relais.phone}
                                                                       onChange={(event) =>
                                                                           this.setState({relais: {...this.state.relais, phone: event.target.value}, errors: {...this.state.errors, phone: (event.target.validity.patternMismatch && event.target.value !== '')}})
                                                                       }
                                                                />
                                                            </div>
                                                            {this.state.errors.phone &&
                                                                <span className={formStyles.textDanger}>Le numéro de téléphone doit être valide.</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReactTooltip multiline={true}/>
                            </div>
                        }

                        {this.state.option === "come" &&
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <div className={styles.cardContainer}>
                                    <div
                                        className={globalStyles.card}
                                        style={{
                                            padding: "20px 30px 30px",
                                            flex: 1,
                                            backgroundColor: "#f3f6f8",
                                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                            borderRadius: "5px",
                                            display: "flex",
                                            height: "100%",
                                            boxSizing: "border-box",
                                            flexDirection: window.innerWidth > 1020 ? "row" : "column",
                                        }}
                                    >
                                        <div style={{flex: 1, marginRight: window.innerWidth > 1020 ? "10px" : undefined}} dangerouslySetInnerHTML={{__html : this.state.deliveryOptions.come[0]}}/>
                                        <div style={{flex: 1, marginLeft: window.innerWidth > 1020 ? "10px" : undefined}} dangerouslySetInnerHTML={{__html : this.state.deliveryOptions.come[1]}}/>
                                    </div>
                                </div>

                                <div className={styles.cardContainer}>
                                    <div
                                        className={globalStyles.card}
                                        style={{
                                            padding: "30px",
                                            flex: 1,
                                            backgroundColor: "#f3f6f8",
                                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                            borderRadius: "5px",
                                            display: "flex",
                                            height: "100%",
                                            boxSizing: "border-box",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <div>
                                            <div style={{flexDirection: "column", marginTop: "15px"}}>
                                                <div className={formStyles.inputLine}>
                                                    <div className={formStyles.inputGroup} style={{marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined}}>
                                                        <label>
                                                            Nom du destinataire*
                                                        </label>
                                                        <div className={formStyles.inputField}>
                                                            <div className={formStyles.inputIcon}>
                                                                <FontAwesomeIcon icon={faIdBadge}/>
                                                            </div>
                                                            <input type="text" required className={formStyles.input}
                                                                   placeholder="Nom" value={this.state.come.lastName.toUpperCase()}
                                                                   onChange={(event) =>
                                                                       this.setState({come: {...this.state.come, lastName: event.target.value.toUpperCase()}})
                                                                   }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={formStyles.inputGroup}
                                                        style={{
                                                            marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                            marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                        }}
                                                    >
                                                        <label>Prénom du destinataire*</label>
                                                        <div className={formStyles.inputField}>
                                                            <div className={formStyles.inputIcon}>
                                                                <FontAwesomeIcon icon={faChild}/>
                                                            </div>
                                                            <input type="text" required className={formStyles.input}
                                                                   placeholder="Prénom"
                                                                   value={this.state.come.firstName}
                                                                   onChange={(event) =>
                                                                       this.setState({come: {...this.state.come, firstName: event.target.value}})
                                                                   }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div style={{flexDirection: "column", marginBottom: "25px"}}>
                                                <div className={formStyles.inputLine} style={{marginBottom: "10px"}}>
                                                    <div className={formStyles.inputGroup} style={{marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined}}>
                                                        <label>
                                                            Adresse e-mail du destinataire*
                                                            <FontAwesomeIcon
                                                                className={formStyles.iconHelp}
                                                                icon={faQuestionCircle}
                                                                data-tip="Cela permettera de faciliter le suivi et la livraison."
                                                                tabIndex={-1}
                                                            />
                                                        </label>
                                                        <div className={formStyles.inputField}>
                                                            <div className={formStyles.inputIcon}>
                                                                <FontAwesomeIcon icon={faAt}/>
                                                            </div>
                                                            <input type="text" required className={formStyles.input}
                                                                   placeholder="Adresse e-mail" value={this.state.come.email}
                                                                   onChange={(event) =>
                                                                       this.setState({come: {...this.state.come, email: event.target.value}})
                                                                   }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={formStyles.inputGroup}
                                                        style={{
                                                            marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                            marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                        }}
                                                    >
                                                        <label>
                                                            Numéro de téléphone
                                                            <FontAwesomeIcon
                                                                className={formStyles.iconHelp}
                                                                icon={faQuestionCircle}
                                                                data-tip="Cela permettera de faciliter le suivi et la livraison."
                                                                tabIndex={-1}
                                                            />
                                                        </label>
                                                        <div className={formStyles.inputField}>
                                                            <div className={formStyles.inputIcon}>
                                                                <FontAwesomeIcon icon={faPhone}/>
                                                            </div>
                                                            <input type="text" required className={formStyles.input}
                                                                   placeholder="0600000000"
                                                                   pattern={
                                                                       this.state.countries?.map(country =>
                                                                           country.phoneRegex
                                                                       )?.join('|')
                                                                   }
                                                                   onChange={(event) =>
                                                                       this.setState({come: {...this.state.come, phone: event.target.value}, errors: {...this.state.errors, comePhone: (event.target.validity.patternMismatch && event.target.value !== '')}})
                                                                   }
                                                            />
                                                        </div>
                                                        {this.state.errors.comePhone &&
                                                            <span className={formStyles.textDanger}>Le numéro de téléphone doit être valide.</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReactTooltip multiline={true}/>
                            </div>
                        }

                        {(this.state.deliveryOptions.relay.total || this.state.deliveryOptions.home.total) &&
                            <div className={formStyles.btnContainer} style={{marginTop: "20px"}}>
                                <button
                                    onClick={() => this.choose()}
                                    className={
                                        this.formValid() ?
                                            globalStyles.defaultBtn :
                                            formStyles.disabledBtn
                                    }
                                    disabled={!this.formValid()}
                                >
                                    Continuer
                                </button>
                            </div>
                        }


                        {!this.state.deliveryOptions.relay.total && !this.state.deliveryOptions.home.total &&
                            <div className={styles.cardContainer}>
                                <div
                                    className={globalStyles.card}
                                    style={{
                                        padding: "30px",
                                        flex: 1,
                                        backgroundColor: "#f3f6f8",
                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                        borderRadius: "5px",
                                        display: "flex",
                                        height: "100%",
                                        boxSizing: "border-box",
                                        flexDirection: "column"
                                    }}
                                >
                                    <div style={{flex: 5}}>
                                        <h2 style={{margin: "0 0 15px 0"}}>
                                            <FontAwesomeIcon icon={faTimes} style={{marginRight: "15px"}}/>
                                            Oups ...
                                        </h2>

                                        Nous n'avons pas trouvé d'option de livraison pour votre commande. <br/>
                                        Votre demande est particulière. Merci de nous contacter à l'adresse e-mail : <a href={"mailto:support@atorika.fr"} className={globalStyles.defaultLink}>support@atorika.fr</a>.
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            );
        }
        return (
            <div style={{height: "1000000px", backgroundColor:"#FFF"}}/>
        )
    }
}

export default connect(mapStateToProps)(Delivery);
