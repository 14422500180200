import React from "react";
import style from "../../Styles/Style.module.css"

function ThemeBadge(props) {


    return (
        <span
            style={{
                backgroundColor: props.bgColor,
                display: 'inline-block',
                overflow: 'hidden',
                top: 0,
                position: 'relative',
                paddingRight: "25px",
                fontSize: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
                ...props.style
            }}
            className={style.badge}
        >
            {props.theme}
            <span
                style={{
                    content: "",
                    position: 'absolute',
                    top: 0,
                    right: '-42px',
                    width: '50px',
                    bottom: 0,
                    zIndex: 1,
                    transform: 'skewX(-10deg)',
                    background: props.baseColor,
                    borderLeft: '6px double ' + props.bgColor,
                    height: "100%"
                }}
            >
            </span>
        </span>
    );
}

export default ThemeBadge;