import React from 'react';
import fr from 'date-fns/locale/fr';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faChild,
    faCity,
    faEnvelope,
    faFemale,
    faGlobeEurope,
    faIdBadge,
    faMailBulk,
    faMale,
    faMapMarkerAlt,
    faPhone,
    faQuestionCircle,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import {api_url} from "../../../Services/ConnexionService";
import globalStyles from "../../../Styles/Style.module.css";
import formStyles from '../../../Styles/FormStyle.module.css';

class AddAddressDelivery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            lastName: '',
            firstName: '',
            name: '',
            type: 2,
            address: '',
            address2: '',
            postalCode: '',
            city: '',
            country: 'France',
            phone: '',
            errors : {
                phone: false,
            }
        };
    }


    create = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({...this.state}));
        let request = new Request(api_url + '/client/user/address/new', {method:'POST', body: data, credentials:'include'});
        this.props.fetchJsonOrError(request, (data) => {
            if(data["added"]){
                window.location.reload()
            }
        })

    }


    render() {

        const formValid = () => {
            let infoShipping = (this.state.lastName.length > 0) && (this.state.firstName.length > 0) && (this.state.address.length > 0) && (this.state.city.length > 0) && (this.state.country.length > 0) && (!this.state.errors.phone);
            return infoShipping;
        }

        return (
            <div>

                <h1>Ajouter une adresse</h1>

                <div style={{flex: 1}}>
                    <div className={formStyles.inputLine}>
                        <div style={{margin: window.innerWidth ? "10px 20px" : "10px 0", flex: 1}}>

                            Type* :

                            <div style={{flex: 1, display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                                <div className={formStyles.inputGroup}>
                                    <input type="radio" name="type" value="delivery" style={{marginRight: "10px"}} onChange={() => {this.setState({type: 0})}}/>
                                    <label>
                                        Livraison
                                    </label>
                                </div>

                                <div className={formStyles.inputGroup}>
                                    <input type="radio" name="type" value="biling" style={{marginRight: "10px"}} onChange={() => {this.setState({type: 1})}}/>
                                    <label>
                                        Facturation
                                    </label>
                                </div>

                                <div className={formStyles.inputGroup}>
                                    <input type="radio" name="type" value="both" style={{marginRight: "10px"}} onChange={() => {this.setState({type: 2})}} checked={this.state.type === 2}/>
                                    <label>
                                        Les deux
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={formStyles.inputLine}>
                        <div className={formStyles.inputGroup} style={{margin: window.innerWidth > 1020 ? "10px 20px" : "10px 0"}}>
                            <label>
                                Nom de l'adresse*
                                <FontAwesomeIcon
                                    className={formStyles.iconHelp}
                                    icon={faQuestionCircle}
                                    tabIndex={-1}
                                    data-tip="Afin de mieux vous y retrouver, vous pouvez donner un nom à cette adresse."
                                />
                            </label>
                            <div className={formStyles.inputField}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                </div>
                                <input type="text" required className={formStyles.input}
                                       placeholder="Nom de l'adresse" value={this.state.name}
                                       onChange={(event) => this.setState({name: event.target.value})}
                                />

                            </div>
                        </div>
                    </div>

                    <div className={formStyles.inputLine}>
                        <div className={formStyles.inputGroup} style={{margin: window.innerWidth > 1020 ? "10px 20px" : "10px 0"}}>
                            <label>
                                Nom*
                                <FontAwesomeIcon
                                    className={formStyles.iconHelp}
                                    icon={faQuestionCircle}
                                    data-tip="A qui sera adressé le colis ?"
                                    tabIndex={-1}
                                />
                            </label>
                            <div className={formStyles.inputField}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faIdBadge}/>
                                </div>
                                <input type="text" required className={formStyles.input}
                                       placeholder="Nom du destinataire" value={this.state.lastName}
                                       onChange={(event) => this.setState({lastName: event.target.value.toUpperCase()})}/>

                            </div>
                        </div>

                        <div className={formStyles.inputGroup} style={{margin: window.innerWidth > 1020 ? "10px 20px" : "10px 0"}}>
                            <label>
                                Prénom*
                                <FontAwesomeIcon
                                    className={formStyles.iconHelp}
                                    icon={faQuestionCircle}
                                    data-tip="A qui sera adressé le colis ?"
                                    tabIndex={-1}
                                />
                            </label>
                            <div className={formStyles.inputField}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faChild}/>
                                </div>
                                <input type="text" required className={formStyles.input}
                                       placeholder="Prénom du destinataire" value={this.state.firstName}
                                       onChange={(event) => this.setState({firstName: event.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className={formStyles.inputLine}>
                        <div className={formStyles.inputGroup} style={{margin: window.innerWidth > 1020 ? "10px 20px" : "10px 0"}}>
                            <label>
                                Adresse*
                                <FontAwesomeIcon
                                    className={formStyles.iconHelp}
                                    icon={faQuestionCircle}
                                    tabIndex={-1}
                                    data-tip={this.state.type === 0 || this.state.type === 2 ? "Où sera livré le colis ?" : "A quelle adresse sera adressée la facture ?"}
                                />
                            </label>
                            <div className={formStyles.inputField}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                </div>
                                <input type="text" required className={formStyles.input}
                                       placeholder="Adresse" value={this.state.address}
                                       onChange={(event) => this.setState({address: event.target.value})}/>
                            </div>
                        </div>

                        {this.state.type !== 1 &&
                            <div className={formStyles.inputGroup} style={{margin: window.innerWidth > 1020 ? "10px 20px" : "10px 0"}}>
                                <label>
                                    Complément d'adresse
                                    <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle}
                                                     tabIndex={-1}
                                                     data-tip="Cela permettera de faciliter la livraison."/>
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Étage, Bâtiment .." value={this.state.address2}
                                           onChange={(event) => this.setState({address2: event.target.value})}/>
                                </div>
                            </div>
                        }
                    </div>

                    <div className={formStyles.inputLine}>
                        <div className={formStyles.inputGroup} style={{margin: window.innerWidth > 1020 ? "10px 20px" : "10px 0"}}>
                            <label>
                                Code postal
                            </label>
                            <div className={formStyles.inputField}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faMailBulk}/>
                                </div>
                                <input type="text" maxLength={5} required className={formStyles.input}
                                       placeholder="00000" value={this.state.postalCode}
                                       onChange={(event) => this.setState({postalCode: event.target.value})}/>
                            </div>
                        </div>

                        <div className={formStyles.inputGroup} style={{margin: window.innerWidth > 1020 ? "10px 20px" : "10px 0"}}>
                            <label>Ville*</label>
                            <div className={formStyles.inputField}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faCity}/>
                                </div>
                                <input type="text" required className={formStyles.input} placeholder="Ville"
                                       value={this.state.city}
                                       onChange={(event) => this.setState({city: event.target.value})}/>
                            </div>
                        </div>
                    </div>

                    <div className={formStyles.inputLine}>
                        <div className={formStyles.inputGroup} style={{margin: window.innerWidth > 1020 ? "10px 20px" : "10px 0"}}>
                            <label>
                                Pays
                                {this.state.type !== 1 &&
                                    <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle}
                                                     tabIndex={-1}
                                                     data-tip={"Nous ne livrons que dans les pays suivants : " + this.props.countries?.map(country => country.label)?.join(',')}/>
                                }
                            </label>
                            <div className={formStyles.inputField}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faGlobeEurope}/>
                                </div>

                                {this.state.type === 1 ?
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Pays" value={this.state.country}
                                           onChange={(event) => this.setState({country: event.target.value})}/>
                                    :
                                    <select value={this.state.country} className={formStyles.input} onChange={(event) => this.setState({country: event.target.value})}>
                                        {
                                            this.props.countries?.map(country =>
                                                <option
                                                    value={country.label}>{country.label}</option>
                                            )
                                        }
                                    </select>
                                }
                            </div>
                        </div>

                        <div className={formStyles.inputGroup} style={{margin: window.innerWidth > 1020 ? "10px 20px" : "10px 0"}}>
                            {this.state.type !== 1 &&
                                <div>
                                    <label>
                                        Numéro de téléphone
                                        <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle}
                                                         tabIndex={-1}
                                                         data-tip="Cela permettera de faciliter la livraison."/>
                                    </label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon}>
                                            <FontAwesomeIcon icon={faPhone}/>
                                        </div>
                                        <input
                                            type="tel"
                                            required
                                            className={formStyles.input}
                                            placeholder="06000000"
                                            value={this.state.phone}
                                            pattern={
                                                this.props.countries?.map(country =>
                                                    country.phoneRegex
                                                )?.join('|')
                                            }
                                            onChange={(event) => this.setState({phone: event.target.value, errors: {...this.state.errors, phone: (event.target.validity.patternMismatch && event.target.value !== '')}})}
                                        />
                                    </div>

                                    {this.state.errors.phone &&
                                        <span className={formStyles.textDanger}>Le numéro de téléphone doit être valide.</span>
                                    }
                                </div>
                            }

                        </div>
                    </div>

                    <div className={formStyles.btnContainer}>
                        <button type="button" className={formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn} onClick={() =>{this.create()}} disabled={!formValid()}>
                            Enregistrer
                        </button>
                    </div>

                </div>
                <ReactTooltip multiline={true}/>
            </div>
        );
    };

}

export default AddAddressDelivery;
