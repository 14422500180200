import CryptoJS from 'crypto-js'

const password = "RANDOM_ATORIKA_A87XQXGVN94UJ45R7379"

export function encode(id) {
    return btoa(CryptoJS.DES.encrypt(id.toString(), password).toString())
}

export function decode(enc) {
    let bytes = CryptoJS.DES.decrypt(atob(enc), password);
    return parseInt(bytes.toString(CryptoJS.enc.Utf8))
}
