import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import {
    faUser,
    faTruck,
    faEuroSign,
    faMale,
    faFemale,
    faUserPlus,
    faPlus,
    faCheck
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {api_url} from "../../Services/ConnexionService";
import ThemeBadge from "../Utils/ThemeBadge";

function CardTemplateBox(props) {

    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: props.color ? props.color : "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "column"
            }}
        >
            <div style={{flex: 5}}>
                <h3 style={{margin: "0 0 15px 0"}}>
                    {props.box.name}
                </h3>

                <div style={{marginBottom: "20px"}}>

                    <ThemeBadge
                        theme={props.box.theme}
                        bgColor={props.box.themeColor}
                        baseColor={props.box.themeBaseColor}
                    />

                    { props.box.secondaryTheme &&
                        <ThemeBadge
                            theme={props.box.secondaryTheme}
                            bgColor={props.box.secondaryThemeColor}
                            baseColor={props.box.secondaryThemeBaseColor}
                            style={{marginLeft: "5px"}}
                        />
                    }
                </div>

                <div style={{flexDirection: window.innerWidth > 1020 ? "row" : "column", display: "flex"}}>
                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img
                            src={api_url + '/uploads/article/' + props.box.media[0]}
                            style={{maxWidth: "100%", maxHeight: "100%", borderRadius: "8px", marginRight: "10px"}}
                        />
                    </div>
                    <div style={{flex: 2, display: "flex", marginLeft: window.innerWidth > 960 ? "5px" : 0, flexDirection: "column"}}>

                        <div style={{margin: "10px 0 5px"}}>
                            {props.box.description}
                        </div>

                        <div style={{margin: "5px 0 10px"}}>
                            Age : {props.box.age} +
                        </div>

                        <div style={{margin: "5px 0"}}>
                            {props.box.stock >= 10 &&
                                <span className={globalStyles.badge} style={{backgroundColor:"#6fa939"}}>
                                    En stock
                                </span>
                            }

                            {props.box.stock <= 0 &&
                                <span className={globalStyles.badge} style={{backgroundColor:"#52BAEC"}}>
                                    En précommande
                                </span>
                            }

                            {props.box.stock < 10 &&
                                <span className={globalStyles.badge} style={{backgroundColor:"#f4bc00"}}>
                                    Moins de 10 en stock
                                </span>
                            }
                        </div>

                    </div>
                </div>
            </div>

            <div
                style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end"
                }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                    <button className={globalStyles.defaultLink} onClick={() => {props.chooseAction(props.i)}}>
                        Choisir
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CardTemplateBox;