import React, {useState} from "react";
import {Navigate} from "react-router-dom";
import {api_url} from "../../Services/ConnexionService";
import Empty from "../Empty";

function Logout (props) {

    let [canGo, setGo] = useState(false)

    let request = new Request(api_url + '/client/logout', {credentials: 'include'})
    fetch(request).then((response) => {
        setGo(true)
    })

    if(canGo) {
        return (
            <Navigate to={"/login"}/>
        )
    }
    return (
        <Empty/>
    )
}

export default Logout;
