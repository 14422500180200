import React from "react"
import LoggedComponent, {api_url, mapStateToProps, shop_url} from "../../../../Services/ConnexionService";
import {connect} from "react-redux";
import globalStyles from "../../../../Styles/Style.module.css";
import FilAriane from "../../../header_footer/FilAriane";
import {
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import image from "../../../../assets/fixtures/objectif-mars.png"
import Bandeau from "../../../header_footer/Bandeau";
import Empty from "../../../Empty";
import AddItemTemplate from "./AddItemTemplate";


class UnloggedAddItem extends LoggedComponent {

    constructor(props) {
        super(props);
        this.hasAdded = false;
        this.hasResponded = false;
        this.itemsAdded = [];
        this.qts = {}
    }

    setIsAddItem() {
        this.isAddItem = false
    }

    doesMustLog = () => {
        this.mustLog = 0
    }

    addItem = () => {
        if (!this.hasAdded && !this.state.shouldNavigate) {
            this.hasAdded = true
            let id = 0;
            let item = "";
            let ids = this.props.item.ids
            let refs = []
            let qts = {}
            for(const i in ids) {
                id = ids[i]
                item = this.props.item.item['item'+id]
                if(refs.includes(item.reference)) {
                    qts[item.reference.split('-')[0]] += 1
                } else {
                    refs.push(item.reference)
                    qts[item.reference.split('-')[0]] = 1
                }
            }
            this.qts = qts
            let jsonD = new FormData()
            jsonD.append("json", JSON.stringify(refs))
            let request = new Request(api_url + '/client/basket/items', {method:'POST', credentials:'include', body: jsonD})
            this.fetchJsonOrError(request, (json) =>{
                switch (json.type) {
                    case 'OK':
                        this.hasResponded = true
                        this.itemsAdded = json.added
                        this.forceUpdate()
                        break
                    case 'NONE':
                        this.hasResponded = true
                        this.itemsAdded = []
                        this.forceUpdate()
                        break
                    default:
                        this.goError()
                }
            })
        }
    }

    displayItems() {
        let items = []
        for(let i = 0; i < this.itemsAdded.length; i++){
            let article = this.itemsAdded[i];

            let infos = [];
            if(article.duration && article.type === "commitment"){
                infos.push("Durée : " + article.duration + " mois")
            }
            infos.push(
                article.description
            )

            items.push(
                <AddItemTemplate
                    name={article.name}
                    media={article.media.length > 0 ? article.media[0] : undefined}
                    infos={infos}
                    priceTtc={article.priceTtc/100}
                    unitPriceTtc={article.unitPriceTtc}
                    quantity={this.qts[article.reference]}
                    theme={article.theme}
                    secondaryTheme={article.secondaryTheme}
                    stock={article.stock}
                    isComm={article.isCommitment}
                    choosen={article.choosen}
                />
            )
        }

        return items

    }

    renderChild () {
        this.addItem()
        if(!this.hasResponded) {
            return <div style={{height: "1000000px", backgroundColor:"#FFF"}}/>
        }
        return (
            <div>
                <Bandeau title={"Panier"}/>

                <FilAriane
                    links={
                        [
                            [
                                "Les ateliers",
                                shop_url
                            ],
                            [
                                "Ajouter au panier",
                                ""
                            ],
                        ]
                    }
                />

                <div className={globalStyles.globalContainer} style={{flexDirection: "column", alignItems: "unset", padding: window.innerWidth > 960 ? "unset" : "10px", paddingTop: "10px"}}>
                    {this.displayItems()}

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row"
                        }}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            textAlign: "left",
                            marginTop: "10px",
                            marginRight: window.innerWidth < 1020 ? "5px" : undefined
                        }}>
                            <a href={shop_url} className={globalStyles.secondaryBtn}>
                                Continuer vos achats
                            </a>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                textAlign: "left",
                                marginTop: "10px",
                                marginLeft: window.innerWidth < 1020 ? "5px" : undefined
                        }}>
                            <a href={"/basket/additem"} className={globalStyles.defaultBtn} style={{border: "1px solid #571088"}}>
                                Valider le panier
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(UnloggedAddItem);
