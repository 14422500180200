import React from "react"
import LoggedComponent, {api_url, mapStateToProps} from "../../Services/ConnexionService";
import {connect} from "react-redux";
import globalStyles from "../../Styles/Style.module.css";
import FilAriane from "../header_footer/FilAriane";
import Bandeau from "../header_footer/Bandeau";
import styles from "../Profile/Profile.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign, faQuestionCircle, faShoppingBasket, faTruck} from "@fortawesome/free-solid-svg-icons";
import Basketitem from "../Shopping/Payment/Basketitem";
import img from "../../assets/fixtures/objectif-mars.png";
import CardTemplatePackage from "./CardTemplatePackage";
import CardTemplate from "../Card/CardTemplate";
import moment from "moment";
import titleAriane from "../../Helpers/TitleAriane.json";
import CardTemplateStatus from "./CardTemplateStatus";
import {encode} from "../../Services/ObfuscatorService";
import visa from "../../assets/payment/visa.png";
import masterCard from "../../assets/payment/master-card.png";
import carteBleue from "../../assets/payment/cb.jpg";
import payplug from "../../assets/payment/payplug.svg";
import formStyles from "../../Styles/FormStyle.module.css";
import DisplayPrice from "../Utils/DisplayPrice";
import ReactTooltip from "react-tooltip";


class ViewCommand extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            command : undefined,
            clickPay: false,
        }
        this.loading = true;
    }

    componentDidMount() {
        const scriptPayplug = document.createElement("script")
        scriptPayplug.type = "text/javascript"
        scriptPayplug.src = "https://api.payplug.com/js/1/form.latest.js"
        scriptPayplug.async = true
        document.head.appendChild(scriptPayplug)
    }

    downloadReport() {
        let request = new Request(
            api_url + "/client/order/invoice/" + this.props.id,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    accept: "application/pdf"
                }
            }
        );
        fetch(request)
            .then(res => res.blob()).then((blob) => {
                let fileURL = URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.style = "display: none";
                a.href = fileURL;
                a.download = "Atorika-facture_"+this.state.command.identifier+".pdf";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(fileURL);
                document.body.removeChild(a);
            })
    }

    getCommand(){
        let request = new Request(api_url + '/client/order/' + this.props.id, {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({command: data});
            this.loading = false;
            this.forceUpdate();
        })
    }

    doesMustLog = () => {
        this.mustLog = 1
    }


    submit = () => {
        this.setState({clickPay: true});
        let request2 = new Request(api_url + '/client/pay/retrieve/' + this.props.id + '/' + encode(this.props.id), {method:'POST', credentials:'include'})
        this.fetchJsonOrError(request2, (json) => {
            this.payUrl = json.url;
            this.showPayment()
            this.forceUpdate();
        })
    }

    showPayment = () => {
        const scriptUsePayplug = document.createElement("script")
        scriptUsePayplug.type = "text/javascript"
        scriptUsePayplug.text = "setTimeout(()=>{Payplug.showPayment('"+this.payUrl+"');}, 500);"
        scriptUsePayplug.async = true

        document.head.appendChild(scriptUsePayplug)

        setTimeout(this.isShown, 500)
    }

    isShown = () => {
        this.setState({clickPay: false})
    }

    displayCommands = (nbCommands) => {
        let commands = [];
        let packages = [];

        for (let i = 0; i < this.state.command.od.length; i++) {
            if(this.state.command.od[i].toDisplay){
                packages.push(
                    this.state.command.od[i]
                )
            }
        }

        let total = packages.length;

        let missing = (nbCommands - (total % nbCommands))%nbCommands;
        let currentLine = 0;
        for (let i = 0; i < total; i++) {
            if (i >= nbCommands*currentLine + nbCommands){
                commands.push(<div className={styles.break}/>)
                currentLine++;
            }

            commands.push(
                <div className={styles.childCard} style={{
                    marginLeft: i%nbCommands === 0 ? "0" : "10px",
                    marginRight: i%nbCommands === nbCommands - 1 ? "0" : "10px",
                }}>
                    <CardTemplatePackage
                        identifier={packages[i].number}
                        state={packages[i].state}
                        stateNb={packages[i].stateNb}
                        infos={[
                            packages[i].articleQty + " article(s)",
                        ]}
                        links={packages[i].toChoose ? [["Choisir", "/commitment/choose/" + encode(packages[i].order)]] : [["Suivre le colis", "/" + encode(packages[i].id) + "/tracking"]]}
                        color={packages[i].stateNb == 0 || packages[i].stateNb == 1 || packages[i].stateNb == 2 ? "white" :undefined}
                    />
                </div>
            )

        }

        for(let i = 0; i < missing; i++){
            commands.push(
                <div style={{flex: 1, display: "flex",  flexDirection: "row", margin: "5px"}}>
                </div>
            )
        }
        return commands
    }

    displayBasketItem() {
        let items = [];
        for(let i = 0; i < this.state.command.articles.length; i++){
            let isFor = "";
            if(this.state.command.articles[i].user){
                isFor = "Pour : " + this.state.command.articles[i].userName;
            } else {
                isFor = "Offrir en cadeau";
            }

            let duration = null;
            if(this.state.command.articles[i].article.duration && this.state.command.articles[i].article.type === "commitment"){
                duration = "Durée : " + this.state.command.articles[i].article.duration + " mois";
            }

            let firstBox = null;

            let code = [];


            if(this.state.command.articles[i].firstBox){
                firstBox = "Première box : " + this.state.command.articles[i].firstBox.article.name;
                if(this.state.command.articles[i].firstBox.code.length > 0){
                    code.push("Code d'activation");
                    code.push(
                        <FontAwesomeIcon
                            className={formStyles.iconHelp}
                            icon={faQuestionCircle}
                            data-tip="A transmettre à la personne à qui vous offrez le cadeau"
                        />
                    )
                    code.push(<br/>)
                    for(let j = 0; j < this.state.command.articles[i].firstBox.code.length; j++){
                        code.push(this.state.command.articles[i].firstBox.code[j]);
                        code.push(<br/>);
                    }
                }
            }

            if(this.state.command.articles[i].code.length > 0){
                if(this.state.command.articles[i].code.length === 1){
                    code.push("Code d'activation");
                } else {
                    code.push("Codes d'activation");
                }
                code.push(
                    <FontAwesomeIcon
                        className={formStyles.iconHelp}
                        icon={faQuestionCircle}
                        data-tip="A transmettre à la personne à qui vous offrez le cadeau"
                    />
                )
                code.push(<br/>);
                for(let j = 0; j < this.state.command.articles[i].code.length; j++){
                    code.push(this.state.command.articles[i].code[j]);
                    code.push(<br/>);
                }
            }

            items.push(
                <Basketitem
                    title={this.state.command.articles[i].article.name}
                    price={parseInt(this.state.command.articles[i].priceTtc)/100}
                    unitPrice={this.state.command.articles[i].article.unitPriceTtc ? parseInt(this.state.command.articles[i].article.unitPriceTtc)/100 : this.state.command.articles[i].article.priceTtc/100}
                    img={this.state.command.articles[i].article.media[0]}
                    infos={[
                        duration,
                        isFor,
                        "Quantité : " + this.state.command.articles[i].quantity,
                        firstBox,
                        code
                    ]}
                />
            )
        }

        return items;
    }

    calculateTotal() {
        let subtotal = 0;
        for(let i = 0; i < this.state.command.articles.length; i++){
            subtotal += parseInt(this.state.command.articles[i].priceTtc);
        }
        subtotal += parseInt(this.state.command.delivery.price);
        if(this.state.command.appliedPromo) subtotal -= parseInt(this.state.command.appliedPromo);
        return subtotal/100;
    }


    renderChild () {
        if(!this.loading){
            return (
                <div>
                    <Bandeau title={titleAriane["command"]["title"].replace("_number_", this.state.command.identifier)}/>
                    <FilAriane
                        links={[
                            [
                                titleAriane["command"]["ariane"][0],
                                "/"
                            ],
                            [
                                titleAriane["command"]["ariane"][1],
                                "/commands"
                            ],
                            [
                                titleAriane["command"]["ariane"][2],
                                ""
                            ]
                        ]}
                    />
                    <div className={globalStyles.globalContainer} style={{flexDirection: "column", marginBottom: "100px", padding: window.innerWidth > 960 ? "unset" : "10px"}}>

                        <div className={styles.cardContainer} style={{flexWrap: "wrap", margin: window.innerWidth > 1020 ? "0 10px" : "10px 10px 0"}}>
                            <div style={{flex: 1, marginRight: window.innerWidth > 1020 ? "10px" : 0, marginTop: "10px", marginBottom: "10px"}}>
                                <CardTemplateStatus
                                    title={"Enregistrée"}
                                    color={"#52BAEC"}
                                    isDone={this.state.command.dateValidated !== null || this.state.command.state >= 1}
                                    bgcolor={this.state.command.dateValidated || this.state.command.state >= 1 ? "white" : undefined}
                                    date={this.state.command.dateValidated ? this.state.command.dateValidated.date : undefined}
                                />
                            </div>
                            {window.innerWidth < 1020 && <div className={styles.break}/>}
                            <div
                                style={{
                                    flex: 1,
                                    marginRight: window.innerWidth > 1020 ? "10px" : 0,
                                    marginLeft: window.innerWidth > 1020 ? "10px" : 0,
                                    marginTop: "10px", marginBottom: "10px"
                            }}>
                                <CardTemplateStatus
                                    title={"Payée"}
                                    color={"#F4bc00"}
                                    isDone={this.state.command.datePaid !== null || this.state.command.state >= 2}
                                    bgcolor={this.state.command.datePaid || this.state.command.state >= 2 ? "white" : undefined}
                                    date={this.state.command.datePaid ? this.state.command.datePaid.date : undefined}
                                />
                            </div>
                            {window.innerWidth < 1020 && <div className={styles.break}/>}
                            <div
                                style={{
                                    flex: 1,
                                    marginLeft: window.innerWidth > 1020 ? "10px" : 0,
                                    marginTop: "10px", marginBottom: "10px"
                                }}>
                                <CardTemplateStatus
                                    title={this.state.command.dateFinished || this.state.command.state >= 3 ? this.state.command.state : "Terminée"}
                                    color={"#6fa939"}
                                    isDone={this.state.command.dateFinished !== null || this.state.command.state >= 3}
                                    bgcolor={this.state.command.dateFinished || this.state.command.state >= 3 ? "white" : undefined}
                                    date={this.state.command.dateFinished ? this.state.command.dateFinished.date : undefined}
                                />
                            </div>
                        </div>

                        { this.state.command.datePaid === null &&
                            <div className={styles.cardContainer} style={{flex: 3}}>
                                <div
                                    className={globalStyles.card}
                                    style={{
                                        padding: "30px",
                                        flex: 1,
                                        backgroundColor: "white",
                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                        borderRadius: "5px",
                                        display: "flex",
                                        height: "100%",
                                        boxSizing: "border-box",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                                        <h2 style={{margin: "10px 0", flex: 2}}>Mode de paiement</h2>
                                        <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: window.innerWidth > 1020 ? "flex-end" : "flex-start"}}>
                                            <div style={{
                                                border: "1px solid #ced4da",
                                                padding: "10px 4px",
                                                borderRadius: "3px",
                                                margin: "0 2px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "50px"
                                            }}>
                                                <img src={visa} height={"15px"}/>
                                            </div>
                                            <div style={{
                                                border: "1px solid #ced4da",
                                                padding: "0 2px",
                                                borderRadius: "3px",
                                                margin: "0 2px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "50px"
                                            }}>
                                                <img src={masterCard} height={"35px"}/>
                                            </div>
                                            <div style={{
                                                border: "1px solid #ced4da",
                                                padding: "5px 3px",
                                                borderRadius: "3px",
                                                margin: "0 2px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "50px"
                                            }}>
                                                <img src={carteBleue} height={"25px"}/>
                                            </div>
                                        </div>
                                    </div>
                                    <p><input type={"radio"} checked={true}/> Utiliser une carte bancaire</p>

                                    <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column", flex: 1, alignItems: window.innerWidth > 1020 ? "flex-end" : "flex-start", textAlign: "left", marginTop: "10px"}}>
                                        <div style={{flex: 1}}>
                                            <p style={{margin: 0, fontSize: "0.7rem", marginBottom: "4px"}}>
                                                Paiement sécurisé par
                                            </p>
                                            <img src={payplug} height={"25px"} alt={"Logo Payplug"}/>
                                        </div>

                                        <div style={{flex: 1, display: "flex", justifyContent: "flex-end", marginTop: window.innerWidth > 1020 ? undefined : "10px"}}>
                                            <button
                                                className={this.state.clickPay ? formStyles.disabledBtn : globalStyles.defaultBtn}
                                                onClick={this.submit}
                                                disabled={this.state.clickPay}
                                            >
                                                Procéder au paiement
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }

                        <div className={styles.cardContainer}>
                            <div
                                className={globalStyles.card}
                                style={{
                                    padding: "30px",
                                    flex: 1,
                                    backgroundColor: "#f3f6f8",
                                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                    borderRadius: "5px",
                                    display: "flex",
                                    height: "100%",
                                    boxSizing: "border-box",
                                    flexDirection: "column",
                                }}
                            >
                                <div style={{flex: 5}}>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <h2 style={{margin: "auto 0", flex: 2}}>
                                            <FontAwesomeIcon icon={faShoppingBasket} style={{marginRight: "15px"}}/>
                                            {window.innerWidth > 1020 ? "Récapitulatif de votre commande" : "Votre commande"}
                                        </h2>

                                        <div style={{
                                            flex: 1,
                                            textAlign: "right",
                                        }}
                                        >
                                            <h2 style={{textAlign: window.innerWidth > 1020 ? "right" : "left", margin: "10px 0"}}>
                                                Total : <DisplayPrice price = {this.calculateTotal()}/>
                                            </h2>
                                        </div>
                                    </div>

                                    {this.displayBasketItem()}

                                    <div style={{marginBottom: "20px", marginTop: "20px", display: "flex", flexDirection: "row"}}>
                                        <div style={{flex: 4, flexDirection: "column"}}>
                                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                                <div style={{flex: 2}}>
                                                    <h3 style={{margin: "0"}}>
                                                        {this.state.command.delivery.typeName}
                                                    </h3>
                                                </div>
                                                <div style={{flex: 1, textAlign: "right"}}>
                                                    <DisplayPrice price={this.state.command.delivery.price/100}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.command.appliedPromo > 0 &&
                                        <div style={{marginBottom: "20px", marginTop: "20px", display: "flex", flexDirection: "row"}}>

                                            <div style={{flex: 4, flexDirection: "column"}}>
                                                <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                                    <div style={{flex: 2}}>
                                                        <h3 style={{margin: "0"}}>
                                                            Réduction
                                                        </h3>
                                                    </div>
                                                    <div style={{flex: 1, textAlign: "right"}}>
                                                        -<DisplayPrice price={this.state.command.appliedPromo / 100}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={styles.cardContainer}>
                            <div className={styles.addressContainer} style={{marginRight: "10px", marginBottom : window.innerWidth > 960 ? 0 : "20px"}}>
                                <CardTemplate
                                    color={"white"}
                                    icon={faTruck}
                                    title={"Livraison"}
                                    infos={[
                                        this.state.command.delivery.typeName,
                                        this.state.command.delivery.firstName + " " + this.state.command.delivery.lastName,
                                        this.state.command.delivery.relayName,
                                        this.state.command.delivery.address,
                                        this.state.command.delivery.addressMore,
                                        this.state.command.delivery.postCode && this.state.command.delivery.city ? this.state.command.delivery.postCode + " " + this.state.command.delivery.city : undefined
                                    ]}
                                    links={this.state.command.toChoose && this.state.command.delivery.type > 0 ? [["Changer", "link", "/commitment/choose/" + encode(this.state.command.toChooseId)]] : []}
                                />
                            </div>
                            <div className={styles.addressContainer} style={{marginLeft: "10px"}}>
                                <CardTemplate
                                    color={"white"}
                                    icon={faEuroSign}
                                    title={"Facturation"}
                                    infos={[
                                        this.state.command.billing.firstName + " " + this.state.command.billing.lastName,
                                        this.state.command.billing.address,
                                        this.state.command.billing.addressMore,
                                        this.state.command.delivery.postCode && this.state.command.delivery.city ? this.state.command.billing.postCode + " " + this.state.command.billing.city : undefined
                                    ]}
                                    links={this.state.command.dateFinished ? [["Obtenir la facture", "btn", this.downloadReport.bind(this)]] : []}
                                />
                            </div>
                        </div>

                        <div className={styles.cardContainer} style={{flexWrap: "wrap", margin: "auto -10px"}}>
                            {window.innerWidth > 960 ?
                                this.displayCommands(3, 5) :
                                this.displayCommands(1, 5)
                            }
                        </div>

                    </div>
                    <ReactTooltip multiline={true}/>
                </div>
            );
        } else {
            this.getCommand()
            return (
                <div style={{height: "1000000px", backgroundColor:"#FFF"}}/>
            )
        }

    }
}

export default connect(mapStateToProps)(ViewCommand);
