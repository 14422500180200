import {useParams} from "react-router-dom";
import React from "react";
import {decode} from "../../Services/ObfuscatorService";
import DeleteProfile from "./DeleteProfile";

function GetIdProfileDelete () {
    let {id} = useParams()

    return (
        <DeleteProfile id={id.startsWith('__appli__') ? parseInt(id.substring(9)) : decode(id)}/>
    )
}

export default GetIdProfileDelete;
