import logo from '../../assets/utils/fabrique-en-france.svg';
import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {
    faApple,
    faFacebook,
    faFacebookF,
    faGooglePlay,
    faInstagram,
    faLinkedin, faLinkedinIn, faTwitter, faYoutube
} from "@fortawesome/free-brands-svg-icons";
import React from "react";


function Footer() {
    return (
        <div>
            <div style={{
                background: "#272727",
                height: "80px",
                width: "calc(100% + 10px)",
                transform: "rotate(-1deg)",
                left: "-5px",
                position: "absolute"
            }}>
            </div>

            <div style={{
                background: "#272727",
                top: "40px",
                position: "relative",
                padding: "70px 0px 20px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>

                <div className={styles.fullContainer}>

                    <div className={styles.container}>
                        <div style={{
                            flex: 1,
                            padding: "10px"
                        }}>
                            <div className={styles.title}>
                                Notre startup
                            </div>
                            <div className={styles.item}>
                                <a href={"https://www.atorika.fr/decouvrir"} className={styles.link}>
                                    Découvrir
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a href={"https://www.atorika.fr/concept"} className={styles.link}>
                                    Concept
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a href={"https://www.atorika.fr/parcs"} className={styles.link}>
                                    Parcs indoor
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a href={"https://www.atorika.fr/equipe"} className={styles.link}>
                                    Recrutement
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a href={"https://www.atorika.fr/partenaires"} className={styles.link}>
                                    Partenaires
                                </a>
                            </div>
                        </div>

                        <div style={{
                            flex: 1,
                            padding: "10px"
                        }}>
                            <div style={{marginBottom : "20px"}}>
                                <div className={styles.title}>
                                    Nos ateliers
                                </div>
                                <div className={styles.item}>
                                    <a href={"https://www.atorika.fr/maison"} className={styles.link}>
                                        A la maison
                                    </a>
                                </div>
                                <div className={styles.item}>
                                    <a href={"https://www.atorika.fr/scolaires"} className={styles.link}>
                                        A l'école
                                    </a>
                                </div>
                            </div>


                            <div className={styles.title}>
                                Nos apps
                            </div>
                            <div style={{display: "flex"}}>
                                <a href={"https://play.google.com/store/apps/details?id=com.atorika"} className={styles.appContainer}>
                                    <FontAwesomeIcon icon={faGooglePlay} style={{fontSize: "1.25em", marginRight: "8px"}} />
                                    <span style={{fontSize: "0.85rem"}}>
                                        Google Play
                                    </span>
                                </a>
                                <a href={"https://apps.apple.com/fr/app/atorika/id1599334700"} className={styles.appContainer}>
                                    <FontAwesomeIcon icon={faApple} style={{fontSize: "1.5em", marginRight: "8px"}} />
                                    <span style={{fontSize: "0.85rem"}}>
                                        App store
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className={styles.container}>
                        <div style={{
                            flex: 1,
                            padding: "10px"
                        }}>
                            <div className={styles.title}>
                                Je me lance !
                            </div>
                            <div className={styles.item}>
                                <a href={"https://www.atorika.fr/ateliers"} className={styles.link}>
                                    Je choisis ma box
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a href={"https://www.atorika.fr/ateliers"} className={styles.link}>
                                    J'offre un cadeau
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a href={"https://client.atorika.fr"} className={styles.link}>
                                    Je deviens ambassadeur
                                </a>
                            </div>
                        </div>

                        <div style={{
                            flex: 1,
                            padding: "10px"
                        }}>
                            <div className={styles.title}>
                                Besoin d'aide ?
                            </div>
                            <div className={styles.item}>
                                <a href={"https://help.atorika.fr/faq"} className={styles.link}>
                                    Questions fréquentes
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a href={"https://help.atorika.fr"} className={styles.link}>
                                    Centre d'assistance
                                </a>
                            </div>
                            <div className={styles.item}>
                                <a href={"https://www.atorika.fr/contact"} className={styles.link}>
                                    Contactez-nous
                                </a>
                            </div>
                        </div>
                    </div>


                </div>

                <div className={styles.legalNetworkFooter}>
                    <div className={styles.legalNetwork}>

                        <div className={styles.legal}>
                            <div className={styles.itemLegal}>
                                <a href={"https://www.atorika.fr/legal"} className={styles.linkLegal}>
                                    Mentions légales
                                </a>
                            </div>

                            <div className={styles.itemLegal}>
                                <a href={"https://www.atorika.fr/policy"} className={styles.linkLegal}>
                                    Politique de confidentialité
                                </a>
                            </div>


                            <div className={styles.itemLegal}>
                                <a href={"https://www.atorika.fr/terms"} className={styles.linkLegal}>
                                    CGUV
                                </a>
                            </div>

                        </div>

                        <div className={styles.network}>
                            <a href={"https://www.facebook.com/atorika.fr/"}>
                                <FontAwesomeIcon icon={faFacebookF} className={styles.icon}/>
                            </a>
                            <a href={"https://www.instagram.com/atorika.fr/"}>
                                <FontAwesomeIcon icon={faInstagram} className={styles.icon}/>
                            </a>
                            <a href={"https://fr.linkedin.com/company/atorika"}>
                                <FontAwesomeIcon icon={faLinkedinIn} className={styles.icon}/>
                            </a>
                            <a href={"https://twitter.com/atorika_fr"}>
                                <FontAwesomeIcon icon={faTwitter} className={styles.icon}/>
                            </a>
                            <a href={"https://www.youtube.com/channel/UCULoOLNbsQcG9aZGrBEM1ag"}>
                                <FontAwesomeIcon icon={faYoutube} className={styles.icon}/>
                            </a>
                        </div>

                    </div>

                    <div className={styles.divider}/>

                    <div className={styles.footerContainer}>

                        <div className={styles.footer}>
                            © 2020-2022 Atorika ® - Tous droits réservés
                        </div>

                        <div className={styles.footer} style={{textAlign: "center"}}>
                            <img src={logo} alt="logo" style={{height: "40px"}}/>
                        </div>

                        <div className={styles.footer} style={{textAlign: "right"}}>
                            Réalisé sous le 🌞 de Provence et parfois sous la 🌧️
                        </div>

                    </div>
                </div>



            </div>

        </div>
    );
}

export default Footer;
