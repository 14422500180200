import React from "react";
import globalStyles from '../../../../Styles/Style.module.css';
import {faShoppingBasket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BasketItem from "./BasketItem";

function Basket(props) {

    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "10px",
                paddingTop: "30px",
                backgroundColor: "#f3f6f8",
                display: "flex",
                height: "calc(100% - 150px)",
                position: "fixed",
                width: "calc(50% - 580px)",
                right: "10px",
                top: "220px",
                flexDirection: "column",
            }}
        >

            <h2 style={{margin: "0 0 15px 0"}}>
                <FontAwesomeIcon icon={faShoppingBasket} style={{marginRight: "15px"}}/>
                Panier
            </h2>

            <BasketItem/>
            <BasketItem/>
            <BasketItem/>
            <BasketItem/>

        </div>
    );
}

export default Basket;